<div #dialog class="dialog">
  <!-- <h3 class="title">声明</h3> -->
 
  <button class="close-button" (click)="closeDialog()">
    {{ closeText | translate }} 
  </button>
  <div class="img-contaner">
    <img  src="assets/remark.jpg" />
  </div>
  
  <!-- <iframe src="assets/remark.pdf" width="100%" height="100%" style="border: none;"></iframe> -->
  <button class="close-button-2" (click)="closeDialog()">
    {{ closeText | translate }}
  </button> 
</div>