import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MetaModule } from 'ng2-meta';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { HeaderComponent } from './_share/header/header.component';
import { HeaderNavComponent } from './_share/header-nav/header-nav.component';
import { FooterComponent } from './_share/footer/footer.component';
import { SafetyComponent } from './safety/safety.component';
import { OfficeCollaborationComponent } from './office-collaboration/office-collaboration.component';
import { ContentControlComponent } from './content-control/content-control.component';
import { CustomComponent } from './custom/custom.component';
import { SolutionComponent } from './solution/solution.component';
import { PriceComponent } from './price/price.component';
import { DownloadComponent } from './download/download.component';
import { CloudFormComponent } from './_share/cloud-form/cloud-form.component';
import { HttpClientModule } from '@angular/common/http';
/*翻译库*/
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient } from '@angular/common/http';

import { SuspensionComponent } from './_share/suspension/suspension.component';
import { ServiceGuaranteeComponent } from './service-guarantee/service-guarantee.component';
import { MimicryComponent } from './mimicry/mimicry.component';
import { SmartVersionComponent } from './smart-version/smart-version.component';
import { SmartFindComponent } from './smart-find/smart-find.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { TermsComponent } from './terms/terms.component';
import { PrivacyComponent } from './privacy/privacy.component';
import { SiteMapComponent } from './site-map/site-map.component';
import { CooperationComponent } from './cooperation/cooperation.component';
import { FileDnaComponent } from './file-dna/file-dna.component';
import { LandComponent } from './land/land.component';
import { GovernmentComponent } from './government/government.component';
import { ManufactureComponent } from './manufacture/manufacture.component';
import { MedicalComponent } from './medical/medical.component';
import { TradeComponent } from './trade/trade.component';
import { FinanceComponent } from './finance/finance.component';
import { EnergyComponent } from './energy/energy.component';
import { EducationComponent } from './education/education.component';
import { MediaComponent } from './media/media.component';
import { DesignComponent } from './design/design.component';
import { ChannelCooperateComponent } from './channel-cooperate/channel-cooperate.component';

import { AutoDialogComponent } from './auto-dialog/dialog-example.component';

import { DocsComponent } from './docs/docs.component';
import { VideoPopupComponent } from './_share/video-popup/video-popup.component';
import { SafeHtmlPipe } from './_share/pipes/safe-html.pipe';
import { version } from 'package.json';
import { LazyLoadImageModule } from 'ng-lazyload-image'; // <-- import it

// 自定义加载方法
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, '../assets/i18n/', `.json?v=${version}`);
}

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    HeaderComponent,
    HeaderNavComponent,
    FooterComponent,
    SafetyComponent,
    OfficeCollaborationComponent,
    ContentControlComponent,
    CustomComponent,
    SolutionComponent,
    PriceComponent,
    DownloadComponent,
    CloudFormComponent,
    SuspensionComponent,
    ServiceGuaranteeComponent,
    MimicryComponent,
    SmartVersionComponent,
    SmartFindComponent,
    ContactUsComponent,
    AboutUsComponent,
    TermsComponent,
    PrivacyComponent,
    SiteMapComponent,
    CooperationComponent,
    FileDnaComponent,
    LandComponent,
    GovernmentComponent,
    ManufactureComponent,
    MedicalComponent,
    TradeComponent,
    FinanceComponent,
    EnergyComponent,
    EducationComponent,
    MediaComponent,
    DesignComponent,
    ChannelCooperateComponent,
    AutoDialogComponent,
    DocsComponent,
    VideoPopupComponent,
    SafeHtmlPipe
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    LazyLoadImageModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    MetaModule.forRoot(),
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }

// export function HttpLoaderFactory(http: HttpClient) {
//   return new TranslateHttpLoader(http);
// }
