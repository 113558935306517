import { Component, OnInit } from '@angular/core';
import { JumpPageService } from 'src/app/_share/service/jump-page.service';

class ApplicationScenarios {
  src: string;
  title: string;
  desc: string;
}

class UseWay {
  src: string;
  title: string;
  desc: string;
}

class FileInterference {
  src: string;
  title: string;
}

@Component({
  selector: 'app-file-dna',
  templateUrl: './file-dna.component.html',
  styleUrls: ['./file-dna.component.less']
})
export class FileDnaComponent implements OnInit {

  constructor(
    private _jumpPage: JumpPageService,
  ) { }

  public contactUsTitle = "product.file-dna.contactUsTitle"; // 标题
  public contactUsDesc = "product.file-dna.contactUsDesc"; // 描述
  public contactUsImgSrc = "/assets/img/fileDNA/filedna-banner.png"; // 背景图

  // 文件干扰
public fileInterferenceArr: Array<FileInterference> = [
  {
    src: "/assets/img/fileDNA/filedna-part1-three-image1.png",
    title: "product.file-dna.item3-subtitle1",
  },
  {
    src: "/assets/img/fileDNA/filedna-part1-three-image2.png",
    title: "product.file-dna.item3-subtitle2",
  },
  {
    src: "/assets/img/fileDNA/filedna-part1-three-image3.png",
    title: "product.file-dna.item3-subtitle3",
  },
  {
    src: "/assets/img/fileDNA/filedna-part1-three-image4.png",
    title: "product.file-dna.item3-subtitle4",
  }
];

// 应用场景
public applicationScenariosArr: Array<ApplicationScenarios> = [
  {
    src: "/assets/img/fileDNA/filedna-part2-one-image1.png",
    title: "product.file-dna.item5-subtitle1",
    desc: "product.file-dna.item5-desc1",
  },
  {
    src: "/assets/img/fileDNA/filedna-part2-one-image2.png",
    title: "product.file-dna.item5-subtitle2",
    desc: "product.file-dna.item5-desc2",
  },
  {
    src: "/assets/img/fileDNA/filedna-part2-one-image3.png",
    title: "product.file-dna.item5-subtitle3",
    desc: "product.file-dna.item5-desc3",
  }
];

// 使用方式
public useWayArr: Array<UseWay> = [
  {
    src: "/assets/img/fileDNA/filedna-part2-two-image1.png",
    title: "product.file-dna.item6-subtitle1",
    desc: "product.file-dna.item6-desc1",
  },
  {
    src: "/assets/img/fileDNA/filedna-part2-two-image2.png",
    title: "product.file-dna.item6-subtitle2",
    desc: "product.file-dna.item6-desc2",
  },
];

  ngOnInit(): void {
    this._jumpPage.goTop();
  }

}
