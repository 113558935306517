<div>
  <div>
    <app-header
      [title]="downloadTitle | translate"
      [desc]="downloadDesc | translate"
    ></app-header>
  </div>

  <div class="product-download-wrapper">
    <div class="product-download-content">
      <div class="product-download-item" *ngFor="let item of downloadContent" (click)="item.click()">
        <img class="product-download-img" [src]="item.imgUrl">
        
        <div class="product-download-hoverImg" *ngIf="!item.id" >
          <img [src]="item.hoverImg">
        </div>

        <div class="product-download-hoverImg" *ngIf="item.id">
          <canvas 
            [id]="item.id" 
          ></canvas>
        </div>

        <div class="product-download-title">
          {{item.title | translate}}
        </div>
        <div class="product-download-desc">
          {{item.desc | translate}}
        </div>
        <div class="product-download-hoverText">
          {{item.hoverText | translate}}
        </div>
      </div>
    </div>
  </div>

  <app-suspension></app-suspension>

  <app-footer></app-footer>
</div>