import { Component, OnInit } from '@angular/core';
import { JumpPageService } from 'src/app/_share/service/jump-page.service';
import * as QRCode from 'qrcode';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-download',
  templateUrl: './download.component.html',
  styleUrls: ['./download.component.less']
})
export class DownloadComponent implements OnInit {

  public downloadTitle: string = 'product.download.downloadTitle';
  public downloadDesc: string = 'product.download.downloadDesc';

  public packageUrl: string;

  public downloadContent: Array<any> = [
    {
      imgUrl: './assets/img/download/download-img1.svg',
      title: 'product.download.content-title1',
      desc: 'product.download.content-desc1',
      hoverText: 'product.download.content-hoverText1',
      hoverImg: './assets/img/download/download-pc.png',
      type: 'downOfPc',
      click: () => {
        let url: string = 'https://app.oatos.com/download_win_x86';
    
        let agent: string = navigator.userAgent.toLowerCase();
    
        if (agent.indexOf('win64') >= 0 || agent.indexOf('wow64') >= 0) {
          url = 'https://app.oatos.com/download_win_x64';
        }

        this.newWin(url, 'downOfPc');
      }
    },
    {
      imgUrl: './assets/img/download/download-img2.svg',
      title: 'product.download.content-title2',
      desc: 'product.download.content-desc2',
      hoverText: 'product.download.content-hoverText1',
      hoverImg: './assets/img/download/download-pc.png',
      type: 'downOfMac',
      click: () => {
        let url: string = 'https://app.oatos.com/download_mac';

        this.newWin(url, 'downOfMac');
      }
    },
    {
      imgUrl: './assets/img/download/download-img3.png',
      title: 'product.download.content-title3',
      desc: 'product.download.content-desc4',
      hoverText: 'product.download.content-hoverText2',
      type: 'downOfIos',
      click: () => {
        let url: string = 'https://app.oatos.com/download_ios';

        this.newWin(url, 'downOfIos');
      },
      id: 'qrcodeIOS'
    },
    {
      imgUrl: './assets/img/download/download-img4.png',
      title: 'product.download.content-title4',
      desc: 'product.download.content-desc3',
      hoverText: 'product.download.content-hoverText2',
      type: 'downOfAndroid',
      click: () => {
        let url: string = 'https://app.oatos.com/download_android';

        this.newWin(url, 'downOfAndroid');
      },
      id: 'qrcodeAndroid'
    },
    {
      imgUrl: './assets/img/download/download-img1.svg',
      title: 'product.download.content-title5',
      desc: 'product.download.content-desc5',
      hoverText: 'product.download.content-hoverText1',
      hoverImg: './assets/img/download/download-pc.png',
      type: 'downOfSync',
      click: () => {
        let url: string = 'https://app.oatos.com/download_sync_win';
    
        this.newWin(url, 'downOfSync');
      }
    },
    // {
    //   imgUrl: './assets/img/download/download-img1.svg',
    //   title: 'product.download.content-title6',
    //   desc: 'product.download.content-desc6',
    //   hoverText: 'product.download.content-hoverText1',
    //   hoverImg: './assets/img/download/download-pc.png',
    //   click: () => {

    //   }
    // },
  ]


  constructor(
    private _jumpPage: JumpPageService,
    private meta: Meta,
    private titleService: Title
  ) {
    this.titleService.setTitle('客户端下载-燕麦云智能企业云盘');
    this.meta.addTag({ name: 'keywords', content: '燕麦云客户端,燕麦云,企业云盘,企业网盘,OATOS,私有云存储' });
    this.meta.addTag({ name: 'description', content: '燕麦云客户端涵盖Windows、MacOS、iOS、Android全平台。如在下载和使用燕麦云客户端时遇到任何问题，请和我们联系。燕麦云智能企业云盘服务热线：4000861108。' });
  }

  ngOnInit(): void {
    this._jumpPage.goTop();

    this.androidCode();

    this.iosCode();
  }

  // 生成Android下载二维码
  public androidCode(): void {
    setTimeout(() => {
      let url:string = 'https://app.oatos.com/download_android';
      let qrcodeAndroid = document.getElementById('qrcodeAndroid');

      QRCode.toCanvas(
        qrcodeAndroid,
        url,
        {
          errorCorrectionLevel: 'H',
          version: 8,
        },
        error => {
          
        }
      );

      qrcodeAndroid.style.width = '150px';
      qrcodeAndroid.style.height = '150px';
    });
  }

  // 生成ios下载二维码
  public iosCode(): void {
    setTimeout(() => {
      let url:string = 'https://app.oatos.com/download_ios';
      let qrcodeIOS = document.getElementById('qrcodeIOS');
      
      QRCode.toCanvas(
        qrcodeIOS,
        url,
        {
          errorCorrectionLevel: 'H',
          version: 8,
        },
        error => {
          
        }
      );

      qrcodeIOS.style.width = '150px';
      qrcodeIOS.style.height = '150px';
    });
  }

  // 手动触发新窗口
  private newWin(url: string, id: string): void {
    let form = document.createElement('form');
    form.action = url;
    form.target = '_blank';
    form.method = 'GET';
    form.setAttribute('id', id);

    if(!document.getElementById(id)) {         
        document.body.appendChild(form);
      }
    form.submit();
    form.remove();
  }
  
}
