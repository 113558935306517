import { Component, OnInit } from '@angular/core';
import { JumpPageService } from 'src/app/_share/service/jump-page.service';
import { Meta, Title } from '@angular/platform-browser';
import { SendFormStatuService } from'src/app/_share/service/send-form-statu.service';

@Component({
  selector: 'app-price',
  templateUrl: './price.component.html',
  styleUrls: ['./price.component.less']
})
export class PriceComponent implements OnInit {

  constructor(
    private _jumpPage: JumpPageService,
    private meta: Meta,
    private _sendFormStatus: SendFormStatuService,
    private titleService: Title
  ) {
    this.titleService.setTitle('版本价格-燕麦云智能企业云盘');
    this.meta.addTag({ name: 'keywords', content: '版本价格,燕麦云,企业云盘,企业网盘,OATOS,私有云存储' });
    this.meta.addTag({ name: 'description', content: '燕麦云价格由用户使用或购买模式而定，燕麦云价格涵盖在线使用的套餐价格和私有部署的一次性授权价格。了解价格详情可致电燕麦云智能企业云盘服务热线：4000861108。' });
  }

    // 是否显示表单
  public isShowForm: boolean = false;
  public priceTitle: string = 'product.version-price.title';
  public priceDesc: string = 'product.version-price.priceDesc';
  public priceImgSrc: string = './assets/img/price/price-background.png';

  public priceContent: Array<any> = [
    {
      title: 'product.version-price.priceContent-title',
      price: ' ',
      desc: 'product.version-price.priceContent-desc',
      list: [
        'product.version-price.priceContent-list1', 
        'product.version-price.priceContent-list2', 
        'product.version-price.priceContent-list3', 
        'product.version-price.priceContent-list4', 
        'product.version-price.priceContent-list5', 
        'product.version-price.priceContent-list6', 
        'product.version-price.priceContent-list7', 
        'product.version-price.priceContent-list8', 
        'product.version-price.priceContent-list9', 
        'product.version-price.priceContent-list10'
      ],
      button: 'product.version-price.priceContent-btn',
      link: 'https://app.oatos.com/os/#/welcome/sign-up'
    },
    {
      title: '私有化定制版',
      desc: '适用于私有/混合部署，有定制化需求的企业。',
      list: [
        '包含标准版功能', 
        '数据隔离加密', 
        '高可用性能架构，动态扩容', 
        '企业自主掌控，多维度管理', 
        '现有IT系统集成', 
        '单点登录到第三方应用，如钉钉、企业微信等', 
        '私有云/公有云/混合云部署', 
        '提供齐全的API接口', 
        '咨询指导、需求实施服务', 
        '产品使用培训', 
        '一对一客户经理', 
        '7*24h客服支持'
      ],
      button: '预约部署',
      link: ''
    }
  ]

  // 产品功能
  public versionContent: Array<any> = [
    {
      title: '文件管理',
      id: 'version-price1',
      subContent: [
        {
          subtitle: '基础文件管理功能',
          desc: '新建文件夹/删除/上传/下载/移动/复制/重命名，文件排序，文件列表切换等',
          check50: 'circle',
          check50Plus: 'circle',
        },
        // {
        //   subtitle: '拟态保险箱Mimic Vault ™',
        //   desc: '全球范围内首次商用的拟态技术产品，极致私密、安全的网络存储空间',
        //   check50: 'circle',
        //   check50Plus: 'ring',
        // },
        {
          subtitle: '智能搜索文件',
          desc: '提供关键词全文搜索，图片及音视频内容搜索',
          check50: 'circle',
          check50Plus: 'circle',
        },
        {
          subtitle: '智能版本管理 Sversion ™',
          desc: '自动保存文件的每个改动版本，智能释放存储空间，节约成本',
          check50: 'circle',
          check50Plus: 'circle',
        },
        {
          subtitle: '文件权限管控',
          desc: '管控成员的文件查看/上传/下载/删除/外链/编辑等操作权限',
          check50: 'circle',
          check50Plus: 'circle',
        },
        {
          subtitle: '存储区域安全管理',
          desc: '通过存储区域从底层隔离账号、部门或生产系统文件数据，预防数据穿越和信息泄露',
          check50: 'ring',
          check50Plus: 'circle',
        },
        {
          subtitle: '在线预览文档',
          desc: '在线预览多种格式文档、图片、视频、音频文件',
          check50: 'circle',
          check50Plus: 'circle',
        },
        {
          subtitle: '工作交接',
          desc: '一键交接员工的工作文件',
          check50: 'circle',
          check50Plus: 'circle',
        },
        {
          subtitle: 'CAD文档专业预览',
          desc: '提供专业级的CAD文档在线预览',
          check50: 'ring',
          check50Plus: 'circle',
        },
        {
          subtitle: '文件回收站',
          desc: '暂存已删除的文件，删除者可在此彻底删除或者还原文件到原位置',
          check50: 'circle',
          check50Plus: 'circle',
        },
        {
          subtitle: '文件水印',
          desc: '为文档、图片设置水印',
          check50: 'circle',
          check50Plus: 'circle',
        }
      ]
    },
    {
      title: '工作协同',
      id: 'version-price2',
      subContent: [
        {
          subtitle: '在线交互编辑文件',
          desc: 'PC端在线交互编辑云端文件，支持任意可编辑格式文件',
          check50: 'circle',
          check50Plus: 'circle',
        },
        {
          subtitle: '在线多人协同编辑',
          desc: 'Office文档多人在线实时协同编辑',
          check50: 'ring',
          check50Plus: 'ring',
        },
        {
          subtitle: '文档模板',
          desc: '海量在线模板，支持自定义上传文档模板供企业内部使用',
          check50: 'circle',
          check50Plus: 'circle',
        },
        {
          subtitle: '日程管理',
          desc: '新建工作日程，和同事共享工作日程',
          check50: 'circle',
          check50Plus: 'circle',
        },
        {
          subtitle: '外链分享文件',
          desc: '生成文件外链，将文件分发给外部人员访问、下载',
          check50: 'circle',
          check50Plus: 'circle',
        },
        {
          subtitle: '外链收集文件',
          desc: '生成文件夹上传外链，通过上传外链从外部收集文件',
          check50: 'circle',
          check50Plus: 'circle',
        },
        {
          subtitle: '文件审批',
          desc: '发起文件给其他同事审批，审批其他同事的文件',
          check50: 'circle',
          check50Plus: 'circle',
        },
        {
          subtitle: '外链审批',
          desc: '审批成员新建的外链，通过审批的外链才生效',
          check50: 'ring',
          check50Plus: 'ring',
        },
        {
          subtitle: '个人文件共享',
          desc: '需授权共享自己的个人文件给其他账号',
          check50: 'circle',
          check50Plus: 'circle',
        },
        {
          subtitle: '实时动态提醒',
          desc: '收藏、共享的文件（夹）被修改或权限变动时，实时获取动态详情提醒',
          check50: 'circle',
          check50Plus: 'circle',
        }
      ]
    },
    {
      title: '移动办公',
      id: 'version-price3',
      subContent: [
        {
          subtitle: '基础文件管理功能',
          desc: '全面支持新建文件夹/删除/上传/下载/移动/复制/重命名，文件排序，文件列表切换等',
          check50: 'circle',
          check50Plus: 'circle',
        },
        {
          subtitle: '在线多人协同编辑',
          desc: 'Office文档多人在线实时协同编辑',
          check50: 'ring',
          check50Plus: 'ring',
        },
        {
          subtitle: '全平台分享文件',
          desc: '一键分享文件给QQ、微信、钉钉好友或邮箱联系人',
          check50: 'circle',
          check50Plus: 'circle',
        },
        {
          subtitle: '文件预览',
          desc: '通过移动端在线预览文档、图片、视频',
          check50: 'circle',
          check50Plus: 'circle',
        },
        {
          subtitle: '实时消息提醒',
          desc: '随时收取文件动态或消息通知',
          check50: 'circle',
          check50Plus: 'circle',
        },
        {
          subtitle: '拍照/视频上传',
          desc: '手机端拍照或视频直接上传到云端或共享给其他成员',
          check50: 'circle',
          check50Plus: 'circle',
        },
        {
          subtitle: '从其他APP上传',
          desc: 'iOS端可从其它APP复制文件并上次到云端保存',
          check50: 'circle',
          check50Plus: 'circle',
        },
        {
          subtitle: '其它平台集成',
          desc: '集成到企业微信、钉钉等',
          check50: 'ring',
          check50Plus: 'circle',
        }
      ]
    },
    {
      title: '后台管理',
      id: 'version-price4',
      subContent: [
        {
          subtitle: '消息推送',
          desc: '企业管理员推送企业公告或消息通知给成员',
          check50: 'circle',
          check50Plus: 'circle',
        },
        {
          subtitle: '账号管理',
          desc: '成员账号的新建与管理，账号存储区域管理、个人文件容量管理、账号锁定/解锁',
          check50: 'circle',
          check50Plus: 'circle',
        },
        {
          subtitle: '部门管理',
          desc: '部门的新建与管理，成员部门管理',
          check50: 'circle',
          check50Plus: 'circle',
        },
        {
          subtitle: '用户置顶',
          desc: '支持将特殊用户置顶，应用到文件共享、审批、授权的各个操作界面',
          check50: 'circle',
          check50Plus: 'circle',
        },
        {
          subtitle: '企业文件授权',
          desc: '新新建企业文件一级目录结构，为所有账号授权企业文件权限，管理企业文件授权',
          check50: 'circle',
          check50Plus: 'circle',
        },
        {
          subtitle: '外链管控',
          desc: '企业文件外链和个人文件外链的集中管控，公开外链白名单管理',
          check50: 'circle',
          check50Plus: 'circle',
        },
        {
          subtitle: '水印设置',
          desc: '定制水印内容及显示样式',
          check50: 'circle',
          check50Plus: 'circle',
        },
        {
          subtitle: '锁定设置',
          desc: '已锁定文件的管理操作设置，自动锁定文件异常状态的解锁',
          check50: 'circle',
          check50Plus: 'circle',
        },
        {
          subtitle: '超级回收站',
          desc: '找回从成员回收站彻底删除的文件，彻底删除文件清理存储空间',
          check50: 'circle',
          check50Plus: 'circle',
        },
        {
          subtitle: '版本管理',
          desc: '设置文件历史版本规则，清理历史版本',
          check50: 'circle',
          check50Plus: 'circle',
        },
        {
          subtitle: '云盘使用统计',
          desc: '空间用量详情统计，文件类型详情统计和部门使用详情统计',
          check50: 'circle',
          check50Plus: 'circle',
        },
        {
          subtitle: '存储区域管理',
          desc: '新建存储隔离区域',
          check50: 'ring',
          check50Plus: 'circle',
        },
        {
          subtitle: '指定备份管理',
          desc: '备份策略管理',
          check50: 'circle',
          check50Plus: 'circle',
        },
        {
          subtitle: '多级管理员设置',
          desc: '新建下级管理员，授权下级管理员的部门管理范围和企业文件授权管理范围',
          check50: 'circle',
          check50Plus: 'circle',
        },
        {
          subtitle: '域管理',
          desc: '配置域服务、域账号管理、域部门管理、域角色管理',
          check50: 'delete',
          check50Plus: 'circle',
        },
        {
          subtitle: '邮件服务设置',
          desc: '配置系统通知的邮件服务',
          check50: 'delete',
          check50Plus: 'circle',
        },
        {
          subtitle: '行为监控设置',
          desc: '配置行为监控规则',
          check50: 'ring',
          check50Plus: 'circle',
        },
        {
          subtitle: '系统日志查询',
          desc: '查询或导出账号、文件日志',
          check50: 'circle',
          check50Plus: 'circle',
        }
      ]
    },
    {
      title: '智能同步',
      id: 'version-price5',
      subContent: [
        {
          subtitle: '双向同步',
          desc: '企业管理员推送企业公告或消息通知给成员',
          check50: 'circle',
          check50Plus: 'circle',
        },
        {
          subtitle: '文件备份',
          desc: '成员账号的新建与管理，账号存储区域管理、个人文件容量管理、账号锁定/解锁',
          check50: 'circle',
          check50Plus: 'circle',
        },
        {
          subtitle: '多任务',
          desc: '支持创建多个同步任务，满足多种同步场景，可独立管理任务',
          check50: 'circle',
          check50Plus: 'circle',
        }
      ]
    },
    {
      title: '安全保障',
      id: 'version-price6',
      subContent: [
        {
          subtitle: '燕麦双因子',
          desc: '账号绑定验证器，账号登录或关键操作时校验动态密码进行二次身份验证',
          check50: 'circle',
          check50Plus: 'circle',
        },
        {
          subtitle: '帐号锁定/解锁',
          desc: '锁定账号强制退出系统并限制登录，解锁账号即可恢复登录',
          check50: 'circle',
          check50Plus: 'circle',
        },
        {
          subtitle: '加密存储',
          desc: '碎片化加密存储文件',
          check50: 'circle',
          check50Plus: 'circle',
        },
        {
          subtitle: '密码安全',
          desc: '高级密码规则',
          check50: 'circle',
          check50Plus: 'circle',
        },
        {
          subtitle: 'SSL加密传输',
          desc: 'https加密传输数据',
          check50: 'circle',
          check50Plus: 'circle',
        },
        {
          subtitle: '容灾备份',
          desc: '系统容灾及数据备份双重数据安全方案',
          check50: 'circle',
          check50Plus: 'circle',
        },
        {
          subtitle: '智能安全防护',
          desc: '实时监控账号及终端行为，智能感知异常操作，支持白名单与网段访问策略设置，随时切断可疑终端的接入访问',
          check50: 'ring',
          check50Plus: 'circle',
        },
        {
          subtitle: '用户访问管控',
          desc: '为帐号设置特定访问ip，仅允许用户在在特定区域访问燕麦云账户内的数据',
          check50: 'delete',
          check50Plus: 'circle',
        },
        {
          subtitle: '网段访问管控',
          desc: '开启IP防护，只允许您指定的IP地址访问，减少盗用账号访问数据的风险',
          check50: 'delete',
          check50Plus: 'circle',
        },
        {
          subtitle: '登录行为监控',
          desc: '智能监控成员的登录行为，当成员触发了系统预警时系统将发送通知邮件到相关人员',
          check50: 'ring',
          check50Plus: 'circle',
        },
        // {
        //   subtitle: '终端控制',
        //   desc: '管控登录燕麦云产品的终端，支持多终端同时登陆系统，可在线解除异常登录的设备',
        //   check50: 'ring',
        //   check50Plus: 'circle',
        // }
      ]
    },
    {
      title: '系统集成',
      id: 'version-price7',
      subContent: [
        {
          subtitle: '域集成',
          desc: '集成企业域服务，同步域账号',
          check50: 'delete',
          check50Plus: 'circle',
        },
        {
          subtitle: '自定义域名及登录页',
          desc: '用自有域名访问燕麦云产品',
          check50: 'delete',
          check50Plus: 'circle',
        },
        {
          subtitle: 'SMTP设置',
          desc: '配置自有SMTP邮件服务器从系统发送邮件通知',
          check50: 'delete',
          check50Plus: 'circle',
        }
      ]
    },
    {
      title: '部署方式',
      id: 'version-price8',
      subContent: [
        {
          subtitle: '单机部署',
          desc: '单台PC或服务器上部署',
          check50: 'delete',
          check50Plus: 'circle',
        },
        {
          subtitle: '集群部署',
          desc: '按需定制的多台服务器部署方案',
          check50: 'delete',
          check50Plus: 'circle',
        },
        {
          subtitle: '高可用部署',
          desc: '按需定制的高可用部署方案',
          check50: 'delete',
          check50Plus: 'circle',
        },
        {
          subtitle: '内外网隔离部署',
          desc: '内网隔离访问的部署方案',
          check50: 'delete',
          check50Plus: 'circle',
        },
        {
          subtitle: '分布式部署',
          desc: '跨地域分布式部署方案',
          check50: 'delete',
          check50Plus: 'circle',
        }
      ]
    },
    {
      title: '二次开发',
      id: 'version-price9',
      subContent: [
        {
          subtitle: '系统集成',
          desc: '与现有IT系统集成',
          check50: 'delete',
          check50Plus: 'circle',
        },
        {
          subtitle: 'API开放',
          desc: '提供齐全的API接口',
          check50: 'delete',
          check50Plus: 'circle',
        },
        {
          subtitle: '功能定制',
          desc: '根据您的业务定制相关功能',
          check50: 'delete',
          check50Plus: 'circle',
        },
        {
          subtitle: '对象存储',
          desc: '支持对象存储定制服务',
          check50: 'delete',
          check50Plus: 'circle',
        }
      ]
    },
    {
      title: '客户端支持',
      id: 'version-price10',
      subContent: [
        {
          subtitle: '网页端',
          desc: '从浏览器访问',
          check50: 'circle',
          check50Plus: 'circle',
        },
        {
          subtitle: 'Windows端',
          desc: '用Windows电脑访问',
          check50: 'circle',
          check50Plus: 'circle',
        },
        {
          subtitle: 'Mac端',
          desc: '从Mac电脑访问',
          check50: 'circle',
          check50Plus: 'circle',
        },
        {
          subtitle: 'Linux端',
          desc: '从Linux系统电脑访问',
          check50: 'ring',
          check50Plus: 'circle',
        },
        {
          subtitle: '安卓端',
          desc: '安卓手机及平板访问',
          check50: 'circle',
          check50Plus: 'circle',
        },
        {
          subtitle: 'iOS端',
          desc: '从iPhone/iPad访问',
          check50: 'circle',
          check50Plus: 'circle',
        },
        {
          subtitle: 'H5端',
          desc: '从手机浏览器访问或集成现有移动端服务',
          check50: 'ring',
          check50Plus: 'circle',
        }
      ]
    },
    {
      title: '技术服务',
      id: 'version-price11',
      subContent: [
        {
          subtitle: '产品升级',
          desc: '产品功能升级',
          check50: 'circle',
          check50Plus: 'ring',
        },
        {
          subtitle: '热线支持',
          desc: '为您提供电话热线咨询服务',
          check50: '7*12小时',
          check50Plus: '7*24小时',
        },
        {
          subtitle: '在线支持',
          desc: '为您提供及时的咨询服务',
          check50: '5*8小时',
          check50Plus: '7*24小时',
        },
        {
          subtitle: '工单支持',
          desc: '为您提供的维护工单服务',
          check50: 'delete',
          check50Plus: 'circle',
        },
        {
          subtitle: '部署支持',
          desc: '基础远程部署服务',
          check50: 'delete',
          check50Plus: 'circle',
        },
        {
          subtitle: '技术服务',
          desc: '专职工程师一对一的技术支持',
          check50: 'circle',
          check50Plus: 'circle',
        },
        {
          subtitle: '产品使用培训',
          desc: '用户及管理员使用远程培训',
          check50: 'circle',
          check50Plus: 'circle',
        },
        {
          subtitle: '产品实施支持',
          desc: '个性化需求实施支持',
          check50: 'circle',
          check50Plus: 'circle',
        },
        {
          subtitle: '到门服务',
          desc: '现场技术支持及使用培训',
          check50: 'delete',
          check50Plus: 'circle',
        }
      ]
    },
  ];

    // 显示表单
    public showForm() {
      this._sendFormStatus.formStatu.emit(this.isShowForm = true);
      document.querySelector('html').style.overflow = 'hidden';
    }

  ngOnInit(): void {
    this._jumpPage.goTop();
  }

}
