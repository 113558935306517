<div>
  <div>
    <app-header
      [title]="sversionTitle"
      [desc]="sversionTDesc"
      [imgSrc]="sversionTImgSrc"
    ></app-header>
  </div>

  <div class="product-smart-versions-wrapper">
    <div class="product-smart-versions">
      <div class="product-smart-versions-content" *ngFor="let item of functionIntroduction">
        <div class="product-smart-versions-content-title">{{ item.title | translate }}</div>
        <div class="product-smart-versions-content-desc">
          <dl *ngFor="let descItem of item.desc">
            <img [src]="descItem.src" [alt]="item.title">
            <dt class="product-smart-versions-subtitle">{{ descItem.title | translate }}</dt>
            <dd class="product-smart-versions-subdesc">{{ descItem.desc | translate }}</dd>
          </dl>
        </div>
      </div>
      <div class="product-smart-versions-table" *ngFor=" let tableItem of versionTable">
        <div class="product-smart-versions-table-title">{{ tableItem.title | translate }}</div>
        <div class="product-smart-versions-table-desc">
          <table>
            <tr>
              <td class="tr-head">{{ 'product.smart-version.table1-title' | translate }}</td>
              <td class="tr-head tr-head-2">{{ 'product.smart-version.table2-title' | translate }}</td>
              <td class="tr-head">{{ 'product.smart-version.table3-title' | translate }}</td>
            </tr>
            <tr>
              <td>{{ 'product.smart-version.table1-desc1' | translate }}</td>
              <td>{{ 'product.smart-version.table2-desc1' | translate }}</td>
              <td>{{ 'product.smart-version.table3-desc1' | translate }}
                <img src="/assets/img/Sversion/check.png">
              </td>
            </tr>
            <tr>
              <td>{{  'product.smart-version.table1-desc2' | translate }}</td>
              <td>{{  'product.smart-version.table2-desc2' | translate }}</td>
              <td>{{ 'product.smart-version.table3-desc2' | translate }}
                <img src="/assets/img/Sversion/check.png">
              </td>
            </tr>
            <tr>
              <td>{{ 'product.smart-version.table1-desc3' | translate }}</td>
              <td>{{ 'product.smart-version.table2-desc3' | translate }}</td>
              <td>{{ 'product.smart-version.table3-desc3' | translate }}
                <img src="/assets/img/Sversion/check.png">
              </td>
            </tr>
            <tr>
              <td>{{ 'product.smart-version.table1-desc4' | translate }}</td>
              <td>{{ 'product.smart-version.table2-desc4' | translate }}</td>
              <td>{{ 'product.smart-version.table3-desc4' | translate }}
                <img src="/assets/img/Sversion/check.png">
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </div>

  <app-suspension></app-suspension>

  <app-footer></app-footer>
</div>