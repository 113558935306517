import { Component, OnInit, Input } from '@angular/core';
import {nextTick} from 'q';

@Component({
  selector: 'app-video-popup',
  templateUrl: './video-popup.component.html',
  styleUrls: ['./video-popup.component.less']
})
export class VideoPopupComponent implements OnInit {
  @Input() public key: string
  constructor() { }

  // 视频引入地址
  // public videoSrc: string = 'https://cdn.oatos.com/videos/oatos_intro_cn.mp4';
  // public videoSrc: string = 'https://vd.oatos.com/oatos_intro_cn.mp4';
  public videoSrc: string = '../../assets/img/home/home-video.m4v';
  public isShowVideo: boolean = false;

  // 教育版介绍视频引入地址
  // public eduVideoSrc: string = 'http://vd.oatos.com/oatosEduFinal.mp4';

  ngOnInit() {
  }

  // 隐藏弹窗
  public hideVideoPopup() {
    this.isShowVideo = false;
    document.querySelector('html').style.overflow = 'auto';
    // this.$emit("hideVideo");
  }
  // 弹出弹窗,显示视频,播放视频
  public showVideoPopup(event, popupVideo) {
    this.isShowVideo = true;
    nextTick(() => {
      event.currentTime = 0;
      event.play();
      document.querySelector('html').style.overflow = 'hidden';
    });
  }

}
