import { Component, OnInit } from '@angular/core';
import { JumpPageService } from 'src/app/_share/service/jump-page.service';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-content-control',
  templateUrl: './content-control.component.html',
  styleUrls: ['./content-control.component.less']
})
export class ContentControlComponent implements OnInit {

  constructor(
    private _jumpPage: JumpPageService,
    private meta: Meta,
    private titleService: Title
  ) { 
    this.titleService.setTitle('内容管控-燕麦云智能企业云盘');
    this.meta.addTag({ name: 'keywords', content: '内容管控,燕麦云,企业云盘,企业网盘,OATOS,私有云存储' });
    this.meta.addTag({ name: 'description', content: '燕麦云智能企业云盘集成企业级的内容管控能力，从文档的上传与访问、共享和协同、备份或销毁的各个环节都提供全面的安全和管控措施，并结合政企的使用场景打造内容管控策略和方案。燕麦云智能企业云盘服务热线：4000861108。' });
  }

  public contentTitle: string = 'product.content-control.contentTitle';
  public contentBtn: string = 'product.content-control.contentBtn';
  public contentImgSrc: string = './assets/img/content/control-background.png';
  public contentLink:string = 'https://app.oatos.com/os/#/welcome/sign-up';  //链接地址

  public controlContent1: Array<any> = [
    {
      imgUrl: './assets/img/content/control-content1-img1.png',
      title: 'product.content-control.content1-title1',
      desc: 'product.content-control.content1-desc1' 
    },
    {
      imgUrl: './assets/img/content/control-content1-img2.png',
      title: 'product.content-control.content1-title2',
      desc: 'product.content-control.content1-desc2'
    },
    {
      imgUrl: './assets/img/content/control-content1-img3.png',
      title: 'product.content-control.content1-title3',
      desc: 'product.content-control.content1-desc3'
    }
  ]

  public controlContent2: Array<any> = [
    {
      title: 'product.content-control.content2-title',
      children: [
        {
          imgUrl: './assets/img/content/control-content2-1.png',
          title: 'product.content-control.content2-title1',
          desc: 'product.content-control.content2-desc1'
        },
        {
          imgUrl: './assets/img/content/control-content2-2.png',
          title: 'product.content-control.content2-title2',
          desc: 'product.content-control.content2-desc2'
        },
        {
          imgUrl: './assets/img/content/control-content2-3.png',
          title: 'product.content-control.content2-title3',
          desc: 'product.content-control.content2-desc3'
        },
        // {
        //   imgUrl: './assets/img/content/control-content2-4.png',
        //   title: '企业级回收站',
        //   desc: '特有的企业级回收站设计，普通账号彻底删除或清空的文件进入企业回收站，管理员可在此找回文件。'
        // },
        {
          imgUrl: './assets/img/content/control-content2-5.png',
          title: 'product.content-control.content2-title4',
          desc: 'product.content-control.content2-desc4'
        },
        {
          imgUrl: './assets/img/content/control-content2-7.png',
          title: 'product.content-control.content2-title5',
          desc: 'product.content-control.content2-desc5'
        },
        {
          imgUrl: './assets/img/content/control-content2-8.png',
          title: 'product.content-control.content2-title6',
          desc: 'product.content-control.content2-desc6'
        }
      ]
    },
  ]

  ngOnInit(): void {
    this._jumpPage.goTop();
  }

}
