<div>
  <div>
    <app-header
      [title]="serviceTitle"
      [desc]="serviceDesc"
      [btn]="serviceBtn"
      [imgSrc]="serviceImgSrc"
      [route]="serviceRoute"
    ></app-header>
  </div>

  <div class="product-service-guarantee-wrapper">
    <div class="product-service-guarantee">
      <div class="product-service-guarantee-part1-wrapper">
        <div class="product-service-guarantee-part1">
          <div class="product-service-guarantee-title">
            {{ serviceGuaranteeContent1[0].title | translate}}
          </div>
          <div class="product-service-guarantee-desc">
            {{ serviceGuaranteeContent1[0].desc | translate }}
          </div>
          <div class="product-service-guarantee-content">
            <dl *ngFor="let item of serviceGuaranteeContent1[0].subContent">
              <dt>{{ item.subtitle | translate }}</dt>
              <dd>{{ item.desc | translate }}</dd>
            </dl>
          </div>
          <div class="product-service-guarantee-process">
            <div class="product-service-guarantee-process-title">
              {{ serviceGuaranteeContent1[0].processTitle | translate }}
            </div>
            <div class="product-service-guarantee-process-content">
              <dl *ngFor="let item of serviceGuaranteeContent1[0].processContent; let index =index">
                <div class="number"><img [src]="item.src" alt=""></div>
                <dt>{{ item.subtitle | translate}}</dt>
                <dd>{{ item.desc | translate }}</dd>
                <div class="arrow" *ngIf="index != serviceGuaranteeContent1[0].processContent.length - 1"><img src="/assets/img/service/arrow.png" alt=""></div>
              </dl>
            </div>
          </div>
        </div>
      </div>
      <div class="product-service-guarantee-part2-wrapper">
        <div class="product-service-guarantee-part2">
          <div class="product-service-guarantee-title">
            {{ serviceGuaranteeContent2[0].title | translate }}
          </div>
          <div class="product-service-guarantee-desc">
            {{ serviceGuaranteeContent2[0].desc | translate }}
          </div>
          <div class="product-service-guarantee-content">
            <dl *ngFor="let item of serviceGuaranteeContent2[0].subContent">
              <div><img [src]="item.src" alt=""></div>
              <dt>{{ item.subtitle | translate }}</dt>
              <dd>{{ item.desc | translate }}</dd>
            </dl>
          </div>
          <div class="product-service-guarantee-response">
            <div class="product-service-guarantee-response-title">
              {{ serviceGuaranteeContent2[0].responseTitle | translate }}
            </div>
            <div class="product-service-guarantee-response-content">
              <dl *ngFor="let item of serviceGuaranteeContent2[0].responseContent">
                <dt>{{ item.subtitle | translate }}</dt>
                <dd>{{ item.desc | translate }}</dd>
                <dd>{{ item.time | translate }}</dd>
              </dl>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <app-suspension></app-suspension>

  <app-footer></app-footer>

</div>