import { Component, OnInit } from '@angular/core';
import { JumpPageService } from 'src/app/_share/service/jump-page.service';

@Component({
  selector: 'app-finance',
  templateUrl: './finance.component.html',
  styleUrls: ['./finance.component.less']
})
export class FinanceComponent implements OnInit {

  constructor(
    private _jumpPage: JumpPageService,
  ) { }

  public financeTitle = 'product.finance.financeTitle'; // 标题
  public financeDesc = 'product.finance.financeDesc'; //描述
  public financeBtn = 'product.finance.financeBtn'; 
  public financeImgSrc = './assets/img/finance/finance-background.png'; //背景图
  public financeLink = 'https://app.oatos.com/os/#/welcome/sign-up'; //链接地址

  public caseContent: Array<any> = [
    {
      imgUrl: './assets/img/finance/finance-case-img1.png'
    },
    {
      imgUrl: './assets/img/finance/finance-case-img2.png'
    },
    {
      imgUrl: './assets/img/finance/finance-case-img3.png'
    }
  ]

  public featureTitle = 'product.finance.featureTitle';
  public featureDesc = 'product.finance.featureDesc';

  public customizeTitle = 'product.finance.customizeTitle';
  public customizeContent: Array<any> = [
    {
      imgUrl: './assets/img/finance/finance-customize-img1.svg',
      title: 'product.finance.customizeContent-title1',
      desc: 'product.finance.customizeContent-desc1'
    },
    {
      imgUrl: './assets/img/finance/finance-customize-img2.svg',
      title: 'product.finance.customizeContent-title2',
      desc: 'product.finance.customizeContent-desc2'
    },
    {
      imgUrl: './assets/img/finance/finance-customize-img3.svg',
      title: 'product.finance.customizeContent-title3',
      desc: 'product.finance.customizeContent-desc3'
    },
    {
      imgUrl: './assets/img/finance/finance-customize-img4.svg',
      title: 'product.finance.customizeContent-title4',
      desc: 'product.finance.customizeContent-desc4'
    }
  ]

  ngOnInit(): void {
    this._jumpPage.goTop();
  }

}
