import { Component, OnInit } from '@angular/core';
import { JumpPageService } from 'src/app/_share/service/jump-page.service';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-safety',
  templateUrl: './safety.component.html',
  styleUrls: ['./safety.component.less']
})
export class SafetyComponent implements OnInit {

  constructor(
    private _jumpPage: JumpPageService,
    private meta: Meta,
    private titleService: Title
  ) { 
    this.titleService.setTitle('安全保障-燕麦云智能企业云盘');
    this.meta.addTag({ name: 'keywords', content: '云盘安全,燕麦云,企业云盘,企业网盘,OATOS,私有云存储' });
    this.meta.addTag({ name: 'description', content: '燕麦云智能企业云盘安全体系让文件从上传起即由燕麦云进行加密传输、加密存储，通过严格的账号、权限管控和文件保护机制保障文件被安全使用，在新的数据安全环境下，结合政企使用场景提供可靠解决方案及全方位云盘安全保障，有效守护数据资产。燕麦云智能企业云盘服务热线：4000861108。' });
   }

  public safetyTitle = '全周期保障文件存储和访问安全'; // 标题
  public safetyBtn = '免费试用'; // 描述
  public safetyImgSrc = './assets/img/safety/safety-background.png'; //背景图
  public safetyLink = 'https://app.oatos.com/os/#/welcome/sign-up'; //链接地址

  public safetyContent1: Array<any> = [
    {
      imgUrl: './assets/img/safety/safety-content1-img1.png',
      title: '智能安全防护',
      desc: '实时监控账号及终端行为，智能感知异常操作，支持白名单与网段访问策略设置，随时切断可疑终端的接入访问。'
    },
    {
      imgUrl: './assets/img/safety/safety-content1-img2.png',
      title: 'Mimic Vault ™拟态保险箱',
      desc: '基于国家重点网络安全研发专项计划“网络空间拟态防御”，由燕麦云实验室和北京大学先进网络共同研发完成的产品，提供极致私密，安全的网络存储空间。',
      btn: '了解更多 >>',
      link: '/functions/mimic'
    }
  ]

  public safetyContent2: Array<any> = [
    {
      title: '传输与储存',
      children: [
        {
          imgUrl: './assets/img/safety/safety-content2-item1-img1.png',
          title: '加密存储',
          desc: '文件碎片化上传，并对每个文件块加密存储'
        },
        {
          imgUrl: './assets/img/safety/safety-content2-item1-img2.png',
          title: 'SSL加密传输',
          desc: '通过银行级SSL256位密码加密传输数据，保护数据传输安全'
        },
        {
          imgUrl: './assets/img/safety/safety-content2-item1-img3.png',
          title: '容灾备份',
          desc: '提供系统容灾及数据备份双重数据安全方案'
        },
      ]
    },
    {
      title: '账号及设备安全',
      children: [
        {
          imgUrl: './assets/img/safety/safety-content2-item1-img4.png',
          title: '燕麦双因子',
          desc: '账号绑定燕麦双因子，账号登录或关键操作时校验动态密码进行二次身份验证。'
        },
        {
          imgUrl: './assets/img/safety/safety-content2-item1-img5.png',
          title: '账号锁定',
          desc: '自动锁定异常登录账号，或主动锁定账号；禁止已锁定账号接入燕麦云，及时保护企业数据资产。'
        },
        {
          imgUrl: './assets/img/safety/safety-content2-item1-img6.png',
          title: '账号有效期',
          desc: '账号可设置有效期，在到期后自动锁定账号，禁止该账号访问燕麦云。'
        },
        {
          imgUrl: './assets/img/safety/safety-content2-item1-img7.png',
          title: '网段访问管控',
          desc: '开启IP防护，只允许您指定的IP地址访问，减少盗用账号访问数据的风险。'
        },
        {
          imgUrl: './assets/img/safety/safety-content2-item1-img8.png',
          title: '用户访问管控',
          desc: '为账号设置特定访问ip，仅允许用户在在特定区域访问燕麦云账户内的数据。'
        },
        {
          imgUrl: './assets/img/safety/safety-content2-item1-img9.png',
          title: '终端控制',
          desc: '管控登录燕麦云的终端，支持多终端同时登陆，可在线解除异常登录的设备。'
        },
        {
          imgUrl: './assets/img/safety/safety-content2-item1-img11.png',
          title: '登录行为监控',
          desc: '智能监控账号的登录行为，当有用户触发了系统预警时系统将发送通知邮件到相关人员。'
        }
      ]
    },
    {
      title: '平台及服务器安全', 
      children: [
        {
          imgUrl: './assets/img/safety/safety-content2-item1-img12.png',
          title: '本地部署数据私有',
          desc: '支持软件、硬件、网络三位一体在企业内部部署燕麦云，所有数据均由企业掌控。'
        },
        {
          imgUrl: './assets/img/safety/safety-content2-item1-img13.png',
          title: '数据加密存储',
          desc: '采用自主研发的文件加密技术，守护您的数据安全存储，同时不影响数据的使用效率。'
        },
        {
          imgUrl: './assets/img/safety/safety-content2-item1-img14.png',
          title: '数据容量备份',
          desc: '强大的分布式网络存储架构，支持数据跨地域自动备份，服务器多节点容灾保护。'
        },
        {
          imgUrl: './assets/img/safety/safety-content2-item1-img15.png',
          title: '数据隔离存储',
          desc: '企业数据云端独立存储，每个企业享有独立空间，杜绝企业数据互相穿透。'
        },
        {
          imgUrl: './assets/img/safety/safety-content2-item1-img16.png',
          title: '防DDOS攻击',
          desc: '对于大量会导致云盘服务器瘫痪的请求予以阻止，从而确保燕麦云服务的正常运行。'
        },
        {
          imgUrl: './assets/img/safety/safety-content2-item1-img17.png',
          title: '防XSS',
          desc: '对于用户提交的非法数据进行安全处理，使其无法利用燕麦云为载体获取其它用户的信息。'
        },
        {
          imgUrl: './assets/img/safety/safety-content2-item1-img17.png',
          title: '防CSRF',
          desc: '对访问请求进行安全效验，并阻止用户构造的虚假请求，从而有效的保障燕麦云安全。'
        },
      ]
    }
  ]

  ngOnInit(): void {
    this._jumpPage.goTop();
  }

}
