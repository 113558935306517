import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class OperationInterfaceService {

  constructor(
    private _http: HttpClient,
  ) { }

  /**
   * 获取uuid
   */
  public getUuid(): Observable<any> {
    const url = '/operator/uuid';
    return this._http.get(url);
  }

  /**
   * 获取手机验证码
   */
  public getPhoneCode(request): Observable<any> {
    const url = '/operator/send-sms';
    let data = {
      data: {
        contactNumber: request.contactNumber,
        countryCode: request.countryCode,
        uuid: request.uuid,
        verificationCode: request.verificationCode
      }
    };
    return this._http.post(url, data);
  }

  /**
   * 获取图片验证码
   */
  public getPicCaptchaUrl(request) {
    let captcha_time = new Date().getTime();
    let url = `/operator/verification-code?&uuid=${request.uuid}&captchaTime=${captcha_time}`;
    return url;
  }

  /**
   * 提交申请表单
   */
  public privateCloudApplyFor(request): Observable<any> {
    let url = '/operator/add/private-cloud';
    let data = {
      data:{
        nameOrganization: request.nameOrganization,
        industryInvolved: request.industryInvolved,
        organizationScale: request.organizationScale,
        contacts: request.contacts,
        contactNumber: request.contactNumber,
        uuid: request.uuid,
        verificationCode: request.verificationCode,
        sendSmsCode: request.sendSmsCode,
        countryCode: request.countryCode,
      }
    };
    return this._http.post(url, data);
  }

   /**
   * 提交教育版申请表单
   */
  public eduApplication(request): Observable<any> {
    let url = '/operator/add/edu';
    let data = {
      data:{
        schoolName: request.schoolName,
        schoolType: request.schoolType,
        schoolNumber: request.schoolNumber,
        name: request.name,
        mobile: request.mobile,
        uuid: request.uuid,
        code: request.code,
        verificationCode: request.verificationCode,
      }
    };
    return this._http.post(url, data);
  }


  /**
   * 提交渠道合作表单
   */
  public channelApplyFor(request): Observable<any> {
    let url = '/operator/add/channel';
    let data = {
      data:{
        nameOrganization: request.nameOrganization,
        institutionAddress: request.institutionAddress,
        contacts: request.contacts,
        contactNumber: request.contactNumber.toString(),
        contactAddress: request.contactAddress,
        industryInvolved: request.industryInvolved,
        organizationScale: request.organizationScale,
        intentionDescribe: request.intentionDescribe,
        verificationCode: request.verificationCode,
        uuid: request.uuid,
      }
    };
    return this._http.post(url, data);
  }

  /**
   * 获取产品动态
   */
  public getProduct(): Observable<any> {
    let url = 'https://www.oatos.com/news/product-news';

    return this._http.get(url, {params: {'json': 'get_recent_posts', 'count': '1', 'include': 'categories,date,title,url'}});
  }


  // 获取行业资讯
  public getNews(): Observable<any> {
    let url = `https://www.oatos.com/news/trade-news`;
    return this._http.get(url, {params: {'json': 'get_recent_posts', 'count': '3', 'include': 'categories,date,title,url,thumbnail'}});
  }

  // 协议政策
  public getPactDetails(request: {
    type: string;
  }): Observable<any> {
    let url: string = '/operator/publicity';

    let options: any = {
      params: {
        type: request.type,
      }
    };

    return this._http.get(url, options);
  }

}
