<div>
    <div class="oat-channel-cooperation-header-wrapper" [ngStyle]="{backgroundImage:'url(' + imgSrc + ')'}"
         [ngClass]="{'oat-channel-cooperation-header-wrapper-ipad': this._userAgent.userAgent === 'iPad'}">
<!--      <div class="oat-header-background-wrapper">-->
<!--        <div class="oat-header-background&#45;&#45;image" [ngStyle]="{backgroundImage:'url(' + imgSrc + ')'}"></div>-->
<!--      </div>-->
      <div class="oat-channel-cooperation-header">
        <app-header-nav></app-header-nav>
        <div class="oat-channel-cooperation-content-wrapper" [ngClass]="{'oat-channel-cooperation-content-wrapper-ipad': this._userAgent.userAgent === 'iPad'}">
          <div class="oat-channel-cooperation-content">
            <div class="oat-channel-cooperation-desc">
              <span>{{ title | translate }}</span>
              <i>{{ intro | translate }}</i>
            </div>
            <div class="oat-channel-cooperation-form">
              <form  #channelForm="ngForm">
                <div class="form-group">
                  <label for="name"><span>*</span>{{channelCooperationContent.name | translate}}</label>
                  <input type="text" id="name" required  name="name" [(ngModel)]="ruleForm.name" #name="ngModel"
                  [ngClass]="name.pristine && name.untouched || name.valid ? '':'oat-channel-cooperation-warning-input'">
                  <div class="alert alert-danger oat-channel-cooperation-warning" [hidden]="name.pristine && name.untouched || name.valid" >
                    {{'product.channel-cooperation.name-valid-empty' | translate}}
                  </div>
                </div>
                <div class="form-group">
                  <label for="site">{{channelCooperationContent.site | translate}}</label>
                  <input type="text" class="form-control" id="site"   name="site" [(ngModel)]="ruleForm.site" #site="ngModel">
                </div>
                <div class="form-group">
                  <label for="contacts"><span>*</span>{{channelCooperationContent.contacts | translate}}</label>
                  <input type="text" id="contacts" required  name="contacts" [(ngModel)]="ruleForm.contacts" #contacts="ngModel"
                  [ngClass]="contacts.pristine && contacts.untouched || contacts.valid ? '':'oat-channel-cooperation-warning-input'">
                  <div class="alert alert-danger oat-channel-cooperation-warning" [hidden]="contacts.pristine && contacts.untouched || contacts.valid" >
                    {{'product.channel-cooperation.contacts-valid-empty' | translate}}
                  </div>
                </div>
                <div class="form-group">
                  <label for="number"><span>*</span>{{channelCooperationContent.number | translate}}</label>
                  <input type="text" id="number" required  name="number" [(ngModel)]="ruleForm.number" #number="ngModel"
                  [ngClass]="number.pristine && number.untouched || number.valid ? '':'oat-channel-cooperation-warning-input'">
                  <div class="alert alert-danger oat-channel-cooperation-warning" [hidden]="number.pristine && number.untouched || number.valid">
                    {{'product.channel-cooperation.number-valid-empty' | translate}}
                  </div>
                </div>
                <div class="form-group">
                  <label for="address"><span>*</span>{{channelCooperationContent.address | translate}}</label>
                  <input type="text"  id="address" required  name="address" [(ngModel)]="ruleForm.address" #address="ngModel"
                  [ngClass]="address.pristine && address.untouched || address.valid ? '':'oat-channel-cooperation-warning-input'">
                  <div class="alert alert-danger oat-channel-cooperation-warning" [hidden]="address.pristine && address.untouched || address.valid">
                    {{'product.channel-cooperation.address-valid-empty' | translate}}
                  </div>
                </div>
                <div class="form-group">
                  <label for="job">{{channelCooperationContent.job | translate}}</label>
                  <input type="text"  id="job" name="job" [(ngModel)]="ruleForm.job" #job="ngModel">
                </div>
                <div class="form-group">
                  <label for="scale">{{channelCooperationContent.scale | translate}}</label>
                  <input type="text" id="scale" name="scale" [(ngModel)]="ruleForm.scale" #scale="ngModel">
                </div>
                <div class="form-group">
                  <label for="desc">{{channelCooperationContent.desc | translate}}</label>
                  <textarea rows="2"  id="desc" name="desc" [(ngModel)]="ruleForm.desc" #desc="ngModel"></textarea>
                </div>
                <div class="form-group oat-channel-cooperation-code-item">
                  <label for="code"><span>*</span>{{channelCooperationContent.code | translate}}</label>
                  <input type="text"  id="code" required  name="code" [(ngModel)]="ruleForm.code" #code="ngModel"
                  [ngClass]="code.pristine && code.untouched || code.valid ? '':'oat-channel-cooperation-warning-input'">
                  <div class="oat-channel-cooperation-code" (click)="getPicCaptchaUrl(this.uuid)"><img [src]="codeSrc"></div>
                  <div class="alert alert-danger oat-channel-cooperation-warning" [hidden]="code.pristine && code.untouched || code.valid" >
                    {{'product.channel-cooperation.code-valid-empty' | translate}}
                  </div>
                </div>
                <div class="form-group">
                  <button type="primary" (click)="submitForm()">{{submitBtn | translate}}</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>

    <app-suspension></app-suspension>

    <app-footer></app-footer>
  </div>