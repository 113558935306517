import { Injectable, EventEmitter } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SendFormStatuService {

  public formStatu: any;

  public eduFormStatu: any;

  constructor() { 
    this.formStatu = new EventEmitter();
  }
}
