<div>
  <div>
    <app-header
      [title]="priceTitle | translate"
      [desc]="priceDesc | translate"
      [imgSrc]="priceImgSrc"
    ></app-header>
  </div>

  <!--版本对比-->
  <div class="product-version-price-wrapper">
    <!-- 版本价格 -->
    <div class="product-version-price">
      <div class="product-version-price-title">燕麦云智能企业云盘版本价格</div>

      <div class="product-version-price-compared">
        <div
          class="product-version-price-compared-item"
          *ngFor="let price of priceContent"
        >
          <div class="product-version-price-compared-item-header">
            <div class="product-version-price-compared-item-header-title">
              {{ price.title | translate }}
            </div>
            <div
              class="product-version-price-compared-item-header-price"
              *ngIf="price.price"
            >
              低至 <span style="font-size: 2rem">10元</span> / 账号 / 月
            </div>
            <div class="product-version-price-compared-item-header-desc">
              {{ price.desc | translate }}
            </div>
          </div>

          <div class="product-version-price-compared-item-body">
            <ul>
              <li *ngFor="let item of price.list">{{ item | translate }}</li>
            </ul>

            <ng-container *ngIf="price.link">
              <a [href]="price.link" target="_blank">
                {{ price.button | translate }}
              </a>
            </ng-container>
            <ng-container *ngIf="!price.link">
              <a (click)="showForm()">
                {{ price.button | translate }}
              </a>
            </ng-container>
          </div>
        </div>
      </div>
    </div>

    <!-- 功能对比 -->
    <div class="product-version-price">
      <div class="product-version-price-title">
        燕麦云智能企业云盘版本功能对比
      </div>
      <div class="product-product-price-ps">
        <div>
          <img src="/assets/img/price/ring.svg" />
          <span> 可扩展 </span>
        </div>
        <div>
          <img src="/assets/img/price/disc.svg" />
          <span> 满足 </span>
        </div>
        <div>
          <img src="/assets/img/price/delete.svg" />
          <span> 不支持 </span>
        </div>
      </div>
      <div class="product-version-price-table">
        <div class="product-version-price-header-one">
          <span></span>
          <span
            ><div>燕麦云标准版</div>
            <strong>官网在线使用</strong></span
          >
          <span
            ><div>私有化定制版</div>
            <strong>客户本地部署</strong></span
          >
        </div>
        <div class="product-version-price-header-two">
          <span>价格</span>
          <span>按套餐收费</span>
          <span>按账号授权</span>
        </div>
        <div
          class="product-version-price-item"
          *ngFor="let item of versionContent"
        >
          <div class="product-version-price-item-title" [id]="item.id">
            {{ item.title }}
          </div>
          <dl *ngFor="let subItem of item.subContent">
            <dt [innerHTML]="subItem.subtitle"></dt>
            <dd>{{ subItem.desc }}</dd>
            <dd>
              <span *ngIf="subItem.check50 === 'circle'">
                <img src="/assets/img/price/disc.svg" alt="" />
              </span>
              <span *ngIf="subItem.check50 === 'ring'">
                <img src="/assets/img/price/ring.svg" alt="" />
              </span>
              <span *ngIf="subItem.check50 === 'delete'">
                <img src="/assets/img/price/delete.svg" alt="" />
              </span>
              <span
                *ngIf="
                  subItem.check50 !== 'circle' &&
                  subItem.check50 !== 'ring' &&
                  subItem.check50 !== 'delete'
                "
              >
                {{ subItem.check50 }}
              </span>
            </dd>
            <dd>
              <span *ngIf="subItem.check50Plus === 'circle'">
                <img src="/assets/img/price/disc.svg" alt="" />
              </span>
              <span *ngIf="subItem.check50Plus === 'ring'">
                <img src="/assets/img/price/ring.svg" alt="" />
              </span>
              <span *ngIf="subItem.check50Plus === 'delete'">
                <img src="/assets/img/price/delete.svg" alt="" />
              </span>
              <span
                *ngIf="
                  subItem.check50 !== 'circle' &&
                  subItem.check50 !== 'ring' &&
                  subItem.check50 !== 'delete'
                "
              >
                {{ subItem.check50Plus }}
              </span>
            </dd>
          </dl>
        </div>
      </div>
    </div>
  </div>

  <app-suspension></app-suspension>

  <app-footer></app-footer>
</div>
