import { Component, OnInit } from '@angular/core';
import { JumpPageService } from "src/app/_share/service/jump-page.service";

@Component({
  selector: 'app-site-map',
  templateUrl: './site-map.component.html',
  styleUrls: ['./site-map.component.less']
})
export class SiteMapComponent implements OnInit {

  constructor(
    private _jumpPage: JumpPageService,
  ) { }

  public mapTitle: string = '网站地图';
  public mapUrl: string = '/assets/img/siteMap/sitemap-bg.png';

  public mapsite: Array<any> = [
    {
      title: '首页',
      route: '/home'
    },
    {
      title: '产品',
      children: [
        {
          subTitle: '安全保障',
          route: '/safety'
        },
        {
          subTitle: '办公协同',
          route: '/collaboration'
        },
        {
          subTitle: '内容管控',
          route: '/content-controls'
        },
        {
          subTitle: '私有化定制',
          route: '/customization'
        },
      ]
    },
    {
      title: '解决方案',
      route: '/solutions'
    },
    {
      title: '价格',
      route: '/pricing'
    },
    {
      title: '支持',
      children: [
        {
          subTitle: '帮助中心',
          linkUrl: 'https://www.oatos.com/help/'
        },
        {
          subTitle: '燕麦课堂',
          linkUrl: 'https://www.oatos.com/courses/'
        },
        {
          subTitle: '服务保障',
          route: '/service'
        },

      ]
    },
    {
      title: '下载',
      route: '/download'
    },
    {
      title: '关于我们',
      route: '/aboutUs'
    }
  ]

  ngOnInit(): void {
    this._jumpPage.goTop();
  }

}
