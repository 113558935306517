<div>
  <div>
    <app-header
      [title]="mimicTitle | translate"
      [desc]="mimicDesc | translate"
      [imgSrc]="mimicImgSrc"
    ></app-header>
  </div>

  <div class="product-mimicry-wrapper">
    <div class="product-mimicry">
      <div class="product-mimicry-item-wrapper" *ngFor="let item of mimicryContent">
        <div class="product-mimicry-item-wrapper-title">
          {{ item.title | translate }}
        </div>
        <div class="product-mimicry-item-wrapper-desc">
          <img [src]="item.src" alt="">
          <div class="product-mimicry-item-wrapper-detail">
            <div *ngFor="let descItem of item.desc">
              {{ descItem.detail | translate }}
            </div>
          </div>
        </div>
      </div>
      <div class="product-mimicry-bottom" *ngFor="let subitem of mimicryContentDetail">
          <div class="product-mimicry-bottom-title">
            {{ subitem.title | translate}}
          </div>
          <div class="product-mimicry-bottom-desc">
            {{ subitem.desc | translate }}
          </div>
          <div class="product-mimicry-bottom-detail">
            <dl *ngFor="let detailitem of subitem.detail">
              <img [src]="detailitem.src" alt="">
              <dd *ngIf="detailitem.desc">{{ detailitem.desc | translate }}</dd>
            </dl>
          </div>
      </div>
    </div>
  </div>

  <app-suspension></app-suspension>

  <app-footer></app-footer>
</div>