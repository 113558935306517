import { Component, OnInit } from '@angular/core';
import { JumpPageService } from "src/app/_share/service/jump-page.service";
import { Meta, Title } from '@angular/platform-browser';
import { OperationInterfaceService } from 'src/app/_share/service/operation-interface.service';

@Component({
  selector: 'app-terms',
  templateUrl: './terms.component.html',
  styleUrls: ['./terms.component.less']
})
export class TermsComponent implements OnInit {

  constructor(
    private _jumpPage: JumpPageService,
    private meta: Meta,
    private titleService: Title,
    private _http: OperationInterfaceService,
  ) {
    this.titleService.setTitle('服务条款-燕麦云智能企业云盘');
    this.meta.addTag({ name: 'keywords', content: '服务条款,燕麦云,企业云盘,企业网盘,OATOS,私有云存储' });
    this.meta.addTag({ name: 'description', content: '燕麦云服务条款，燕麦云持续为政企客户提供稳定、专业、安全、高效的企业云盘和在线办公服务。燕麦云服务热线：4000861108。' });
  }

  public title: string = '';
  public content: string = '';

  ngOnInit(): void {
    this._jumpPage.goTop();

    this._http.getPactDetails({
      type: 'user',
    }).subscribe({
      next: (result: any) => {
        this.title = result.data.title;
        this.content = result.data.content;
      },
      error: (errorCode: string) => {
        console.error(errorCode);
      }
    })
  }

}
