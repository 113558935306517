import { Component, OnInit } from '@angular/core';
import { JumpPageService } from 'src/app/_share/service/jump-page.service';

@Component({
  selector: 'app-trade',
  templateUrl: './trade.component.html',
  styleUrls: ['./trade.component.less']
})
export class TradeComponent implements OnInit {

  constructor(
    private _jumpPage: JumpPageService,
  ) { }

  public tradeTitle = '零售行业内容协作解决方案'; // 标题
  public tradeDesc = '管理海量商业数据变得更容易'; //描述
  public tradeBtn = '免费试用'; 
  public tradeImgSrc = './assets/img/trade/trade-background.png'; //背景图
  public tradeLink = 'https://app.oatos.com/os/#/welcome/sign-up'; //链接地址

  public caseContent: Array<any> = [
    {
      imgUrl: './assets/img/trade/trade-case-img1.png'
    },
    {
      imgUrl: './assets/img/trade/trade-case-img2.png'
    },
    {
      imgUrl: './assets/img/trade/trade-case-img3.png'
    }
  ]

  public featureTitle = '零售行业内容协作的特点';
  public featureDesc = '零售系统积累了大量数据，这些数据存储在不同不同门店或不同信息系统中，如ERP、OA、CRM 、进销存管理软件系统等，这些系统中的数据呈几何倍数增长，给存储、检索和利用带来的挑战。';

  public customizeTitle = '燕麦云针对零售行业定制的解决方案';
  public customizeContent: Array<any> = [
    {
      imgUrl: './assets/img/trade/trade-customize-img1.svg',
      title: '开放接口对接零售系统',
      desc: '燕麦云开放的API接口，可支持对接内部OA/ERP/CRM等零售系统，便于第三方系统自直接调用云盘软件'
    },
    {
      imgUrl: './assets/img/trade/trade-customize-img2.svg',
      title: '海量数据快速搜索',
      desc: '通过OCR识别可以把票据上的文字内容识别出来，用户可根据订单号/订单日期/客户代码/客户名称/客户订单号等信息查找所需票据。'
    },
    {
      imgUrl: './assets/img/trade/trade-customize-img3.svg',
      title: '商业数据保密保管',
      desc: '加密信息碎片化存储，授权查看文件，保护企业的用户信息和商业机密不被泄露和恶意盗取'
    },
    {
      imgUrl: './assets/img/trade/trade-customize-img4.svg',
      title: '快速收集门店资料',
      desc: '由于零售行业的门店分散，对营收报表、促销活动的时效性要求较高，通过设置外链文件，一键收集所有门店的资料文件或一键派发活动信息，帮助企业及时掌握门店动态和市场情况'
    }
  ]

  ngOnInit(): void {
    this._jumpPage.goTop();
  }

}
