import { Component, OnInit } from '@angular/core';
import {JumpPageService} from 'src/app/_share/service/jump-page.service';
import { Meta, Title } from '@angular/platform-browser';

class MimicryContent {
  title: string;
  desc: Array<{
    detail: string;
  }>;
  src: string;
}

class MimicryContentDetail {
  title: string;
  desc: string;
  detail: Array<{
    title?: string;
    desc: string;
    src: string;
  }>
}

@Component({
  selector: 'app-mimicry',
  templateUrl: './mimicry.component.html',
  styleUrls: ['./mimicry.component.less']
})
export class MimicryComponent implements OnInit {

  constructor(
    private _jumpPage: JumpPageService,
    private meta: Meta,
    private titleService: Title
  ) {
    this.titleService.setTitle('拟态保险箱-燕麦云智能企业云盘');
    this.meta.addTag({ name: 'keywords', content: 'Mimic Vault, 拟态保险箱,燕麦云,企业云盘,企业网盘,OATOS,私有云存储' });
    this.meta.addTag({ name: 'description', content: 'Mimic Vault拟态保险箱，是基于拟态技术的云端数据保险箱，集成于燕麦云内，是全球首个商用的拟态网络存储产品；Mimic Vault拟态保险箱由燕麦云实验室与北京大学先进网络实验室联合打造。燕麦云智能企业云盘服务热线：4000861108。' });
  }

  // public mimicTitle = 'product.mimicry.title'; // 标题
  // public mimicDesc = 'product.mimicry.desc'; // 描述
  public mimicImgSrc = '/assets/img/mimic/mimic-bg.png'; // 背景图
  public mimicTitle = 'product.mimicry.mimicTitle';
  public mimicDesc = 'product.mimicry.mimicDesc';

  // 拟态内容
  public mimicryContent: Array<MimicryContent> = [
    {
      title: 'product.mimicry.content1-title',
      desc: [
        {
          detail: 'product.mimicry.content1-desc1'
        },
        {
          detail: 'product.mimicry.content1-desc2'
        },
        {
          detail: 'product.mimicry.content1-desc3'
        }
      ],
      src:'/assets/img/mimic/mimic-detail.png'
    },
    {
      title: 'product.mimicry.content2-title',
      desc: [
        {
          detail: 'product.mimicry.content2-desc1'
        },
        {
          detail: 'product.mimicry.content2-desc2'
        }
      ],
      src:'/assets/img/mimic/mimic-report.png'
    }
  ];

 // 拟态内容细节
  public mimicryContentDetail: Array<MimicryContentDetail> = [
    {
      title: 'product.mimicry.content3-title',
      desc: 'product.mimicry.content3-desc',
      detail: [
        {
          title:'product.mimicry.content3-desc1-title1',
          desc:'product.mimicry.content3-desc1-title1-dec',
          src:'/assets/img/mimic/mimic-safety.svg'
        },
        {
          title:'product.mimicry.content3-desc1-title2',
          desc:'product.mimicry.content3-desc1-title2-dec',
          src:'/assets/img/mimic/mimic-privaty.svg'
        },
        {
          title:'product.mimicry.content3-desc1-title3',
          desc:'product.mimicry.content3-desc1-title3-dec',
          src:'/assets/img/mimic/mimic-usage.svg'
        }
      ]
    },
    {
      title: 'product.mimicry.content4-title',
      desc: '',
      detail: [
        {
          desc:'product.mimicry.content4-desc1-2',
          src:'/assets/img/mimic/mimic-maneger.png'
        },
        {
          desc:'product.mimicry.content4-desc2-2',
          src:'/assets/img/mimic/mimic-advance-file.png'
        },
        {
          desc:'product.mimicry.content4-desc3-2',
          src:'/assets/img/mimic/mimic-private-file.png'
        }
      ]
    }
  ];

  ngOnInit(): void {
    this._jumpPage.goTop();
  }

}
