import { Component, OnInit } from '@angular/core';
import { JumpPageService } from 'src/app/_share/service/jump-page.service';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-custom',
  templateUrl: './custom.component.html',
  styleUrls: ['./custom.component.less']
})
export class CustomComponent implements OnInit {

  public customTitle: string = 'product.custom.customTitle';
  public customBtn: string = 'product.custom.customBtn';
  public customImgSrc: string = './assets/img/custom/custom-backgroud.png';
  public customLink: string = 'https://app.oatos.com/os/#/welcome/sign-up'; 

  public customContent: Array<any> = [
    {
      title: 'product.custom.customContent-title1',
      children: [
        {
          imgUrl: './assets/img/custom/custom-img-1.png',
          title: 'product.custom.customContent-subtitle1',
          desc: 'product.custom.customContent-subdesc1'
        },
        {
          imgUrl: './assets/img/custom/custom-img-2.png',
          title: 'product.custom.customContent-subtitle2',
          desc: 'product.custom.customContent-subdesc2'
        },
        {
          imgUrl: './assets/img/custom/custom-img-3.png',
          title: 'product.custom.customContent-subtitle3',
          desc: 'product.custom.customContent-subdesc3'
        },
        {
          imgUrl: './assets/img/custom/custom-img-4.png',
          title: 'product.custom.customContent-subtitle4',
          desc: 'product.custom.customContent-subdesc4'
        },
        {
          imgUrl: './assets/img/custom/custom-img-5.png',
          title: 'product.custom.customContent-subtitle5',
          desc: 'product.custom.customContent-subdesc5'
        }
      ]
    },
    {
      title: 'product.custom.customContent-title2',
      children: [
        {
          imgUrl: './assets/img/custom/custom-img-6.png',
          title: 'product.custom.customContent-subtitle6',
          desc: 'product.custom.customContent-subdesc6'
        },
        {
          imgUrl: './assets/img/custom/custom-img-5.png',
          title: 'product.custom.customContent-subtitle7',
          desc: 'product.custom.customContent-subdesc7'
        },
        {
          imgUrl: './assets/img/custom/custom-img-7.png',
          title: 'product.custom.customContent-subtitle8',
          desc: 'product.custom.customContent-subdesc8'
        },
        {
          imgUrl: './assets/img/custom/custom-img-8.png',
          title: 'product.custom.customContent-subtitle9',
          desc: 'product.custom.customContent-subdesc9'
        },
        {
          imgUrl: './assets/img/custom/custom-img-9.png',
          title: 'product.custom.customContent-subtitle10',
          desc: 'product.custom.customContent-subdesc10'
        },
        {
          imgUrl: './assets/img/custom/custom-img-10.png',
          title: 'product.custom.customContent-subtitle11',
          desc: 'product.custom.customContent-subdesc11'
        }
      ]
    }
  ]

  constructor(
    private _jumpPage: JumpPageService,
    private meta: Meta,
    private titleService: Title
  ) {
    this.titleService.setTitle('私有化定制-燕麦云智能企业云盘');
    this.meta.addTag({ name: 'keywords', content: '私有化定制,燕麦云,企业云盘,企业网盘,OATOS,私有云存储' });
    this.meta.addTag({ name: 'description', content: '燕麦云智能企业云盘提供私有化定制服务，企业可将燕麦云智能企业云盘私有化部署，并集合企业业务实际定制功能。了解燕麦云更多私有化定制内容可致电服务热线：4000861108。' });
  }

  ngOnInit(): void {
    this._jumpPage.goTop();
  }

}
