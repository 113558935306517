<div>
  <div>
    <app-header
      [title]="solutionTitle"
      [desc]="solutionDesc"
      [imgSrc]="solutionImgSrc"
    ></app-header>
  </div>

  <div class="product-solution-wrapper">
    <div class="product-solution">
      <div class="product-solution-content">
        <dl [id]="item.name" *ngFor="let item of solutionArr" [routerLink]="item.route" >
          <dt>
            <img [src]="item.src" [alt]="item.title">
            <span><div>{{ item.title }}</div></span>
            <strong></strong>
            <b>{{ item.dataDesc }}</b>
          </dt>
          <dd class="product-solution-subtitle">{{ item.subtitle }}</dd>
          <!-- <dd class="product-solution-desc">{{ item.desc }}</dd> -->
          <!-- <div class="product-solution-logos">
            <div class="product-solution-logos-image"
                [ngStyle]="{backgroundPositionX: item.logo1x,backgroundPositionY: item.logo1y}">
            </div>
            <div class="product-solution-logos-image"
                [ngStyle]="{ backgroundPositionX: item.logo2x,backgroundPositionY: item.logo2y}">
            </div>
            <div class="product-solution-logos-image"
                [ngStyle]="{backgroundPositionX: item.logo3x,backgroundPositionY: item.logo3y}">
            </div>
          </div> -->
        </dl>
      </div>
    </div>
  </div>

  <app-suspension></app-suspension>

  <app-footer></app-footer>

</div>