<div>
    <!-- 头部组件 -->
    <app-header
      [title]="title | translate"
      [desc]="desc | translate"
      [imgSrc]="imgSrc"
    ></app-header>
  
    <!-- 在线协同编辑 -->
    <div class="oat-edit-wrapper" *ngFor="let item of editContent; let index = index">
      <div class="oat-edit">
        <div class="oat-edit-item-wrapper" [id]="item.id">
          <div class="oat-edit-item-img">
            <img [src]="item.imgUrl">
          </div>
          <div class="oat-edit-item-text">
            <div class="oat-edit-item-text-title">
              {{item.title | translate}}
            </div>
            <ul class="oat-edit-item-text-desc1">
              <li *ngFor="let desc of item.desc">
                {{desc.title | translate}}
              </li>
            </ul>
            <ul class="oat-edit-item-text-desc2">
              <li *ngFor="let subContent of item.subContent">
                {{subContent.title | translate}}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  
  <app-suspension></app-suspension>

  <app-footer></app-footer>
</div>