import { Component, OnInit, HostListener } from '@angular/core';
import { SendFormStatuService } from'src/app/_share/service/send-form-statu.service';
import { UserAgentService } from 'src/app/_share/service/user-agent.service';

@Component({
  selector: 'app-header-nav',
  templateUrl: './header-nav.component.html',
  styleUrls: ['./header-nav.component.less']
})
export class HeaderNavComponent implements OnInit {

  constructor(
    private _sendFormStatus: SendFormStatuService,
    public userAgents: UserAgentService,
  ) { }

  public navArr: Array<any> = [
    {
      title: 'product.header-nav.title1',
      route: '/home'
    },
    {
      title: 'product.header-nav.title2',
      children: [
        {
          subTitle: 'product.header-nav.title2-subtitle1',
          route: '/safety'
        },
        {
          subTitle: 'product.header-nav.title2-subtitle2',
          route: '/collaboration'
        },
        {
          subTitle: 'product.header-nav.title2-subtitle3',
          route: '/content-controls'
        },
        {
          subTitle: 'product.header-nav.title2-subtitle4',
          route: '/customization'
        },
        {
          subTitle: 'product.header-nav.title2-subtitle5',
          route: '/functions/docs'
        },
      ]
    },
    {
      title: 'product.header-nav.title3',
      route: '/solutions'
    },
    {
      title: 'product.header-nav.title4',
      route: '/pricing'
    },
    {
      title: 'product.header-nav.title6',
      children: [
        {
          subTitle: 'product.header-nav.title6-subtitle1',
          linkUrl: 'https://www.oatos.com/help/'
        },
        {
          subTitle: 'product.header-nav.title6-subtitle2',
          linkUrl: 'https://www.oatos.com/courses/'
        },
        {
          subTitle: 'product.header-nav.title6-subtitle3',
          route: '/service'
        },

      ]
    },
    {
      title: 'product.header-nav.title5',
      route: '/download'
    },
    {
      title: 'product.header-nav.title7',
      route: '/aboutUs'
    }
  ]

  public darkHeader: boolean = false;

  // 是否显示表单
  public isShowForm: boolean = false;

  ngOnInit(): void {
    this.changePos();
  }

  @HostListener('window:scroll', ['$event']) 
  scrolling(event) {
    this.darkHeader = window.pageYOffset > 5;
  }

  ngAfterViewChecked() {
    window.addEventListener('scroll', this.changePos);
  }

  private changePos() {
    let obj = document.getElementById('product-header-nav');
    let scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
    if (scrollTop > 10) {
      obj && obj.classList.add('active');
    } else {
      obj && obj.classList.remove('active');
    }
  }

  // 显示表单
  public showForm() {
    this._sendFormStatus.formStatu.emit(this.isShowForm = true);
    document.querySelector('html').style.overflow = 'hidden';
  }

}
