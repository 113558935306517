<div>
  <div>
    <app-header
      [title]="contactUsTitle | translate "
      [desc]="contactUsDesc | translate "
      [imgSrc]="contactUsImgSrc"
    ></app-header>
  </div>

  <div class="product-contact-us-wrapper">
      <div class="product-contact-us">
          <div class="product-contact-us-top">
              <div class="product-contact-us-top-box" *ngFor="let list of contactUsList">
                  <div class="product-contact-us-top-box-img">
                      <img [src]='list.imgUrl'>
                  </div>
                  <div class="product-contact-us-top-box-title">
                      {{ list.title | translate }}
                  </div>
                  <div class="product-contact-us-top-box-desc">
                      {{ list.telephone | translate }}
                  </div>
              </div>
          </div>
          <div class="product-contact-us-middle" *ngFor="let detail of contactUsDetail">
              <div>
                  {{ detail.InternationalLine | translate }}
              </div>
              <div>
                  {{ detail.serviceHotline | translate }}
              </div>
              <div>
                  {{ detail.contactEmail | translate }}
              </div>
              <div>
                  {{ detail.contactAddress | translate }}
              </div>
              <div>
                  {{ detail.zipCode | translate }}
              </div>
          </div>
          <div class="product-contact-us-bottom" id="product-contact-us-bottom" [ngStyle]="{backgroundImage: isShowDefaultMap ? 'url(/assets/img/contact/contact-map.png)' : 'none'}"
          ></div>
      </div>
  </div>

  <app-suspension></app-suspension>

  <app-footer></app-footer>

</div>