<div>
  <div>
    <app-header
      [title]="SfindTitle"
      [desc]="SfindDesc"
      [imgSrc]="SfindImgSrc"
    ></app-header>
  </div>

  <div class="product-smart-find-wrapper">
    <div class="product-smart-find-section">
      <div class="product-smart-find-title">
        {{'product.smart-find.title2' | translate}}
      </div>
      <div class="product-smart-find-sub-title">
        {{'product.smart-find.desc2' | translate}}
      </div>
    </div>

    <div class="product-smart-find-section product-smart-find-compare">
      <div class="product-smart-find-title">
        {{'product.smart-find.title3' | translate}}
      </div>
      <div class="product-smart-find-sub-title">
        {{'product.smart-find.desc3' | translate}}
      </div>
      <div class="product-smart-find-pictures">
        <div class="product-smart-find-picture-section" *ngFor="let item of traditionalSearch">
          <img [src]="item.src">
          <div class="product-smart-find-picture-title">
            {{ item.title | translate}}
          </div>
          <div class="product-smart-find-picture-sub-title">
            {{ item.desc | translate}}
          </div>
        </div>
      </div>
    </div>

    <div class="product-smart-find-section product-smart-find-intro">
      <div class="product-smart-find-title">
        {{'product.smart-find.title4' | translate}}
      </div>

      <div class="product-smart-find-sub-title">
        {{'product.smart-find.desc4' | translate}}
      </div>

      <div class="product-smart-find-pictures">
        <div class="product-smart-find-picture-section" *ngFor="let item of functionIntroduction">
          <img [src]="item.src">
          <div class="product-smart-find-picture-title">
            {{ item.title | translate}}
          </div>
          <div class="product-smart-find-picture-sub-title">
            {{ item.desc | translate}}
          </div>
        </div>
      </div>
    </div>

    <div class="product-smart-find-section product-smart-find-usage">
      <div class="product-smart-find-title">
        {{'product.smart-find.title5' | translate}}
      </div>

      <div class="product-smart-find-sub-title">
        {{'product.smart-find.desc5' | translate}}
      </div>

      <div class="product-smart-find-pictures">
        <div class="product-smart-find-picture-section" *ngFor="let item of usageScenario">
          <img [src]="item.src">
          <div class="product-smart-find-picture-title">
            {{ item.title | translate}}
          </div>
          <div class="product-smart-find-picture-sub-title">
           {{ item.desc | translate}}
          </div>
        </div>
      </div>
    </div>
  </div>

  <app-suspension></app-suspension>

  <app-footer></app-footer>
</div>