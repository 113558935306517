import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UserAgentService {

  // 用户设备
  public userAgent: string;

  constructor() { }

  // 检测系统
  public checkSystem() {
    
    const userAgent = navigator.userAgent || navigator.vendor;
    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
      this.userAgent = 'windows-phone';
    } else if (/android/i.test(userAgent)) {
      this.userAgent = 'android';
    } else if (/iPad/i.test(userAgent)) {
      this.userAgent = 'iPad';
    } else if (/iPhone|iPod/i.test(userAgent)) {
      this.userAgent = 'iOS';
    } else if (/mac/i.test(userAgent)) {
      this.userAgent = 'Mac';
    } else {
      this.userAgent = 'PC';
    }
    console.log('当前设备：', this.userAgent);
  }
  
}
