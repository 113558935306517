<app-header-nav></app-header-nav>

<div class="product-header-wrapper">
  <div class="product-header">
    <div class="product-header-background-wrapper">
      <div 
        class="product-header-background-image" 
        [ngStyle]="{
          backgroundImage:'url(' + imgSrc + ')'
        }"
      ></div>
    </div>

    <div class="product-header-brief">
      <div 
        *ngIf="title" 
        class="product-header-title" 
        [innerHTML]="title"
      ></div>

      <div 
        *ngIf="desc" 
        class="product-header-desc"
        [innerHTML]="desc"
      ></div>

      <a 
        *ngIf="link" 
        [href]="link" 
        target="_blank"
        class="product-header-btn"
        [innerHTML]="btn"
      ></a>

      <a 
        *ngIf="route" 
        [routerLink]="route"
        class="product-header-btn"
        [innerHTML]="btn"
      ></a>
    </div>
  </div>
</div>