import { Component, OnInit } from '@angular/core';
import {JumpPageService} from 'src/app/_share/service/jump-page.service';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-docs',
  templateUrl: './docs.component.html',
  styleUrls: ['./docs.component.less']
})
export class DocsComponent implements OnInit {

  constructor(
    private _jumpPage: JumpPageService,
    private meta: Meta,
    private titleService: Title
  ) {
    this.titleService.setTitle('在线协同编辑-燕麦云智能企业云盘');
    this.meta.addTag({ name: 'keywords', content: '在线编辑,协同编辑,燕麦云,企业云盘,企业网盘,OATOS,私有云存储' });
    this.meta.addTag({ name: 'description', content: '燕麦云在线协同编辑高度兼容Office文档，支持多端在线编辑，多人实时协同编辑；燕麦云在线协同编辑功能还提供强大的协作能力，可标注或评论文档，助力企业办公效率飞跃。燕麦云智能企业云盘服务热线：4000861108。' });
  }

  public title = "product.edit.title"; // 标题
  public desc = "product.edit.desc"; // 描述
  public imgSrc = "../../assets/img/docs/edit-bg.png"; // 背景图

  public editContent: Array<any> = [
    {
      title: "product.edit.content1.title",
      id: 'multi',
      imgUrl: '../../assets/img/docs/edit1.png',
      subContent: [
        {
          title: 'product.edit.content1.subDesc1',
        },
        {
          title: 'product.edit.content1.subDesc2',
        },
        {
          title: 'product.edit.content1.subDesc3'
        },
        {
          title: 'product.edit.content1.subDesc4'
        },
        {
          title: 'product.edit.content1.subDesc5'
        },
        {
          title: 'product.edit.content1.subDesc6'
        },
        {
          title: 'product.edit.content1.subDesc7'
        }
      ]
    },
    {
      title: 'product.edit.content2.title',
      id: 'word',
      imgUrl: '../../assets/img/docs/edit2.png',
      desc: [
        {
          title: 'product.edit.content2.desc1',
        },
        {
          title: 'product.edit.content2.desc2',
        }
      ],
      subContent: [
        {
          title: 'product.edit.content2.subDesc1'
        },
        {
          title: 'product.edit.content2.subDesc2'
        },
        {
          title: 'product.edit.content2.subDesc3'
        }
      ]
    },
    {
      title: 'product.edit.content3.title',
      id: 'excel',
      imgUrl: '../../assets/img/docs/edit3.png',
      desc: [
        {
          title: 'product.edit.content3.desc1',
        },
        {
          title: 'product.edit.content3.desc2',
        }
      ],
      subContent: [
        {
          title: 'product.edit.content3.subDesc1'
        },
        {
          title: 'product.edit.content3.subDesc2'
        },
        {
          title: 'product.edit.content3.subDesc3'
        }
      ]
    },
    {
      title: 'product.edit.content4.title',
      id: 'ppt',
      imgUrl: '../../assets/img/docs/edit4.png',
      desc: [
        {
          title: 'product.edit.content4.desc1',
        },
        {
          title: 'product.edit.content4.desc2',
        }
      ],
      subContent: [
        {
          title: 'product.edit.content4.subDesc1'
        },
        {
          title: 'product.edit.content4.subDesc2'
        },
        {
          title: 'product.edit.content4.subDesc3'
        }
      ]
    }
  ]

  ngOnInit(): void {
    this._jumpPage.goTop();
  }

}
