import { Component, OnInit, Input, EventEmitter } from '@angular/core';
import { SendFormStatuService } from 'src/app/_share/service/send-form-statu.service';
import { OperationInterfaceService } from 'src/app/_share/service/operation-interface.service';
import { UserAgentService } from "src/app/_share/service/user-agent.service";
import { TranslateService } from '@ngx-translate/core';

class CountryCode {
  tabTitle: string;
  tabContent: Array<string>;
}

@Component({
  selector: 'app-cloud-form',
  templateUrl: './cloud-form.component.html',
  styleUrls: ['./cloud-form.component.less']
})
export class CloudFormComponent implements OnInit {

  constructor(
    private _sendFormStatus: SendFormStatuService, // 发送表单状态
    private _http: OperationInterfaceService, // 接口服务
    public _userAgent: UserAgentService,
    private _translate: TranslateService,
  ) { }

  // 表单
  @Input() public isShowForm: boolean;

  public submitBtn: string = 'product.cloud-from.b​​tn';
  public codeSrc: string = ''; // 图片验证码
  public uuid: string = ''; //uuid
  public isStartCountDown: boolean = false; // 手机验证码演示器
  public countryCode: string = '+86'; // 地区手机前缀
  public tabsActiveIndex: number = 0; // 默认显示热门
  public smsCaptchaText: string = 'product.cloud-from.get-code'; // 手机验证码文案
  public captchaCountDown: number = 120; // 手机验证码间隔120秒
  public timer: any = null; // 延时器

  // 表单内容标题
  public cloudFormContent = {
    entName: 'product.cloud-from.entName',
    industry: 'product.cloud-from.industry',
    size: 'product.cloud-from.size',
    contact: 'product.cloud-from.contact',
    mobile: 'product.cloud-from.mobile',
    picCaptcha: 'product.cloud-from.picCaptcha',
    smsCaptcha: 'product.cloud-from.smsCaptcha',
  };

  // 表单数据
  public ruleForm = {
    entName: '',
    industry: '',
    size:  0,
    contact: '',
    mobile: '',
    picCaptcha: '',
    smsCaptcha: '',
  };

  // 国家代号
  public countryCodeArr: Array<CountryCode> = [
    {
      tabTitle: 'product.cloud-from.countryCodeHot',
      tabContent: [
        'product.cloud-from.countryCodeHot-tabContent1',
        'product.cloud-from.countryCodeHot-tabContent2',
        'product.cloud-from.countryCodeHot-tabContent3',
        'product.cloud-from.countryCodeHot-tabContent4',
        'product.cloud-from.countryCodeHot-tabContent5',
        'product.cloud-from.countryCodeHot-tabContent6',
        'product.cloud-from.countryCodeHot-tabContent7',
        'product.cloud-from.countryCodeHot-tabContent8',
        'product.cloud-from.countryCodeHot-tabContent9',
        'product.cloud-from.countryCodeHot-tabContent10',
        'product.cloud-from.countryCodeHot-tabContent11',
        'product.cloud-from.countryCodeHot-tabContent12'
      ],
    },
    {
      tabTitle: 'product.cloud-from.countryCodeOceania',
      tabContent: [
        'product.cloud-from.countryCodeHot-tabContent1',
        'product.cloud-from.countryCodeHot-tabContent2'
      ],
    },
    {
      tabTitle: 'product.cloud-from.countryCodeAmerica',
      tabContent: [
        'product.cloud-from.countryCodeHot-tabContent3',
        'product.cloud-from.countryCodeHot-tabContent4',
        'product.cloud-from.countryCodeHot-tabContent13',
        'product.cloud-from.countryCodeHot-tabContent14'
      ],
    },
    {
      tabTitle: 'product.cloud-from.countryCodeEurope',
      tabContent: [
        'product.cloud-from.countryCodeHot-tabContent15',
        'product.cloud-from.countryCodeHot-tabContent16',
        'product.cloud-from.countryCodeHot-tabContent17',
        'product.cloud-from.countryCodeHot-tabContent18',
        'product.cloud-from.countryCodeHot-tabContent19',
        'product.cloud-from.countryCodeHot-tabContent20',
        'product.cloud-from.countryCodeHot-tabContent21',
        'product.cloud-from.countryCodeHot-tabContent22',
        'product.cloud-from.countryCodeHot-tabContent23',
        'product.cloud-from.countryCodeHot-tabContent24',
        'product.cloud-from.countryCodeHot-tabContent25',
        'product.cloud-from.countryCodeHot-tabContent26'
      ],
    },
    {
      tabTitle: 'product.cloud-from.countryCodeAsia',
      tabContent: [
        'product.cloud-from.countryCodeHot-tabContent27',
        'product.cloud-from.countryCodeHot-tabContent5',
        'product.cloud-from.countryCodeHot-tabContent6',
        'product.cloud-from.countryCodeHot-tabContent28',
        'product.cloud-from.countryCodeHot-tabContent29',
        'product.cloud-from.countryCodeHot-tabContent7',
        'product.cloud-from.countryCodeHot-tabContent8',
        'product.cloud-from.countryCodeHot-tabContent9',
        'product.cloud-from.countryCodeHot-tabContent30',
        'product.cloud-from.countryCodeHot-tabContent10',
        'product.cloud-from.countryCodeHot-tabContent11',
        'product.cloud-from.countryCodeHot-tabContent12',
        'product.cloud-from.countryCodeHot-tabContent31',
        'product.cloud-from.countryCodeHot-tabContent32',
        'product.cloud-from.countryCodeHot-tabContent33'
      ],
    }
  ];

  ngOnInit(): void {
    this._sendFormStatus.formStatu.subscribe((value: boolean) => {
      this.isShowForm = value;
      this.getUUid();
      this.countryCode = '+86';
    });
  }

  // 提交表单
  public submitForm() {
    let flag = true;

    // 判断有没有输入框为空的
    for (const key in this.ruleForm) {
      if (this.ruleForm.hasOwnProperty(key) && this.ruleForm[key] === '') {
        flag = false;
        break;
      }
    }
    if (flag) {
      this.postForm();
    } else {
      alert(this._translate.instant('product.cloud-from.inputNoEmpty'));
      return false;
    }
  }

  // 获取UUid
  public getUUid() {
    this._http.getUuid().subscribe({
      next: (response) => {
        this.uuid = response.uuid;

        // 获取图形验证码
        this.getPicCaptchaUrl(this.uuid)
      },
      error: (err) => {
        console.log(err);
      }
    })
  }

  // 获取图形验证码
  public getPicCaptchaUrl(uuid) {
    let params = {
        uuid: uuid
    };
    this.codeSrc = this._http.getPicCaptchaUrl(params);
  }

  // 提交内容的发送
  public postForm() {

    let params = {
      uuid: this.uuid,
      nameOrganization: this.ruleForm.entName,
      industryInvolved: this.ruleForm.industry,
      organizationScale: this.ruleForm.size,
      contacts: this.ruleForm.contact,
      contactNumber: this.ruleForm.mobile,
      verificationCode: this.ruleForm.picCaptcha,
      sendSmsCode: this.ruleForm.smsCaptcha,
      countryCode: this.countryCode,
    };

    this._http.privateCloudApplyFor(params).subscribe(response => {

      // 非法参数类型
      if (response.statusCode === 405) {
         alert(this._translate.instant('product.cloud-from.apply-error-alert'));
      }

      if (response.statusCode === 200) {
        alert(this._translate.instant('product.cloud-from.apply-cooperation-success'));
        this._clearForm();
        this.hideForm();
        this.getUUid();
      } else {

        // 图形验证码,手机验证码错误信息，手机验证码失效
        if (response.msg && response.statusCode !==428) {
          alert(response.msg);
        }

        // 图形验证码失效
        if (response.statusCode === 428) {
          alert(response.msg);
          this.getPicCaptchaUrl(this.uuid);
        }
      }
    }, err => {
      console.log(err);
    });
  }

  // 手机验证码间隔120秒的定时器
  private _captchaTimer() {
    if (this.captchaCountDown === 0) {
      this.smsCaptchaText = this._translate.instant('product.cloud-from.get-code');
      this.isStartCountDown = false;
      this.captchaCountDown = 120;
      return;
    } else {
      this.smsCaptchaText = `${this._translate.instant('product.cloud-from.resend')}(${this.captchaCountDown})`
      this.captchaCountDown--;
    }
    this.timer = setTimeout(() => {
      this._captchaTimer(); 
    }, 1000);
  }

  // 获取手机验证码
  public getPhoneCode() {
    if (this.captchaCountDown === 120) {

      let params = {
        contactNumber: this.ruleForm.mobile.toString(),
        countryCode: this.countryCode,
        uuid: this.uuid,
        verificationCode:  this.ruleForm.picCaptcha,
      };

      if (this.ruleForm.mobile === ''  || this.ruleForm.mobile === undefined ) {
        alert(this._translate.instant('product.cloud-from.mobile-valid-empty'));
        return
      }
 
      if (!this.ruleForm.picCaptcha) {
        alert(this._translate.instant('product.cloud-from.picCaptcha-empty'));
        return
      }

      this._http.getPhoneCode(params).subscribe({
        next: (response) => {
          if (response.statusCode === 200) {
            this.isStartCountDown = true;
            this._captchaTimer();
          } else {
            alert(response.msg);
          }
        },
        error: (err) => {
          alert(err.name);
        }
      });
    }
  }

  // 隐藏表单
  public hideForm() {
    this.isShowForm = false;
    this.captchaCountDown = 120;
    this.smsCaptchaText = this._translate.instant('product.cloud-from.get-code');
    this.isStartCountDown = false;
    clearTimeout(this.timer);
    this._clearForm();
    document.querySelector('html').style.overflow = 'auto';
  }

  // 改变地区手机编码
  public changeCountryCode(item) {
    let key = this._translate.instant(item);
    this.countryCode = key.substr(key.indexOf('+'));
  }

  // 统计数值
  public countNumber(type) {
    if (type == "up") {
      this.ruleForm.size = this.ruleForm.size + 1;
    } else if (type == "down"){
      if (this.ruleForm.size == 0){
        return
      } else {
        this.ruleForm.size = this.ruleForm.size -1;
      }
    }
  }

  // 清空表单数据
  private _clearForm() {
    // 清空表单
    for (const key in this.ruleForm) {
      if (this.ruleForm.hasOwnProperty(key)) {
        this.ruleForm[key] = '';
        if (key == 'size'){
          this.ruleForm.size = 0;
        }
      }
    }
  }

}
