import { Component, OnInit } from '@angular/core';
import { JumpPageService } from 'src/app/_share/service/jump-page.service';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-cooperation',
  templateUrl: './cooperation.component.html',
  styleUrls: ['./cooperation.component.less']
})
export class CooperationComponent implements OnInit {

  constructor(
    private _jumpPage: JumpPageService,
    private meta: Meta,
    private titleService: Title
  ) {
    this.titleService.setTitle('合作伙伴-燕麦云智能企业云盘');
    this.meta.addTag({ name: 'keywords', content: '合作伙伴,燕麦云,企业云盘,企业网盘,OATOS,私有云存储' });
    this.meta.addTag({ name: 'description', content: '燕麦云智能企业云盘秉持“开发进化”的产品发展理念，热忱欢迎社会各界与我们沟通合作，一起进化。燕麦云智能企业云盘服务热线：4000861108。' });
  }

  public cooperationTitle: any = 'product.cooperation.cooperationTitle';
  public cooperationImgSrc: any = '/assets/img/cooperation/cooperation-bg.png';

  public partner: Array<any> =[
    {
      id: 'dnspod',
      imgUrl: './assets/img/cooperation/cooperation-1.png',
      link: 'https://www.dnspod.cn/'
    },
    {
      id: 'onlyoffice',
      imgUrl: './assets/img/cooperation/cooperation-2.png',
      link: 'https://www.onlyoffice.com/'
    },
    {
      id: 'sendcloud',
      imgUrl: './assets/img/cooperation/cooperation-3.png',
      link: 'https://sendcloud.sohu.com/'
    },
    {
      id: 'vmware',
      imgUrl: './assets/img/cooperation/cooperation-4.png',
      link: 'https://www.vmware.com/'
    },
    {
      id: 'ali',
      imgUrl: './assets/img/cooperation/cooperation-5.png',
      link: 'https://www.aliyun.com/'
    },
    {
      id: 'dingding',
      imgUrl: './assets/img/cooperation/cooperation-6.png',
      link: 'https://www.dingtalk.com/'
    },
    {
      id: 'fanwei',
      imgUrl: './assets/img/cooperation/cooperation-7.png',
      link: 'https://www.weaver.com.cn/'
    },
    {
      id: 'qychat',
      imgUrl: './assets/img/cooperation/cooperation-8.png',
      link: 'https://work.weixin.qq.com/'
    },
    {
      id: 'kingdee',
      imgUrl: './assets/img/cooperation/cooperation-9.png',
      link: 'https://www.kingdee.com/'
    },
    {
      id: 'tencentcloud',
      imgUrl: './assets/img/cooperation/cooperation-10.png',
      link: 'https://cloud.tencent.com/'
    },
    {
      id: 'microsoft',
      imgUrl: './assets/img/cooperation/cooperation-11.png',
      link: 'https://www.microsoft.com/'
    },
    {
      id: 'yongyou',
      imgUrl: './assets/img/cooperation/cooperation-12.png',
      link: 'https://www.yonyou.com/'
    }
  ]

  ngOnInit(): void {
    // 置顶
    this._jumpPage.goTop();
  }

}
