<div>
  <div>
    <app-header
      [title]="contactUsTitle | translate"
      [desc]="contactUsDesc | translate"
      [imgSrc]="contactUsImgSrc"
    ></app-header>
  </div>

  <div class="product-file-dna-wrapper">
    <div class="product-file-dna-part1">
      <div class="product-file-dna-item">
        <div class="product-file-dna-item-title">{{ 'product.file-dna.item1-title' | translate }}</div>
        <div class="product-file-dna-item-desc" [innerHTML]=" 'product.file-dna.item1-desc' | translate"></div>
        <div class="product-file-dna-item-image-ai-wrapper">
          <dl class="product-file-dna-item-image-one">
            <dt><img src="/assets/img/fileDNA/filedna-part1-one-image1.png"/></dt>
            <dd>{{ 'product.file-dna.item1-subtitle1' | translate }}</dd>
          </dl>
          <dl class="product-file-dna-item-image-two">
            <dt><img src="/assets/img/fileDNA/filedna-part1-one-image2.png"/></dt>
            <dd>{{ 'product.file-dna.item1-subtitle2' | translate }}</dd>
          </dl>
        </div>
      </div>
      <div class="product-file-dna-item">
        <div class="product-file-dna-item-title" [innerHTML]=" 'product.file-dna.item2-title' | translate"></div>
        <div class="product-file-dna-item-desc" [innerHTML]=" 'product.file-dna.item2-desc' | translate"></div>
        <img class="product-image" src="/assets/img/fileDNA/filedna-part1-two-image.png"/>
        <div class="product-file-dna-item-tips" [innerHTML]=" 'product.file-dna.item2-tips' | translate"></div>
      </div>
      <div class="product-file-dna-item">
        <div class="product-file-dna-item-title" [innerHTML]=" 'product.file-dna.item3-title' | translate"></div>
        <div class="product-file-dna-item-desc" [innerHTML]=" 'product.file-dna.item3-desc' | translate"></div>
        <div class="product-file-dna-item-image-interference-wrapper">
          <dl *ngFor="let item of fileInterferenceArr">
            <dt><img [src]="item.src"/></dt>
            <dd>{{ item.title | translate }}</dd>
          </dl>
        </div>
      </div>
      <div class="product-file-dna-item">
        <div class="product-file-dna-item-title" [innerHTML]=" 'product.file-dna.item4-title' | translate"></div>
        <div class="product-file-dna-item-desc" [innerHTML]=" 'product.file-dna.item4-desc' | translate"></div>
        <img class="product-image" src="/assets/img/fileDNA/filedna-part1-four-image.png"/>
      </div>
    </div>
    <div class="product-file-dna-part2">
      <div class="product-file-dna-part2-one">
        <div class="product-file-dna-item-title" [innerHTML]=" 'product.file-dna.item5-title' | translate"></div>
        <div class="product-file-dna-item-desc">{{ 'product.file-dna.item5-desc' | translate }}</div>
        <div class="product-file-dna-part2-one-wrapper">
          <dl *ngFor=" let item of applicationScenariosArr">
            <img [src]="item.src"/>
            <dt [innerHTML]=" item.title | translate"></dt>
            <dd [innerHTML]=" item.desc | translate"></dd>
          </dl>
        </div>
      </div>
      <div class="product-file-dna-part2-two">
        <div class="product-file-dna-item-title" [innerHTML]=" 'product.file-dna.item6-title' | translate"></div>
        <div class="product-file-dna-part2-two-wrapper">
          <dl *ngFor=" let item of useWayArr">
            <img [src]="item.src"/>
            <dt [innerHTML]="item.title | translate"></dt>
            <dd [innerHTML]="item.desc | translate"></dd>
          </dl>
        </div>
      </div>
    </div>
  </div>

  <app-suspension></app-suspension>

  <app-footer></app-footer>
</div>