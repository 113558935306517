import { Component, OnInit } from '@angular/core';
import { JumpPageService } from 'src/app/_share/service/jump-page.service';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-office-collaboration',
  templateUrl: './office-collaboration.component.html',
  styleUrls: ['./office-collaboration.component.less']
})
export class OfficeCollaborationComponent implements OnInit {

  constructor(
    private _jumpPage: JumpPageService,
    private meta: Meta,
    private titleService: Title
  ) { 
    this.titleService.setTitle('办公协同-燕麦云智能企业云盘');
    this.meta.addTag({ name: 'keywords', content: '办公协同,燕麦云,企业云盘,企业网盘,OATOS,私有云存储' });
    this.meta.addTag({ name: 'description', content: '燕麦云智能企业云盘基于人工智能技术打造了文件共享协同、移动办公、在线编辑的高效的办公协同方案，满足政企数据资产的安全存储、管控、流转与应用中的各类办公协同需求。燕麦云智能企业云盘提供在线使用的企业云盘SaaS服务和私有化部署版本。燕麦云服务热线：4000861108。' });
   }

  public officeTitle: string = 'product.office-collaboration.officeTitle';
  public officeBtn: string = 'product.office-collaboration.officeBtn';
  public officeImgSrc: string = './assets/img/office/office-background.png';
  public officeLink: string = 'https://app.oatos.com/os/#/welcome/sign-up'; //链接地址

  public officeContent1: Array<any> = [
    {
      imgUrl: './assets/img/office/office-content1-img3.png',
      title: 'product.office-collaboration.officeContent1-title1',
      desc: 'product.office-collaboration.officeContent1-desc1',
      btn: 'product.office-collaboration.officeContent1-btn',
      route: '/functions/smartfind'
    },
    {
      imgUrl: './assets/img/office/office-content1-img1.png',
      title: 'product.office-collaboration.officeContent1-title2',
      btn: 'product.office-collaboration.officeContent1-btn',
      desc: 'product.office-collaboration.officeContent1-desc2',
      route: '/functions/docs'
    },
    {
      imgUrl: './assets/img/office/office-content1-img2.png',
      title: 'product.office-collaboration.officeContent1-title3',
      desc: 'product.office-collaboration.officeContent1-desc3',
      btn: 'product.office-collaboration.officeContent1-btn',
      route: '/functions/sversion'
    },
  ]

  public officeContent2: Array<any> = [
    {
      title: 'product.office-collaboration.officeContent2-title1',
      children: [
        {
          imgUrl: './assets/img/office/office-content2-1.png',
          title: 'product.office-collaboration.officeContent2-subtitle1',
          desc: 'product.office-collaboration.officeContent2-subdesc1'
        },
        {
          imgUrl: './assets/img/office/office-content2-2.png',
          title: 'product.office-collaboration.officeContent2-subtitle2',
          desc: 'product.office-collaboration.officeContent2-subdesc2'
        },
        {
          imgUrl: './assets/img/office/office-content2-3.png',
          title: 'product.office-collaboration.officeContent2-subtitle3',
          desc: 'product.office-collaboration.officeContent2-subdesc3'
        },
        {
          imgUrl: './assets/img/office/office-content2-4.png',
          title: 'product.office-collaboration.officeContent2-subtitle4',
          desc: 'product.office-collaboration.officeContent2-subdesc4'
        },
        {
          imgUrl: './assets/img/office/office-content2-5.svg',
          title: 'product.office-collaboration.officeContent2-subtitle5',
          desc: 'product.office-collaboration.officeContent2-subdesc5'
        }
      ]
    },
    {
      title: 'product.office-collaboration.officeContent2-title2',
      children: [
        {
          imgUrl: './assets/img/office/office-content2-5.png',
          title: 'product.office-collaboration.officeContent2-subtitle6',
          desc: 'product.office-collaboration.officeContent2-subdesc6'
        },
        {
          imgUrl: './assets/img/office/office-content2-6.png',
          title: 'product.office-collaboration.officeContent2-subtitle7',
          desc: 'product.office-collaboration.officeContent2-subdesc7'
        },
        {
          imgUrl: './assets/img/office/office-content2-7.png',
          title: 'product.office-collaboration.officeContent2-subtitle8',
          desc: 'product.office-collaboration.officeContent2-subdesc8'
        },
        {
          imgUrl: './assets/img/office/office-content2-8.png',
          title: 'product.office-collaboration.officeContent2-subtitle9',
          desc: 'product.office-collaboration.officeContent2-subdesc9'
        },
        {
          imgUrl: './assets/img/office/office-content2-9.png',
          title: 'product.office-collaboration.officeContent2-subtitle10',
          desc: 'product.office-collaboration.officeContent2-subdesc10'
        },
        {
          imgUrl: './assets/img/office/office-content2-9.png',
          title: 'product.office-collaboration.officeContent2-subtitle11',
          desc: 'product.office-collaboration.officeContent2-subdesc11'
        }
      ]
    },
    {
      title: 'product.office-collaboration.officeContent2-title3',
      children: [
        {
          imgUrl: './assets/img/office/office-content2-10.png',
          title: 'product.office-collaboration.officeContent2-subtitle12',
          desc: 'product.office-collaboration.officeContent2-subdesc12'
        },
        {
          imgUrl: './assets/img/office/office-content2-11.png',
          title: 'product.office-collaboration.officeContent2-subtitle13',
          desc: 'product.office-collaboration.officeContent2-subdesc13'
        },
        {
          imgUrl: './assets/img/office/office-content2-12.png',
          title: 'product.office-collaboration.officeContent2-subtitle14',
          desc: 'product.office-collaboration.officeContent2-subdesc14'
        }
      ]
    }
  ]

  ngOnInit(): void {
    this._jumpPage.goTop();
  }

}
