import { Component, OnInit } from '@angular/core';
import { JumpPageService } from 'src/app/_share/service/jump-page.service';

@Component({
  selector: 'app-medical',
  templateUrl: './medical.component.html',
  styleUrls: ['./medical.component.less']
})
export class MedicalComponent implements OnInit {

  constructor(
    private _jumpPage: JumpPageService,
  ) { }

  public medicalTitle = 'product.medical.medicalTitle'; // 标题
  public medicalDesc = 'product.medical.medicalDesc'; //描述
  public medicalBtn = 'product.medical.medicalBtn'; 
  public medicalImgSrc = './assets/img/medical/medical-background.png'; //背景图
  public medicalLink = 'https://app.oatos.com/os/#/welcome/sign-up'; //链接地址

  public caseContent: Array<any> = [
    {
      imgUrl: './assets/img/medical/medical-case-img1.png'
    },
    {
      imgUrl: './assets/img/medical/medical-case-img2.png'
    },
    {
      imgUrl: './assets/img/medical/medical-case-img3.png'
    }
  ]

  public featureTitle = 'product.medical.featureTitle';
  public featureDesc = 'product.medical.featureDesc';

  public customizeTitle = 'product.medical.customizeTitle';
  public customizeContent: Array<any> = [
    {
      imgUrl: './assets/img/medical/medical-customize-img1.svg',
      title: 'product.medical.customizeContent-title1',
      desc: 'product.medical.customizeContent-desc1'
    },
    {
      imgUrl: './assets/img/medical/medical-customize-img2.svg',
      title: 'product.medical.customizeContent-title2',
      desc: 'product.medical.customizeContent-desc2'
    },
    {
      imgUrl: './assets/img/medical/medical-customize-img3.svg',
      title: 'product.medical.customizeContent-title3',
      desc: 'product.medical.customizeContent-desc3'
    },
    {
      imgUrl: './assets/img/medical/medical-customize-img4.svg',
      title: 'product.medical.customizeContent-title4',
      desc: 'product.medical.customizeContent-desc4'
    }
  ]

  ngOnInit(): void {
    this._jumpPage.goTop();
  }

}
