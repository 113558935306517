<div>
    <div>
        <app-header
        [title]="financeTitle | translate"
        [desc]="financeDesc | translate"
        [btn]="financeBtn | translate"
        [imgSrc]="financeImgSrc"
        [link]="financeLink"
        ></app-header>
    </div>

    <div class="solution-content-all">
        <div class="solution-case">
            <div class="solution-content-wrapper">
                <div class="solution-case-title">
                    {{'product.finance.case-title' | translate}}
                </div>

                <div class="solution-case-content">
                    
                    <div class="solution-case-content-item" *ngFor="let case of caseContent">
                        <img [src]="case.imgUrl">
                    </div>

                </div>
            </div>

        </div>

        <div class="solution-feature">
            <div class="solution-content-wrapper solution-feature-main">
                <div class="solution-feature-title">
                    {{featureTitle | translate}}
                </div>

                <div class="solution-feature-content">
                    {{featureDesc | translate}}
                </div>
            </div>
        </div>

        <div class="solution-content-wrapper solution-customize">
            <div class="solution-customize-title">
                {{customizeTitle | translate}}
            </div>

            <div class="solution-customize-content">
                <div class="solution-customize-item" *ngFor="let customize of customizeContent">
                    
                    <div class="customize-item-img">
                        <img [src]="customize.imgUrl">
                    </div>

                    <div class="customize-item-content">
                        <div class="solution-customize-item-title">
                            {{customize.title | translate}}
                        </div>
                        <div class="solution-customize-item-desc">
                            {{customize.desc | translate}}
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>

    <app-suspension></app-suspension>

    <app-footer></app-footer>
</div>