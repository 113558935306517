import { Component, OnInit } from '@angular/core';
import {JumpPageService} from 'src/app/_share/service/jump-page.service';

class Tab {
  title: string;
  anchor: string;
}

class EnterpriseMission {
  icon: string;
  subtitle: string;
  desc: string;
}

class ValueProposition {
  icon: string;
  subtitle: string;
  desc: string;
}

@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.less']
})
export class AboutUsComponent implements OnInit {

  constructor(
    private _jumpPage: JumpPageService,
  ) {}

  public contactUsTitle = 'product.company-intro.title'; // 标题
  public contactUsDesc = 'product.company-intro.desc'; // 描述
  public contactUsImgSrc = '/assets/img/aboutUs/company-intro-bg.png'; //背景图
  public offsetTop: number = 0; // 顶部

  // public offsetIntroTop: number = 0; //公司介绍
  // public offsetFounderTop: number = 0; // 创始人
  // public offsetDevelopTop: number = 0; // 发展历程
  // public offsetHonorTop: number = 0; // 荣誉资质

  public searchBarFixed: boolean = false;
  public defaultAnchor: any = null;

  public tabArr: Array<Tab> = [
    {
      title: 'product.company-intro.tab1',
      anchor: '#company-intro',
    },
    {
      title: 'product.company-intro.tab2',
      anchor: '#company-founder',
    },
    {
      title: 'product.company-intro.tab3',
      anchor: '#company-develop',
    },
    {
      title: 'product.company-intro.tab4',
      anchor: '#company-honor',
    },
  ];

  public enterpriseMissionArr: Array<EnterpriseMission> = [
    {
      icon: '#icondnacopy',
      subtitle: 'product.company-intro.content2-subtitle1',
      desc: 'product.company-intro.content2-desc1',
    },
    {
      icon: '#iconhexin',
      subtitle: 'product.company-intro.content2-subtitle2',
      desc: 'product.company-intro.content2-desc2',
    },
    {
      icon: '#iconjiazhi',
      subtitle: 'product.company-intro.content2-subtitle3',
      desc: 'product.company-intro.content2-desc3',
    },
  ];

  public valuePropositionArr: Array<ValueProposition> = [
    {
      icon: '#iconanquan',
      subtitle: 'product.company-intro.content3-subtitle1',
      desc: 'product.company-intro.content3-desc1',
    },
    {
      icon: '#icontixicopy',
      subtitle: 'product.company-intro.content3-subtitle2',
      desc: 'product.company-intro.content3-desc2',
    },
    {
      icon: '#iconbeijiliancopy',
      subtitle: 'product.company-intro.content3-subtitle3',
      desc: 'product.company-intro.content3-desc3',
    },
  ];

  public introImgUrl: string = '../../assets/img/aboutUs/header-nav-dark.svg';
  public introDesc: string = 'product.company-intro.desc2';

  public num1: number = 0;
  public num2: number = 0;
  public num3: number = 0;

  public companyContent1: Array<any> = [
    {
      title: 'product.company-intro.content1-title',
      id: 'companyIntro',
      imgUrl: '../../assets/img/aboutUs/copany-content1-1.png',
      children: [
        {
          title: 'product.company-intro.content1-subtitle1',
          desc: 'product.company-intro.content1-desc1',
          btn: '了解更多 >>',
          link: 'https://www.qycloud.com'
        }
      ]
    },
    // {
    //   title: 'product.company-intro.content1-title2',
    //   id: 'companyMission',
    //   imgUrl: '../../assets/img/aboutUs/copany-content1-2.png',
    //   children: [
    //     {
    //       title: 'product.company-intro.content1-subtitle2',
    //       desc: 'product.company-intro.content1-desc2'
    //     }
    //   ]
    // }
  ]

  // public companyContent2: Array<any> = [
  //   {
  //     title: 'product.company-intro.content2-title',
  //     id: 'companyCulture',
  //     children: [
  //       {
  //         imgUrl: '../../assets/img/aboutUs/company-content2-1.png',
  //         title: 'product.company-intro.content2-subtitle1',
  //         desc: 'product.company-intro.content2-desc1'
  //       },
  //       {
  //         imgUrl: '../../assets/img/aboutUs/company-content2-2.png',
  //         title: 'product.company-intro.content2-subtitle2',
  //         desc: 'product.company-intro.content2-desc2'
  //       },
  //       {
  //         imgUrl: '../../assets/img/aboutUs/company-content2-3.png',
  //         title: 'product.company-intro.content2-subtitle3',
  //         desc: 'product.company-intro.content2-desc3'
  //       }
  //     ]
  //   }
  // ]

  public companyContent3: Array<any> = [
    {
      title: 'product.company-intro.content4-title',
      id: 'companyFounder',
      desc1: 'product.company-intro.content4-desc1',
      desc2: 'product.company-intro.content4-desc2',
      imgUrl: '../../assets/img/aboutUs/company-content3.png'
    }
   ]

   public companyContent4: Array<any> = [
     {
       title: 'product.company-intro.content5-subtitle8',
       ctime: 'product.company-intro.content5-ctime8',
       imgUrl: '../../assets/img/aboutUs/company-line-dot-right.png',
       desc: 'product.company-intro.content5-desc8'
     },
     {
       title: 'product.company-intro.content5-subtitle7',
       ctime: 'product.company-intro.content5-ctime7',
       imgUrl: '../../assets/img/aboutUs/company-line-dot-left.png',
       desc: 'product.company-intro.content5-desc7'
     },
     {
       title: 'product.company-intro.content5-subtitle6',
       ctime: 'product.company-intro.content5-ctime6',
       imgUrl: '../../assets/img/aboutUs/company-line-dot-right.png',
       desc: 'product.company-intro.content5-desc6'
     },
     {
       title: 'product.company-intro.content5-subtitle5',
       ctime: 'product.company-intro.content5-ctime5',
       imgUrl: '../../assets/img/aboutUs/company-line-dot-left.png',
       desc: 'product.company-intro.content5-desc5'
     },
     {
       title: 'product.company-intro.content5-subtitle4',
       ctime: 'product.company-intro.content5-ctime4',
       imgUrl: '../../assets/img/aboutUs/company-line-dot-right.png',
       desc: 'product.company-intro.content5-desc4'
     },
     {
       title: 'product.company-intro.content5-subtitle3',
       ctime: 'product.company-intro.content5-ctime3',
       imgUrl: '../../assets/img/aboutUs/company-line-dot-left.png',
       desc: 'product.company-intro.content5-desc3'
     },
     {
       title: 'product.company-intro.content5-subtitle2',
       ctime: 'product.company-intro.content5-ctime2',
       imgUrl: '../../assets/img/aboutUs/company-line-dot-right.png',
       desc: 'product.company-intro.content5-desc2'
     },
     {
       title: 'product.company-intro.content5-subtitle1',
       ctime: 'product.company-intro.content5-ctime1',
       imgUrl: '../../assets/img/aboutUs/company-line-dot-left.png',
       desc: 'product.company-intro.content5-desc1'
     }
   ]

  ngOnInit(): void {

    this.jumpToAnchor();
    // setTimeout( ()=> {
    //   this.offsetIntroTop = document.getElementById('company-intro').offsetTop;
    //   this.offsetFounderTop = document.getElementById('company-founder').offsetTop;
    //   this.offsetDevelopTop = document.getElementById('company-develop').offsetTop;
    //   this.offsetHonorTop = document.getElementById('company-honor').offsetTop;
    // }, 100)
  }

  ngAfterViewInit(): void {
    let ele = document.getElementById('statistics');

    if(this.isElementInViewport(ele)) {
      this._loadBar1();
      this._loadBar2();
      this._loadBar3();
    } else {
      this.num1 = 0;
      this.num2 = 0;
      this.num3 = 0;
    }

    window.addEventListener('scroll', () => {
      if (this.isElementInViewport(ele)) {
        this._loadBar1();
        this._loadBar2();
        this._loadBar3();
      } else {
        this.num1 = 0;
        this.num2 = 0;
        this.num3 = 0;
      }
    });
  }

  //获取元素是否在可视区域
  public isElementInViewport(el) {
    var rect = el.getBoundingClientRect();
    return (
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
      rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
  }

  private _loadBar1() {
    let num = 8 / 100 * 10;
    let timer1 = setInterval(() =>{
      this.num1 = Number((this.num1 + num).toFixed(0));
      if(this.num1 >= 8) {
        this.num1 = 8;
        clearInterval(timer1)
      }
    }, 400)
  }

  private _loadBar2() {
    let num = 120000 / 3000 * 10;
    let timer1 = setInterval(() =>{
      this.num2 = Number((this.num2 + num).toFixed(0));
      if(this.num2 >= 120000) {
        this.num2 = 120000;
        clearInterval(timer1)
      }
    },10)
  }

  private _loadBar3() {
    let num = 10 / 100 * 10;
    let timer1 = setInterval(() =>{
      this.num3 = Number((this.num3 + num).toFixed(0));
      if(this.num3 >= 10) {
        this.num3 = 10;
        clearInterval(timer1)
      }
    }, 400)
  }

  // ngAfterContentChecked () {
  //   window.addEventListener('scroll', this.handleScroll.call(this));
  // }
  
  // 跳转到锚
  public jumpToAnchor(anchor?) {
    if (anchor) {
      this._jumpPage.goAnchor(anchor)
    } else {
      this._jumpPage.goTop();
    }
  }

  // 处理滚动
  // public handleScroll () {
  //   let scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
  //   scrollTop > this.offsetTop ? this.searchBarFixed = true : this.searchBarFixed = false;
  //   if (scrollTop >= this.offsetHonorTop - 80) {
  //     this.defaultAnchor = '#company-honor';
  //   } else if (scrollTop >= this.offsetDevelopTop - 80) {
  //     this.defaultAnchor = '#company-develop';
  //   } else if (scrollTop >= this.offsetFounderTop - 80) {
  //     this.defaultAnchor = '#company-founder';
  //   } else if (scrollTop >= this.offsetIntroTop - 80) {
  //     this.defaultAnchor = '#company-intro';
  //   } else {
  //     this.defaultAnchor = null;
  //   }
  // };


}
