import { Component, OnInit } from '@angular/core';
import {JumpPageService} from 'src/app/_share/service/jump-page.service';
import { Meta, Title } from '@angular/platform-browser';

class FunctionIntroduction {
  title: string;
  desc: Array<{
    title: string;
    desc: string;
    src: string;
  }>
}

class VersionTable {
  title: string;
  desc: Array<{
    title: string;
    detail: Array<{
      name: string;
    }>
  }>
}

@Component({
  selector: 'app-smart-version',
  templateUrl: './smart-version.component.html',
  styleUrls: ['./smart-version.component.less']
})
export class SmartVersionComponent implements OnInit {

  constructor(
    private _jumpPage: JumpPageService,
    private meta: Meta,
    private titleService: Title
  ) {
    this.titleService.setTitle('智能版本管理-燕麦云智能企业云盘');
    this.meta.addTag({ name: 'keywords', content: '智能版本管理,Sversion,燕麦云,企业云盘,企业网盘,OATOS,私有云存储' });
    this.meta.addTag({ name: 'description', content: '燕麦云Sversion智能版本管理基于智能行为预测技术，精确生成文件版本，释放更多存储空间。燕麦云智能版本管理Sversion帮助政企在节约IT资源，成本的同时，提升文件的管理与审计效率。燕麦云智能企业云盘服务热线：4000861108。' });
  }

  public sversionTitle = 'Sversion™智能版本管理'; //标题
  public sversionTDesc = '基于智能行为预测技术，精确生成文件版本，释放更多存储空间，帮助政企在节约IT资源，成本的同时，提升文件的管理与审计效率。'; // 描述
  public sversionTImgSrc = '/assets/img/Sversion/Sversion-bg.png'; //背景图

  // 功能介绍内容
  public functionIntroduction: Array<FunctionIntroduction> = [
    {
      title:'product.smart-version.content-title',
      desc: [
        {
          title: 'product.smart-version.content1-desc1-1',
          desc: 'product.smart-version.content1-desc1-2',
          src: '/assets/img/Sversion/Sversion-img1.png',
        },
        {
          title: 'product.smart-version.content1-desc2-1',
          desc: 'product.smart-version.content1-desc2-2',
          src: '/assets/img/Sversion/Sversion-img2.png',
        },
        {
          title: 'product.smart-version.content1-desc3-1',
          desc: 'product.smart-version.content1-desc3-2',
          src: '/assets/img/Sversion/Sversion-img3.png',
        },
        {
          title: 'product.smart-version.content1-desc4-1',
          desc: 'product.smart-version.content1-desc4-2',
          src: '/assets/img/Sversion/Sversion-img4.png',
        }
      ]
    }
  ];

  // 版本表格内容
  public versionTable: Array<VersionTable> = [
    {
      title: 'product.smart-version.table-title',
      desc: [
        {
          title: 'product.smart-version.table1-title',
          detail: [
            {
              name:'product.smart-version.table1-desc1'
            },
            {
              name:'product.smart-version.table1-desc2'
            },
            {
              name:'product.smart-version.table1-desc3'
            },
            {
              name:'product.smart-version.table1-desc4'
            }
          ]
        },
        {
          title: 'product.smart-version.table2-title',
          detail: [
            {
              name:'product.smart-version.table2-desc1'
            },
            {
              name:'product.smart-version.table2-desc2'
            },
            {
              name:'product.smart-version.table2-desc3'
            },
            {
              name:'product.smart-version.table2-desc4'
            }
          ]
        },
        {
          title: 'product.smart-version.table3-title',
          detail: [
            {
              name:'product.smart-version.table3-desc1'
            },
            {
              name:'product.smart-version.table3-desc2'
            },
            {
              name:'product.smart-version.table3-desc3'
            },
            {
              name:'product.smart-version.table3-desc4'
            }
          ]
        }
      ]
    }
  ];

  ngOnInit(): void {
    this._jumpPage.goTop();
  }

}
