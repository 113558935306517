<div >
  <div>
    <app-header
      [title]="safetyTitle"
      [btn]="safetyBtn"
      [imgSrc]="safetyImgSrc"
      [link]="safetyLink"
    ></app-header>
  </div>

  <div class="product-safety-content-all">
    <div class="product-safety-content">
      <div class="product-safety-content-wrapper">
        <div class="product-safety-content-title">
          智能安全
        </div>
        <div class="product-safety-content1-main">
          <div class="product-safety-content1-item" *ngFor="let content1 of safetyContent1">
            <img [src]="content1.imgUrl">
            <div class="product-safety-content1-item-title">
              {{content1.title}}
            </div>
            <div class="product-safety-content1-item-desc">
              {{content1.desc}}
              <br>
              <a 
                *ngIf="content1.btn"
                [routerLink]="content1.link"
              >
                {{content1.btn}}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="product-safety-content product-safety-content2">
      <div class="product-safety-content-wrapper">
        <div class="product-safety-content-title">
          安全特性
        </div>
        <div class="product-safety-content2-main">
          <div class="product-safety-content2-main-content" *ngFor="let content2 of safetyContent2" >
            <div class="product-safety-content2-title">
              {{content2.title}}
            </div>
            <div class="product-safety-content2-desc">
              <div class="product-safety-content2-item" *ngFor="let item of content2.children" >
                <img [src]="item.imgUrl">
                <div class="product-safety-content2-item-title">
                  {{item.title}}
                </div>
                <div class="product-safety-content2-item-desc">
                  {{item.desc}}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <app-suspension></app-suspension>

  <app-footer></app-footer>

</div>