import { Component, OnInit } from '@angular/core';
import { JumpPageService } from 'src/app/_share/service/jump-page.service';

@Component({
  selector: 'app-manufacture',
  templateUrl: './manufacture.component.html',
  styleUrls: ['./manufacture.component.less']
})
export class ManufactureComponent implements OnInit {

  constructor(
    private _jumpPage: JumpPageService,
  ) { }

  public manufactureTitle = 'product.manufacture.manufactureTitle'; // 标题
  public manufactureDesc = 'product.manufacture.manufactureDesc'; //描述
  public manufactureBtn = 'product.manufacture.manufactureBtn'; 
  public manufactureImgSrc = './assets/img/manufacture/manufacture-background.png'; //背景图
  public manufactureLink = 'https://app.oatos.com/os/#/welcome/sign-up'; //链接地址

  public caseContent: Array<any> = [
    {
      imgUrl: './assets/img/manufacture/manufacture-case-img1.png'
    },
    {
      imgUrl: './assets/img/manufacture/manufacture-case-img2.png'
    },
    {
      imgUrl: './assets/img/manufacture/manufacture-case-img3.png'
    }
  ]

  public featureTitle = 'product.manufacture.featureTitle';
  public featureDesc = 'product.manufacture.featureDesc';

  public customizeTitle = 'product.manufacture.customizeTitle';
  public customizeContent: Array<any> = [
    {
      imgUrl: './assets/img/manufacture/manufacture-customize-img1.svg',
      title: 'product.manufacture.customizeContent-title1',
      desc: 'product.manufacture.customizeContent-desc1'
    },
    {
      imgUrl: './assets/img/manufacture/manufacture-customize-img2.svg',
      title: 'product.manufacture.customizeContent-title2',
      desc: 'product.manufacture.customizeContent-desc2'
    },
    {
      imgUrl: './assets/img/manufacture/manufacture-customize-img3.svg',
      title: 'product.manufacture.customizeContent-title3',
      desc: 'product.manufacture.customizeContent-desc3'
    },
    {
      imgUrl: './assets/img/manufacture/manufacture-customize-img4.svg',
      title: 'product.manufacture.customizeContent-title4',
      desc: 'product.manufacture.customizeContent-desc4'
    }
  ]

  ngOnInit(): void {
    this._jumpPage.goTop();
  }

}
