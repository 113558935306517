import { Component, OnInit } from '@angular/core';
import { SendFormStatuService } from 'src/app/_share/service/send-form-statu.service';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';
import { JumpPageService } from 'src/app/_share/service/jump-page.service';

class Lang {
  signal: string;
  name: string
}

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.less']
})
export class FooterComponent implements OnInit {

  constructor(
    private _sendFormStatus: SendFormStatuService,
    private _translate: TranslateService,
    private _cookeis: CookieService,
    private _jumpPage: JumpPageService,
  ) { }

  public productItem: Array<any> = [
    {
      title: '公司',
      children: [
        '何洋开讲',
        '新闻中心',
        '公司介绍',
        '服务条款',
        '隐私政策',
      ]
    },
    {
      title: '产品',
      children: [
        '燕麦云',
        '云盘私有部署',
        '行业解决方案',
        '燕麦云实验室',
        '燕麦云教育版',
      ],
    },
    {
      title: '支持',
      children: [
        '渠道合作',
        '燕麦课堂',
        '帮助中心',
        '行业资讯',
        '产品动态',
      ],
    }
  ]

  // 是否显示表单
  public isShowForm: boolean = false;

  // 目前语言
  public currentLang: string;

  public defaultImage: string = './assets/img/home/zip.png'


  // 语言
  public lang: Array<Lang> = [
     {
       signal: 'zh-CHS',
       name: '简体中文'
     },
     {
       signal: 'zh-CHT',
       name: '繁體中文'
     }
   ];

  ngOnInit(): void {
  }

  public useLanguage(language: any) {
    this.currentLang = language.signal;
    this._translate.use(language.signal);
    this._cookeis.set('oatosLang', language.signal);
    this._jumpPage.goTop();
  }

  public showForm() {
    this._sendFormStatus.formStatu.emit(this.isShowForm = true);
    document.querySelector('html').style.overflow = 'hidden';
  }

}
