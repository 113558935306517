<div class="product-home-wrapper" id="home">
  <div class="product-home-header">
    <app-header-nav></app-header-nav>

    <div class="product-home-header-item">
      <div class="product-home-header-title">
        {{ "product.home.title" | translate
        }}<span class="product-home-header-title-icon">{{
          "product.home.title0" | translate
        }}</span
        >{{ "product.home.title2" | translate }}
      </div>

      <div class="product-home-header-desc">
        <!-- 安全高效的企业级内容智能协作 -->
        {{ "product.home.desc" | translate }}
      </div>

      <div class="product-home-header-video">
        <iframe
          frameborder="0"
          scrolling="no"
          src="https://www.oatos.com/oatvideo/index.html"
        ></iframe>
      </div>
    </div>

    <div class="product-home-content">
      <div class="product-home-content1">
        <div class="product-home-content1-wrapper">
          <div
            class="product-home-content1-item"
            [ngClass]="{
              showSlide: click_account == 0,
              hideSlide: click_account != 0
            }"
          >
            <div class="product-home-content1-item-main">
              <a
                class="product-home-content1-item1"
                *ngFor="let product of productData"
                target="_blank"
                [href]="product.url"
              >
                <div
                  class="img"
                  [ngStyle]="{
                    backgroundImage: 'url(/assets/img/home/product-news.png)'
                  }"
                ></div>
                <div class="desc">
                  {{ product.title | translate }}
                </div>
              </a>

              <a *ngFor="let content0 of homeContent0" target="_blank">
                <div
                  class="img"
                  [ngStyle]="{
                    backgroundImage: 'url(' + content0.imgUrl + ')'
                  }"
                ></div>
                <div class="desc">
                  {{ content0.text | translate }}
                </div>
              </a>
            </div>
          </div>

          <div
            class="product-home-content1-item"
            [ngClass]="{
              showSlide: click_account == 1,
              hideSlide: click_account != 1
            }"
          >
            <div class="product-home-content1-item-main">
              <a
                class="product-home-content1-item2"
                *ngFor="let item of homeContent1"
              >
                <div
                  class="img"
                  [ngStyle]="{ backgroundImage: 'url(' + defaultImage + ')' }"
                  [lazyLoad]="item.imgUrl"
                ></div>
                <div class="desc">
                  {{ item.text | translate }}
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>

      <div class="product-home-content product-home-content2">
        <div class="product-home-content-wrapper">
          <div class="product-home-content-title">
            <!-- 智能化管理工具，银行级文件安全保障，避免数据泄露风险 -->
            {{ "product.home.content2Title" | translate }}
          </div>

          <div class="product-home-content-tab">
            <a
              *ngFor="let content2Tab of homeContent2Tab; let index = index"
              [ngClass]="{
                'product-home-content-tab-active': tab === content2Tab.id
              }"
              (click)="tab = content2Tab.id"
            >
              <span>
                {{ content2Tab.title | translate }}
              </span>
            </a>
          </div>

          <div class="product-home-content2-item">
            <div class="slidetoleft">
              <div
                class="product-home-content2-main"
                [ngClass]="{ slidetoleft: tab === content2.id }"
                *ngFor="let content2 of homeContent2"
              >
                <ng-container *ngIf="tab === content2.id">
                  <div class="product-home-content2-left">
                    <!-- <img [src]="content2.content2Img"> -->
                    <img
                      [lazyLoad]="content2.content2Img"
                      [src]="defaultImage"
                    />
                  </div>

                  <div class="product-home-content2-right">
                    <div class="product-home-content2-right-img">
                      <div
                        class="product-home-content2-right-img-item"
                        *ngFor="let exp of content2.content2Exp"
                      >
                        <!-- <img [src]="exp.imgUrl"> -->
                        <img
                          [lazyLoad]="exp.imgUrl"
                          [src]="defaultImage"
                        />

                        <div>{{ exp.title | translate }}</div>
                      </div>
                    </div>

                    <ul>
                      <li *ngFor="let subDsec of content2.content2Dsec">
                        {{ subDsec.desc | translate }}
                      </li>
                    </ul>

                    <div class="product-home-content-button">
                      <a [routerLink]="content2.buttonLink">
                        {{ content2.content2button | translate }}
                      </a>
                    </div>
                  </div>
                </ng-container>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="product-home-content">
        <div class="product-home-content-wrapper">
          <div class="product-home-content-title">
            <!-- 便捷访问文件，内外部文件安全共享，提高办公效率  -->
            {{ "product.home.content3Title" | translate }}
          </div>

          <div class="product-home-content-tab product-home-content3-tab">
            <a
              *ngFor="let content3Tab of homeContent3Tab"
              [ngClass]="{
                'product-home-content-tab-active': tab2 === content3Tab.id
              }"
              (click)="tab2 = content3Tab.id"
            >
              <span>
                {{ content3Tab.title | translate }}
              </span>
            </a>
          </div>

          <div
            class="product-home-content3-main"
            [ngClass]="{ slidetoleft: tab2 === content3.id }"
            *ngFor="let content3 of homeContent3"
          >
            <ng-container *ngIf="tab2 === content3.id">
              <div class="product-home-content3-main-left">
                <ul>
                  <li *ngFor="let desc3 of content3.content3Dsec">
                    <div class="product-home-content3-main-left-desc-title">
                      {{ desc3.title | translate }}
                    </div>
                    <div class="product-home-content3-main-left-desc-desc">
                      {{ desc3.desc | translate }}
                    </div>
                  </li>
                </ul>
              </div>

              <div class="product-home-content3-main-right">
                <img
                  [src]="defaultImage"
                  [lazyLoad]="content3.content3Img"
                  [ngClass]="
                    content3.shadow
                      ? 'product-home-content3-main-right-shadow'
                      : ''
                  "
                />

                <!-- <img 
                [src]="content3.content3Img" 
                [ngClass]= "content3.shadow ? 'product-home-content3-main-right-shadow' : ''"
              > -->
              </div>
            </ng-container>
          </div>
        </div>
      </div>

      <div class="product-home-content product-home-content4">
        <div class="product-home-content-wrapper">
          <div class="product-home-content-title">
            <!-- 集中化后台管理，帮助企业有效管理文件 -->
            {{ "product.home.content4Title" | translate }}
          </div>

          <div class="product-home-content4-main">
            <div
              class="product-home-content4-main-item"
              *ngFor="let content4 of homeContent4"
            >
              <!-- <img [src]="content4.imgUrl"> -->
              <img [lazyLoad]="content4.imgUrl" [src]="defaultImage"/>

              <div class="product-home-content4-main-item-title">
                {{ content4.title | translate }}
              </div>

              <div class="product-home-content4-main-item-desc">
                {{ content4.desc | translate }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="product-home-content">
        <div class="product-home-content-wrapper product-home-content5-wrapper">
          <div class="product-home-content-title product-home-content5-title">
            <!-- 燕麦云为全球数十万企业提供安全的内容管理服务 -->
            {{ "product.home.content5Title" | translate }}
          </div>

          <div class="product-home-content5-main">
            <div *ngFor="let content5 of homeContent5">
              <img [lazyLoad]="content5.imgUrl" [src]="defaultImage"/>
            </div>
          </div>

          <div class="product-home-content5-button1">
            <a routerLink="/solutions"
              >{{ "product.home.solutions" | translate }} >></a
            >
          </div>

          <div class="product-home-content5-button2">
            <a (click)="showForm()">
              {{ "product.home.cooperation-btn" | translate }}
            </a>
          </div>

          <!-- <div class="product-home-content product-home-content6"> -->
          <div
            #statistics
            id="statistics"
            class="product-home-content-wrapper product-home-content6-wrapper"
          >
            <div>
              {{ num1 }} {{ "product.home.year" | translate }}<br />
              {{ "product.home.numdesc1" | translate }}
            </div>
            <div>
              {{ num2 }} +<br />
              {{ "product.home.numdesc2" | translate }}
            </div>
            <div>
              {{ num3 }} +<br />
              {{ "product.home.numdesc3" | translate }}
            </div>
          </div>
          <!-- </div> -->
        </div>
      </div>
    </div>

    <app-suspension></app-suspension>

    
    <app-footer></app-footer>
  </div>
</div>
