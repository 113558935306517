import { Component, ViewChild, OnInit, ElementRef,AfterViewInit  } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { JumpPageService } from 'src/app/_share/service/jump-page.service';
import { OperationInterfaceService } from 'src/app/_share/service/operation-interface.service';
import { SendFormStatuService } from 'src/app/_share/service/send-form-statu.service';
import { UserAgentService } from 'src/app/_share/service/user-agent.service';
import Swiper from 'Swiper';


class Data {
  title: string;
  url: string;
  date: string;
  thumbnail?: string;
  categories: {
    title: string
  };
}

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.less']
})
export class HomeComponent implements OnInit,AfterViewInit  {

  public _statistics;
  @ViewChild('statistics')
    set stat(s: 'statistics') {
      this._statistics = s;
    }

  public _oatVideo;
  @ViewChild('oatVideo')
    set vdo(v: 'oatVideo') {
      this._oatVideo = v;
    }

 

  public isShowVideo: string;

  // 产品轮播对象
  public productSwiper: any;

  // 当前产品滑动下标
  public productIndex: number;

  public defaultImage: string = './assets/img/home/zip.png'

  constructor(
    private _jumpPage: JumpPageService,
    public _userAgents: UserAgentService,
    private _http: OperationInterfaceService,
    private _sendFormStatus: SendFormStatuService,
    private meta: Meta,
    private titleService: Title,
  ) {
    this.titleService.setTitle('燕麦云智能企业云盘：OATOS企业网盘、私有云存储解决方案');
    this.meta.addTag({ name: 'keywords', content: '燕麦云,企业云盘,企业网盘,OATOS,私有云存储' });
    this.meta.addTag({ name: 'description', content: '燕麦云智能企业云盘（原名OATOS企业网盘）提供政企数字化转型所需的企业云盘服务和私有云存储解决方案；燕麦云智能企业云盘升级原有的OATOS企业网盘产品，开发了极具实用价值的私有云存储方案，智慧城市与数字基础设施建设方案。燕麦云智能企业云盘服务热线：4000861108。' });
  }

  // 行业资讯数据
  public newsData: Array<Data> = [];

  // 产品动态数据
  public productData: Array<Data> = [];

  // 当前产品滑动下标
  public productIndex2: number;

  public tab: any;

  public tab2:any;

  public productIndex3: number;

  public homeContent0: Array<any> = [
    {
      imgUrl: './assets/img/home/home-content1-1-img2.jpg',
      text: 'product.home.homeContent0-text1'
    },
    {
      imgUrl: './assets/img/home/home-content1-1-img3.png',
      text: 'product.home.homeContent0-text2'
    },
    {
      imgUrl: './assets/img/home/home-content1-1-img4.png',
      text: 'product.home.homeContent0-text3'
    },
  ]

  public homeContent1: Array<any> = [
    {
      imgUrl: './assets/img/home/home-content1-1-img5.png',
      text: 'product.home.homeContent1-text1'
    },
    {
      imgUrl: './assets/img/home/home-content1-2-img1.png',
      text: 'product.home.homeContent1-text2'
    },
    {
      imgUrl: './assets/img/home/home-content1-2-img2.png',
      text: 'product.home.homeContent1-text3'
    },
    {
      imgUrl: './assets/img/home/home-content1-2-img3.png',
      text: 'product.home.homeContent1-text4'
    },
    // {
    //   imgUrl: './assets/img/home/home-content1-2-img4.png',
    //   text: '燕麦云助力中原油田，建立企业数字内容协同平台，优化数据资产，降低运营成本，提高生产效率。'
    // },
    // {
    //   imgUrl: './assets/img/home/home-content1-2-img4.png',
    //   text: '燕麦云落地澳门，以领先数据技术协助特区智慧城市发展'
    // }
  ]

  public homeContent2Tab: Array<any> = [
    {
      id: 'content2-1',
      title: 'product.home.homeContent2Tab1'
    },
    {
      id: 'content2-4',
      title: 'product.home.homeContent2Tab2'
    },
    {
      id: 'content2-3',
      title: 'product.home.homeContent2Tab3'
    },
    {
      id: 'content2-2',
      title: 'product.home.homeContent2Tab4'
    },
    {
      id: 'content2-5',
      title: 'product.home.homeContent2Tab5'
    },
  ]

  public homeContent2: Array<any> = [
    {
      id: 'content2-1',
      content2Title: 'product.home.homeContent2-title1',
      content2Exp: [
        {
          imgUrl: './assets/img/home-content2-exp1-img1.png',
          title: 'product.home.homeContent2-subtitle1'
        },
        {
          imgUrl: './assets/img/home-content2-exp1-img2.png',
          title: 'product.home.homeContent2-subtitle2'
        }
      ],
      content2Dsec: [
        {
          desc: 'product.home.homeContent2-subdesc1'
        },
        {
          desc:'product.home.homeContent2-subdesc2'
        }
      ],
      content2button: 'product.home.content2button',
      buttonLink: '/safety',
      content2Img: './assets/img/home/home-content2-img1.png'
    },

    {

      id: 'content2-4',
      content2Title: 'product.home.homeContent2-title2',
      content2Exp: [
        {
          imgUrl: './assets/img/home/home-content2-exp4-img1.png',
          title: 'product.home.homeContent2-subtitle3'
        },
        {
          imgUrl: './assets/img/home/home-content2-exp4-img2.png',
          title: 'product.home.homeContent2-subtitle4'
        }
      ],
      content2Dsec: [
        {
          desc: 'product.home.homeContent2-subdesc3'
        },
        {
          desc: 'product.home.homeContent2-subdesc4'
        },
        {
          desc: 'product.home.homeContent2-subdesc5'
        }
      ],
      content2button: 'product.home.content2button',
      buttonLink: '/safety',
      content2Img: './assets/img/home/home-content2-img4.png'
    },

    {

      id: 'content2-3',
      content2Title: 'product.home.homeContent2-title3',
      content2Exp: [
        {
          imgUrl: './assets/img/home/home-content2-exp3-img1.png',
          title: 'product.home.homeContent2-subtitle5'
        },
        {
          imgUrl: './assets/img/home/home-content2-exp3-img2.png',
          title: 'product.home.homeContent2-subtitle6'
        }
      ],
      content2Dsec: [
        {
          desc: 'product.home.homeContent2-subdesc6'
        },
        {
          desc:'product.home.homeContent2-subdesc7'
        }
      ],
      content2button: 'product.home.content2button',
      buttonLink: '/safety',
      content2Img: './assets/img/home/home-content2-img3.png'
    },


    {
      id: 'content2-2',
      content2Title: 'product.home.homeContent2-title4',
      content2Exp: [
        {
          imgUrl: './assets/img/home/home-content2-exp2-img1.png',
          title: 'product.home.homeContent2-subtitle7'
        }
      ],
      content2Dsec: [
        {
          desc: 'product.home.homeContent2-subdesc8'
        }
      ],
      content2button: 'product.home.content2button',
      buttonLink: '/safety',
      content2Img: './assets/img/home/home-content2-img2.png'
    },

    {

      id: 'content2-5',
      content2Title: 'product.home.homeContent2-title5',
      content2Exp: [
        {
          imgUrl: './assets/img/home/home-content2-exp5-img1.png',
          title: 'product.home.homeContent2-subtitle8'
        },
        {
          imgUrl: './assets/img/home/home-content2-exp5-img2.png',
          title: 'product.home.homeContent2-subtitle9'
        }
      ],
      content2Dsec: [
        {
          desc: 'product.home.homeContent2-subdesc9'
        }
      ],
      content2button: 'product.home.content2button',
      buttonLink: '/safety',
      content2Img: './assets/img/home/home-content2-img5.png'
    },
  ]

  public homeContent3Tab: Array<any> = [
    {
      id: 'content3-1',
      title: 'product.home.homeContent3Tab1'
    },
    {
      id: 'content3-2',
      title: 'product.home.homeContent3Tab2'
    },
    {
      id: 'content3-3',
      title: 'product.home.homeContent3Tab3'
    }
  ]

  public homeContent3: Array<any> = [
    {
      id: 'content3-1',
      content3Dsec: [
        {
          title: 'product.home.homeContent3-subtitle1',
          desc: 'product.home.homeContent3-subdesc1'
        },
        {
          title: 'product.home.homeContent3-subtitle2',
          desc: 'product.home.homeContent3-subdesc2'
        },
        {
          title: 'product.home.homeContent3-subtitle3',
          desc: 'product.home.homeContent3-subdesc3'
        },
        {
          title: 'product.home.homeContent3-subtitle4',
          desc: 'product.home.homeContent3-subdesc4'
        },
        {
          title: 'product.home.homeContent3-subtitle5',
          desc: 'product.home.homeContent3-subdesc5'
        },
      ],
      content3Img: './assets/img/home/home-content3-1.png',
      shadow: true
    },

    {
      id: 'content3-2',
      content3Dsec: [
        {
          title: 'product.home.homeContent3-subtitle6',
          desc: 'product.home.homeContent3-subdesc6'
        },
        {
          title: 'product.home.homeContent3-subtitle7',
          desc: 'product.home.homeContent3-subdesc7'
        },
        {
          title: 'product.home.homeContent3-subtitle8',
          desc: 'product.home.homeContent3-subdesc8'
        },
        {
          title: 'product.home.homeContent3-subtitle9',
          desc: 'product.home.homeContent3-subdesc9'
        }
      ],
      content3Img: './assets/img/home/home-content3-2.png',
      shadow: false
    },

    {
      id: 'content3-3',
      content3Dsec: [
        {
          title: 'product.home.homeContent3-subtitle10',
          desc: 'product.home.homeContent3-subdesc10'
        },
        {
          title: 'product.home.homeContent3-subtitle11',
          desc: 'product.home.homeContent3-subdesc11'
        },
        {
          title: 'product.home.homeContent3-subtitle12',
          desc: 'product.home.homeContent3-subdesc12'
        },
        {
          title: 'product.home.homeContent3-subtitle13',
          desc: 'product.home.homeContent3-subdesc13'
        }
      ],
      content3Img: './assets/img/home/home-content3-3.png',
      shadow: false
    }
  ]

  public homeContent4: Array<any> = [
    {
      imgUrl: './assets/img/home/home-content4-1.png',
      title: 'product.home.homeContent4-title1',
      desc: 'product.home.homeContent4-desc1'
    },
    {
      imgUrl: './assets/img/home/home-content4-2.png',
      title: 'product.home.homeContent4-title2',
      desc: 'product.home.homeContent4-desc2'
    },
    {
      imgUrl: './assets/img/home/home-content4-3.png',
      title: 'product.home.homeContent4-title3',
      desc: 'product.home.homeContent4-desc3'
    },
    {
      imgUrl: './assets/img/home/home-content4-4.png',
      title: 'product.home.homeContent4-title4',
      desc: 'product.home.homeContent4-desc4'
    },
    {
      imgUrl: './assets/img/home/home-content4-5.png',
      title: 'product.home.homeContent4-title5',
      desc: 'product.home.homeContent4-desc5'
    },
    {
      imgUrl: './assets/img/home/home-content4-6.png',
      title: 'product.home.homeContent4-title6',
      desc: 'product.home.homeContent4-desc6'
    }
  ]

  public homeContent5: Array<any> = [
    {
      imgUrl: './assets/img/home/home-content5-1.png'
    },
    {
      imgUrl: './assets/img/home/home-content5-2.png'
    },
    {
      imgUrl: './assets/img/home/home-content5-3.png'
    },
    {
      imgUrl: './assets/img/home/home-content5-4.png'
    },
    {
      imgUrl: './assets/img/home/home-content5-5.png'
    },
    {
      imgUrl: './assets/img/home/home-content5-6.png'
    },
    {
      imgUrl: './assets/img/home/home-content5-7.png'
    },
    {
      imgUrl: './assets/img/home/home-content5-8.png'
    },
    {
      imgUrl: './assets/img/home/home-content5-9.png'
    },
    {
      imgUrl: './assets/img/home/home-content5-10.png'
    },
    {
      imgUrl: './assets/img/home/home-content5-11.png'
    },
    {
      imgUrl: './assets/img/home/home-content5-12.png'
    },
    {
      imgUrl: './assets/img/home/home-content5-13.png'
    },
    {
      imgUrl: './assets/img/home/home-content5-14.png'
    },
    {
      imgUrl: './assets/img/home/home-content5-15.png'
    },
    {
      imgUrl: './assets/img/home/home-content5-16.png'
    },
    {
      imgUrl: './assets/img/home/home-content5-17.png'
    },
    {
      imgUrl: './assets/img/home/home-content5-18.png'
    },
    {
      imgUrl: './assets/img/home/home-content5-19.png'
    },
    {
      imgUrl: './assets/img/home/home-content5-20.png'
    },
    {
      imgUrl: './assets/img/home/home-content5-21.png'
    },
    {
      imgUrl: './assets/img/home/home-content5-22.png'
    },
    {
      imgUrl: './assets/img/home/home-content5-23-biyadi.png'
    },
    {
      imgUrl: './assets/img/home/home-content5-24.png'
    },
    {
      imgUrl: './assets/img/home/home-content5-25.png'
    },
  ]

  public num1: number = 0;
  public num2: number = 0;
  public num3: number = 0;

  public click_account: any = 0;

  // 是否显示表单
  public isShowForm: boolean = false;

  ngOnInit(): void {
    // 置顶
    this._jumpPage.goTop();
    
    this.tab = 'content2-1';
    this.tab2 = 'content3-1';

    this.getNews();

    this.getProduct();

    setInterval (() => {
      this.click_account = (this.click_account + 1) % 2;
    }, 5000);
  }
 

  // 产品轮播图初始化
  public productSwiperInit() {
    this.productSwiper = new Swiper('#swiper2', {
      spaceBetween: 30,
      speed: 500, // 滑动时间
      freeMode: true,
      watchSlidesVisibility: true,
      watchSlidesProgress: true,
      observer:true,//修改swiper自己或子元素时，自动初始化swiper
      observeParents:true,//修改swiper的父元素时，自动初始化swiper
    });
  }

  // 显示视频
  public showVideoPopup(event, myVideoPopup, key) {
    console.log(key);
    if (key == 'oatos') {
      this.isShowVideo = 'oatos'
    }else{
      this.isShowVideo = 'edu'
    }
    console.log(this.isShowVideo);
    myVideoPopup.showVideoPopup(event, myVideoPopup);
  }

  // 点击切换页面
  public changeSlide (): void {
    this.click_account = (this.click_account + 1) % 2;
  }

  ngAfterViewInit(): void {
    // console.log('wait 5 second');

    // let ele = document.getElementById('statistics');

    let ele = this._statistics.nativeElement;

    // let vdoEle = this._oatVideo.nativeElement;
    
    // if (this.isElementInViewport(vdoEle)) {
    //   window.onload = () => {
    //     var video = <HTMLVideoElement>document.getElementById('oatVideo');

    //     video.play();
    //   }
    // }

    if(this.isElementInViewport(ele)) {
      this._loadBar1();
      this._loadBar2();
      this._loadBar3();
    } else {
      this.num1 = 0;
      this.num2 = 0;
      this.num3 = 0;
    }

    window.addEventListener('scroll', () => {
      if (this.isElementInViewport(ele)) {
        this._loadBar1();
        this._loadBar2();
        this._loadBar3();
      } else {
        this.num1 = 0;
        this.num2 = 0;
        this.num3 = 0;
      }
    });

    
  }

  // 获取元素是否在可视区域
  public isElementInViewport(el) {
    let rect = el.getBoundingClientRect();
    return (
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
      rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
  }

  private _loadBar1() {
    let num = 8 / 100 * 10;
    let timer1 = setInterval(() =>{
      this.num1 = Number((this.num1 + num).toFixed(0));
      if(this.num1 >= 8) {
        this.num1 = 8;
        clearInterval(timer1)
      }
    }, 400)
  }

  private _loadBar2() {
    let num = 120000 / 3000 * 10;
    let timer1 = setInterval(() =>{
      this.num2 = Number((this.num2 + num).toFixed(0));
      if(this.num2 >= 120000) {
        this.num2 = 120000;
        clearInterval(timer1)
      }
    },10)
  }

  private _loadBar3() {
    let num = 10 / 100 * 10;
    let timer1 = setInterval(() =>{
      this.num3 = Number((this.num3 + num).toFixed(0));
      if(this.num3 >= 10) {
        this.num3 = 10;
        clearInterval(timer1)
      }
    }, 400)
  }

  

  // 获取产品动态
  private getProduct() {
    this._http.getProduct().subscribe({
      next: (response) => {
        if (response.status === 'ok') {
            this.productData = response.posts;
          }
      },
      error: (err) => {
        console.log(err);
      }
    })
  }

  // 获取行业资讯
  public getNews(): void {
    this._http.getNews().subscribe( {
      next: (response) => {
        if (response.status === 'ok') {
            this.newsData = response.posts;
          }
      },
      error: (err) => {
        console.log(err);
      }
    })
  }

  public showForm() {
    this._sendFormStatus.formStatu.emit(this.isShowForm = true);
    document.querySelector('html').style.overflow = 'hidden';
  }

}
