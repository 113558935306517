import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class JumpPageService {
  // 路由跳转,跳转到各个主题页面,例如goAuthor(作者id),跳转到相对应的作者页

  constructor() {}

  // 跳转页面ID
  public goAnchor(selector) {
    let anchor = document.documentElement.querySelector(selector);
    let offsetTop = anchor.offsetTop - 70;
    document.documentElement.scrollTop = offsetTop;
    document.body.scrollTop = offsetTop;
  }

  // 返回顶部
  public goTop(): void {
    document.documentElement.scrollTop = 0;
    document.body.scrollTop = 0
  }
}
