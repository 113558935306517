<div class="product-office-wrapper">
  <div class="product-office-header">
    <app-header
      [title]="officeTitle | translate"
      [btn]="officeBtn | translate"
      [imgSrc]="officeImgSrc"
      [link]="officeLink"
    ></app-header>
  </div>

  <div class="product-office-content-all">
    <div class="product-office-content">
      <div class="product-office-content-wrapper">
        <div class="product-office-content-title">
          {{'product.office-collaboration.main-title1' | translate}}
        </div>
        <div class="product-office-content1-main">
          <div class="product-office-content1-item" *ngFor="let content1 of officeContent1">
            <img [src]="content1.imgUrl">
            <div class="product-office-content1-item-title">
              {{content1.title | translate}}
            </div>
            <div class="product-office-content1-item-desc">
              {{content1.desc | translate}}
              <br>
              <a 
                *ngIf="content1.link"
                [href]="content1.link"
                target="_blank"
              >
                {{content1.btn | translate}}
              </a>
              <a 
                *ngIf="content1.route"
                [routerLink]="content1.route"
              >
                {{content1.btn | translate}}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="product-office-content product-office-content2">
      <div class="product-office-content-wrapper">
        <div class="product-office-content-title">
          {{'product.office-collaboration.main-title2' | translate}} 
        </div>
        <div class="product-office-content2-main">
          <div class="product-office-content2-main-content" *ngFor="let content2 of officeContent2" >
            <div class="product-office-content2-title">
              {{content2.title | translate}}
            </div>
            <div class="product-office-content2-desc">
              <div class="product-office-content2-item" *ngFor="let item of content2.children" >
                <img [src]="item.imgUrl">
                <div class="product-office-content2-item-title">
                  {{item.title | translate}}
                </div>
                <div class="product-office-content2-item-desc">
                  {{item.desc | translate}}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <app-suspension></app-suspension>

  <app-footer></app-footer>
</div>