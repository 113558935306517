import { Component, OnInit } from '@angular/core';
import {JumpPageService} from 'src/app/_share/service/jump-page.service';
import { Meta, Title } from '@angular/platform-browser';

class ServiceGuaranteeContent1 {
  title: string;
  desc: string;
  subContent: Array<{
    subtitle: string;
    desc: string;
  }>;
  processTitle: string;
  processContent: Array<{
    src: string;
    subtitle: string;
    desc: string;
  }>
}

class ServiceGuaranteeContent2 {
  title: string;
  desc: string;
  subContent: Array<{
    subtitle: string;
    desc: string;
    src: string;
  }>;
  responseTitle: string;
  responseContent: Array<{
    subtitle: string;
    desc: string;
    time: string;
  }>
}

@Component({
  selector: 'app-service-guarantee',
  templateUrl: './service-guarantee.component.html',
  styleUrls: ['./service-guarantee.component.less']
})
export class ServiceGuaranteeComponent implements OnInit {

  constructor(
    private _jumpPage: JumpPageService,
    private meta: Meta,
    private titleService: Title
  ) {
    this.titleService.setTitle('服务保障-燕麦云智能企业云盘');
    this.meta.addTag({ name: 'keywords', content: '服务保障,燕麦云,企业云盘,企业网盘,OATOS,私有云存储' });
    this.meta.addTag({ name: 'description', content: '燕麦云为所有部署燕麦云产品的客户提供服务器及系统的一站式运维管理的私有云服务，燕麦云提供的专业私有云服务为客户解决一切后顾之忧。燕麦云智能企业云盘服务热线：4000861108。' });
  }

  // public serviceTitle = 'product.service-guarantee.title'; // 标题
  public serviceTitle = '服务保障';
  // public serviceDesc = 'product.service-guarantee.desc';  // 描述
  public serviceDesc = '燕麦云为客户提供专业多渠道的售后服务，如果您在产品或解决方案的应用中遇到任何问题，都可以与我们取得联系。';
  public serviceImgSrc =  './assets/img/service/service-guarantee-bg.png'; //背景图
  // public serviceBtn = 'product.footer.contact-us-title';  // 按钮
  public serviceBtn = '联系我们';
  public serviceRoute = '/contact'


  // 服务保障内容1
  public serviceGuaranteeContent1: Array<ServiceGuaranteeContent1> = [
    {
      title: 'product.service-guarantee.content1-title',
      desc: 'product.service-guarantee.content1-desc',
      subContent: [
        {
          subtitle: 'product.service-guarantee.content1-subtitle1',
          desc: 'product.service-guarantee.content1-desc1'
        },
        {
          subtitle: 'product.service-guarantee.content1-subtitle2',
          desc: 'product.service-guarantee.content1-desc2'
        },
        {
          subtitle: 'product.service-guarantee.content1-subtitle3',
          desc: 'product.service-guarantee.content1-desc3'
        }
      ],
      processTitle: 'product.service-guarantee.content1-process-title',
      processContent: [
        {
          src: '/assets/img/service/1.png',
          subtitle: 'product.service-guarantee.content1-process-subtitle1',
          desc: 'product.service-guarantee.content1-process-desc1'
        },
        {
          src: '/assets/img/service/2.png',
          subtitle: 'product.service-guarantee.content1-process-subtitle2',
          desc: 'product.service-guarantee.content1-process-desc2'
        },
        {
          src: '/assets/img/service/3.png',
          subtitle: 'product.service-guarantee.content1-process-subtitle3',
          desc: 'product.service-guarantee.content1-process-desc3'
        },
        {
          src: '/assets/img/service/4.png',
          subtitle: 'product.service-guarantee.content1-process-subtitle4',
          desc: 'product.service-guarantee.content1-process-desc4'
        },
        {
          src: '/assets/img/service/5.png',
          subtitle: 'product.service-guarantee.content1-process-subtitle5',
          desc: 'product.service-guarantee.content1-process-desc5'
        },
        {
          src: '/assets/img/service/6.png',
          subtitle: 'product.service-guarantee.content1-process-subtitle6',
          desc: 'product.service-guarantee.content1-process-desc6'
        }
      ],
    },
  ];

  // 服务保障内容2
  public serviceGuaranteeContent2: Array<ServiceGuaranteeContent2> = [
    {
      title: 'product.service-guarantee.content2-title',
      desc: 'product.service-guarantee.content2-desc',
      subContent: [
        {
          subtitle: 'product.service-guarantee.content2-subtitle1',
          desc: 'product.service-guarantee.content2-desc1',
          src: '/assets/img/service/service-guarantee1.png'
        },
        {
          subtitle: 'product.service-guarantee.content2-subtitle2',
          desc: 'product.service-guarantee.content2-desc2',
          src: '/assets/img/service/service-guarantee2.png'
        }
      ],
      responseTitle: 'product.service-guarantee.content2-response-title',
      responseContent: [
        {
          subtitle: 'product.service-guarantee.content2-response-subtitle1',
          desc: 'product.service-guarantee.content2-response-desc1',
          time: 'product.service-guarantee.content2-response-time1',
        },
        {
          subtitle: 'product.service-guarantee.content2-response-subtitle2',
          desc: 'product.service-guarantee.content2-response-desc2',
          time: 'product.service-guarantee.content2-response-time2',
        },
        {
          subtitle: 'product.service-guarantee.content2-response-subtitle3',
          desc: 'product.service-guarantee.content2-response-desc3',
          time: 'product.service-guarantee.content2-response-time3',
        },
        {
          subtitle: 'product.service-guarantee.content2-response-subtitle4',
          desc: 'product.service-guarantee.content2-response-desc4',
          time: 'product.service-guarantee.content2-response-time4',
        },
        {
          subtitle: 'product.service-guarantee.content2-response-subtitle5',
          desc: 'product.service-guarantee.content2-response-desc5',
          time: 'product.service-guarantee.content2-response-time5',
        }
      ]
    }
  ];

  ngOnInit(): void {
    // 置顶
    this._jumpPage.goTop();
  }

}
