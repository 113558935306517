import { Component, OnInit } from '@angular/core';
import { JumpPageService } from 'src/app/_share/service/jump-page.service';

@Component({
  selector: 'app-energy',
  templateUrl: './energy.component.html',
  styleUrls: ['./energy.component.less']
})
export class EnergyComponent implements OnInit {

  constructor(
    private _jumpPage: JumpPageService,
  ) { }

  public energyTitle = 'product.energy.energyTitle'; // 标题
  public energyDesc = 'product.energy.energyDesc'; //描述
  public energyBtn = 'product.energy.energyBtn'; 
  public energyImgSrc = './assets/img/energy/energy-background.png'; //背景图
  public energyLink = 'https://app.oatos.com/os/#/welcome/sign-up'; //链接地址

  public caseContent: Array<any> = [
    {
      imgUrl: './assets/img/energy/energy-case-img1.png'
    },
    {
      imgUrl: './assets/img/energy/energy-case-img2.png'
    },
    {
      imgUrl: './assets/img/energy/energy-case-img3.png'
    }
  ]

  public featureTitle = 'product.energy.featureTitle';
  public featureDesc = 'product.energy.featureDesc';

  public customizeTitle = 'product.energy.customizeTitle';
  public customizeContent: Array<any> = [
    {
      imgUrl: './assets/img/energy/energy-customize-img1.svg',
      title: 'product.energy.customizeContent-title1',
      desc: 'product.energy.customizeContent-desc1'
    },
    {
      imgUrl: './assets/img/energy/energy-customize-img2.svg',
      title: 'product.energy.customizeContent-title2',
      desc: 'product.energy.customizeContent-desc2'
    },
    {
      imgUrl: './assets/img/energy/energy-customize-img3.svg',
      title: 'product.energy.customizeContent-title3',
      desc: 'product.energy.customizeContent-desc3'
    },
    {
      imgUrl: './assets/img/energy/energy-customize-img4.svg',
      title: 'product.energy.customizeContent-title4',
      desc: 'product.energy.customizeContent-desc4'
    }
  ]

  ngOnInit(): void {
    this._jumpPage.goTop();
  }

}
