import { Component, OnInit } from '@angular/core';
import { JumpPageService } from 'src/app/_share/service/jump-page.service';

@Component({
  selector: 'app-media',
  templateUrl: './media.component.html',
  styleUrls: ['./media.component.less']
})
export class MediaComponent implements OnInit {

  constructor(
    private _jumpPage: JumpPageService,
  ) { }

  public mediaTitle = 'product.media.mediaTitle'; // 标题
  public mediaDesc = 'product.media.mediaDesc'; //描述
  public mediaBtn = 'product.media.mediaBtn'; 
  public mediaImgSrc = './assets/img/media/media-background.png'; //背景图
  public mediaLink = 'https://app.oatos.com/os/#/welcome/sign-up'; //链接地址

  public caseContent: Array<any> = [
    {
      imgUrl: './assets/img/media/media-case-img1.png'
    },
    {
      imgUrl: './assets/img/media/media-case-img2.png'
    },
    {
      imgUrl: './assets/img/media/media-case-img3.png'
    }
  ]

  public featureTitle = 'product.media.featureTitle';
  public featureDesc = 'product.media.featureDesc';

  public customizeTitle = 'product.media.customizeTitle';
  public customizeContent: Array<any> = [
    {
      imgUrl: './assets/img/media/media-customize-img1.svg',
      title: 'product.media.customizeContent-title1',
      desc: 'product.media.customizeContent-desc1'
    },
    {
      imgUrl: './assets/img/media/media-customize-img2.svg',
      title: 'product.media.customizeContent-title2',
      desc: 'product.media.customizeContent-desc2'
    },
    {
      imgUrl: './assets/img/media/media-customize-img3.svg',
      title: 'product.media.customizeContent-title3',
      desc: 'product.media.customizeContent-desc3'
    }
  ]

  ngOnInit(): void {
    this._jumpPage.goTop();
  }

}
