import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from "ngx-cookie-service";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.less']
})
export class AppComponent {
  title = 'product-official-website';

  constructor(
    private translate: TranslateService,
    private cookie: CookieService,
) {
    let cookieExists: boolean = this.cookie.check('oatosLang');
    if (cookieExists) {
        let lang: string = cookie.get('oatosLang');
        this.translate.use(lang)
    } else {
        this.translate.setDefaultLang('zh-CHS');
    }
  }
}
