<div class="product-cloud-form-wrapper"  *ngIf="isShowForm">
  <div class="product-cloud-form-popup" (click)="hideForm()"></div>
  <div class="product-cloud-form-content-box">
    <div class="product-cloud-form-cancel" (click)="hideForm()">
      <img src="./assets/img/home/close.svg">
    </div>
    <div class="product-cloud-form-top">
      <div class="product-cloud-form-title">
        {{'product.cloud-from.title' | translate}}
      </div>
      <div class="product-cloud-form-subtitle">
        {{'product.cloud-from.subtitle' | translate}}
      </div>
    </div>
    <form  
      class="product-cloud-form-group" 
      #cloudForm="ngForm" 
      [ngClass]="{'product-cloud-form-group-ipad': this._userAgent.userAgent === 'iPad'}"
    >
      <div class="form-group">
        <label for="entName">
          <span>*</span>{{cloudFormContent.entName | translate}}
        </label>
        <input 
          type="text" 
          id="entName" 
          required  
          name="entName" 
          [(ngModel)]="ruleForm.entName" 
          #entName="ngModel"
          [ngClass]="entName.pristine && entName.untouched || entName.valid ? '':'product-cloud-form-warning-input'">
          <div class="alert alert-danger product-cloud-form-warning" [hidden]="entName.pristine && entName.untouched || entName.valid"
        >
        {{'product.cloud-from.entName-valid-empty' | translate}}
        </div>
      </div>
      <div class="form-group">
        <label for="industry">
          <span>*</span>{{cloudFormContent.industry | translate}}
        </label>
        <input 
          type="text" 
          id="industry" 
          required  
          name="industry" 
          [(ngModel)]="ruleForm.industry" 
          #industry="ngModel"
          [ngClass]="industry.pristine && industry.untouched || industry.valid ? '':'product-cloud-form-warning-input'"
        >
        <div class="alert alert-danger product-cloud-form-warning" [hidden]="industry.pristine && industry.untouched || industry.valid" >
          {{'product.cloud-from.industry' | translate}}
        </div>
      </div>
      <div class="form-group">
        <label for="size">
          <span>*</span>{{cloudFormContent.size | translate}}
        </label>
        <input 
          type="number" 
          id="size" 
          required  
          name="size"
          autofocus="autofocus" 
          [min]="1" 
          [value]="0" 
          [(ngModel)]="ruleForm.size" 
          #size="ngModel"
          [ngClass]="size.pristine && size.untouched || size.valid ? '':'product-cloud-form-warning-input'"
        >
        <label class="product-cloud-form-input-number">
          <span 
            class="product-cloud-form-input-number-up" 
            (click)="countNumber('up')"
          >
            <svg t="1581319560476" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="1109" width="128" height="128"><path d="M530.659556 466.887111l140.8 140.8a28.444444 28.444444 0 1 0 40.220444-40.220444L550.798222 406.528a28.330667 28.330667 0 0 0-40.220444 0L349.639111 567.466667a28.444444 28.444444 0 1 0 40.277333 40.220444l140.743112-140.8z" fill="#707070" p-id="1110"></path></svg>
          </span>
          <span 
            class="product-cloud-form-input-number-down" 
            (click)="countNumber('down')"
          >
            <svg t="1581321155305" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2328" width="128" height="128"><path d="M516.209778 566.897778L657.066667 426.097778a28.444444 28.444444 0 1 1 40.220444 40.220444l-160.881778 160.938667a28.330667 28.330667 0 0 1-40.220444 0L335.189333 466.318222A28.444444 28.444444 0 1 1 375.466667 426.097778l140.743111 140.8z" fill="#707070" p-id="2329"></path></svg>
          </span>
        </label>
      </div>
      <div class="form-group">
        <label for="contact">
          <span>*</span>{{cloudFormContent.contact | translate}}
        </label>
        <input 
          type="text" 
          id="contact" 
          required  
          name="contact" 
          [(ngModel)]="ruleForm.contact" 
          #contact="ngModel"
          [ngClass]="contact.pristine && contact.untouched || contact.valid ? '':'product-cloud-form-warning-input'"
        >
        <div 
        class="alert alert-danger product-cloud-form-warning" 
        [hidden]="contact.pristine && contact.untouched || contact.valid " 
        >
        {{'product.cloud-from.contact' | translate}}
        </div>
      </div>
      <div class="form-group product-cloud-form-mobile-item">
        <label for="mobile">
          <span>*</span>{{cloudFormContent.mobile | translate}}
        </label>
        <div class="product-cloud-form-country-code">
          <span>{{ countryCode }}</span>
          <ul class="product-cloud-form-tabs">
            <li *ngFor="let item of countryCodeArr; let index = index">
              <span (click)="tabsActiveIndex = index" [ngClass]="{'active': tabsActiveIndex === index}">
                {{item.tabTitle | translate}}
              </span>
              <ul *ngIf="tabsActiveIndex === index">
                <li *ngFor="let subitem of item.tabContent" >
                  <a (click)="changeCountryCode(subitem)">
                    {{subitem | translate}}
                  </a>
                </li>
              </ul>
            </li>
          </ul>
        </div>
        <input 
          type="text" 
          id="mobile" 
          required  
          name="mobile" 
          [(ngModel)]="ruleForm.mobile" 
          #mobile="ngModel"
          [ngClass]="mobile.pristine && mobile.untouched || mobile.valid ? '':'product-cloud-form-warning-input'"
        >
        <div 
          class="alert alert-danger product-cloud-form-warning-code" 
          [hidden]="mobile.pristine && mobile.untouched || mobile.valid" 
        >
        {{'product.cloud-from.mobile' | translate}}
        </div>
      </div>
      <div class="form-group product-cloud-form-code-item">
        <label for="picCaptcha">
          <span>*</span>{{cloudFormContent.picCaptcha | translate}}
        </label>
        <input 
          type="text"  
          id="picCaptcha" 
          required  
          name="picCaptcha" 
          [(ngModel)]="ruleForm.picCaptcha" 
          #picCaptcha="ngModel"
          [ngClass]="picCaptcha.pristine && picCaptcha.untouched || picCaptcha.valid ? '':'product-cloud-form-warning-input'"
        >
        <div 
          class="product-cloud-form-code" 
          (click)="getPicCaptchaUrl(uuid)"
        >
          <img [src]="codeSrc">
        </div>

        <div 
          class="alert alert-danger product-cloud-form-warning-code" 
          [hidden]="picCaptcha.pristine && picCaptcha.untouched || picCaptcha.valid"
        >
        {{'product.cloud-from.picCaptcha-empty' | translate}}
        </div>
      </div>
      <div class="form-group product-cloud-form-code-item">
        <label for="smsCaptcha">
          <span>*</span>{{cloudFormContent.smsCaptcha | translate}}
        </label>
        <input 
          type="text"  
          id="smsCaptcha" 
          required  
          name="smsCaptcha" 
          [(ngModel)]="ruleForm.smsCaptcha" 
          #smsCaptcha="ngModel"
          [ngClass]="smsCaptcha.pristine && smsCaptcha.untouched || smsCaptcha.valid ? '':'product-cloud-form-warning-input'"
        >
        <div 
          class="product-cloud-form-btn" 
          [ngClass]="{'active': isStartCountDown}" (click)="getPhoneCode()"
        >
        {{smsCaptchaText | translate}}
      </div>
        <div 
          class="alert alert-danger product-cloud-form-warning-code" 
          [hidden]="smsCaptcha.pristine && smsCaptcha.untouched || smsCaptcha.valid" 
        >
        {{'product.cloud-from.smsCaptcha-valid-empty' | translate}}
        </div>
      </div>
      <div class="form-group product-cloud-form-submit">
        <button type="primary" (click)="submitForm()">
          {{submitBtn | translate}}
        </button>
      </div>
    </form>
  </div>
</div>