import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.less']
})
export class HeaderComponent implements OnInit {

  constructor() { }

  @Input() public title: any; // 标题
  @Input() public desc: any; // 描述
  @Input() public imgSrc: any; // 图片地址
  @Input() public btn: any; // 按钮
  @Input() public link: any; // 链接地址
  @Input() public route: any; //路由地址


  ngOnInit(): void {
  }

}
