import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from 'src/app/home/home.component';
import { HeaderNavComponent } from 'src/app/_share/header-nav/header-nav.component';
import { FooterComponent } from 'src/app/_share/footer/footer.component';
// 安全保障
import { SafetyComponent } from 'src/app/safety/safety.component';
// 办公协同
import { OfficeCollaborationComponent } from 'src/app/office-collaboration/office-collaboration.component';
// 内容管控
import { ContentControlComponent } from 'src/app/content-control/content-control.component';
// 私有化定制
import { CustomComponent } from 'src/app/custom/custom.component';
// 解决方案
import { SolutionComponent } from 'src/app/solution/solution.component';
// 产品价格
import { PriceComponent } from 'src/app/price/price.component';
// 下载
import { DownloadComponent } from 'src/app/download/download.component';
// 服务保障
import { ServiceGuaranteeComponent } from 'src/app/service-guarantee/service-guarantee.component';
// 拟态保险箱
import { MimicryComponent } from 'src/app/mimicry/mimicry.component';
// 在线编辑
import { DocsComponent } from 'src/app/docs/docs.component';
// 智能版本管理
import { SmartVersionComponent } from 'src/app/smart-version/smart-version.component';
// 智能搜索
import { SmartFindComponent } from 'src/app/smart-find/smart-find.component';
// 联系我们 
import { ContactUsComponent } from 'src/app/contact-us/contact-us.component';
// 关于我们
import { AboutUsComponent } from 'src/app/about-us/about-us.component';
// 服务条款
import { TermsComponent } from 'src/app/terms/terms.component';
// 隐私政策
import { PrivacyComponent } from 'src/app/privacy/privacy.component';
// 网站地图
import { SiteMapComponent } from 'src/app/site-map/site-map.component';
// 合作伙伴
import { CooperationComponent } from 'src/app/cooperation/cooperation.component';
// fileDNA
import { FileDnaComponent } from 'src/app/file-dna/file-dna.component';
//解决方案-地产
import { LandComponent } from 'src/app/land/land.component';
//解决方案-智慧政府
import { GovernmentComponent } from 'src/app/government/government.component';
//解决方案-高端制造
import { ManufactureComponent } from 'src/app/manufacture/manufacture.component';
//解决方案-医疗业
import { MedicalComponent } from 'src/app/medical/medical.component';
//解决方案-零售业
import { TradeComponent } from 'src/app/trade/trade.component';
//解决方案-金融业
import { FinanceComponent } from 'src/app/finance/finance.component';
//解决方案-能源业
import { EnergyComponent } from 'src/app/energy/energy.component';
//解决方案-教育业
import { EducationComponent } from 'src/app/education/education.component';
//解决方案-文化传媒
import { MediaComponent } from 'src/app/media/media.component';
//解决方案-设计业
import { DesignComponent } from 'src/app/design/design.component';
//合作申请
import { ChannelCooperateComponent } from 'src/app/channel-cooperate/channel-cooperate.component';

const routes: Routes = [
  // 首页
  {
    path: 'home',
    component: HomeComponent,
  },
  // 安全保障
  {
    path: 'safety',
    component: SafetyComponent
  },
  // 办公协同
  {
    path: 'collaboration',
    component: OfficeCollaborationComponent
  },
  // 内容管控
  {
    path: 'content-controls',
    component: ContentControlComponent
  },
  // 私有化定制
  {
    path: 'customization',
    component: CustomComponent
  },
  // 解决方案
  {
    path: 'solutions',
    component: SolutionComponent
  },
  // 产品价格
  {
    path: 'pricing',
    component: PriceComponent
  },
  // 下载
  {
    path: 'download',
    component: DownloadComponent
  },
  // 服务保障
  {
    path: 'service',
    component: ServiceGuaranteeComponent
  },
  // 拟态保险箱
  {
    path: 'functions/mimic',
    component: MimicryComponent
  },
  // 在线编辑
  {
    path: 'functions/docs',
    component: DocsComponent
  },
  // 智能版本管理
  {
    path: 'functions/sversion',
    component: SmartVersionComponent
  },
  // 智能搜索
  {
    path: 'functions/smartfind',
    component: SmartFindComponent
  },
  // 联系我们
  {
    path: 'contact',
    component: ContactUsComponent
  },
  // 关于我们
  {
    path: 'aboutUs',
    component: AboutUsComponent
  },
  // 服务条款
  {
    path: 'terms',
    component: TermsComponent
  },
  // 隐私政策
  {
    path: 'privacy',
    component: PrivacyComponent
  },
  // 网站地图
  {
    path: 'sitemap',
    component: SiteMapComponent
  },
  // 合作伙伴
  {
    path: 'partnership',
    component: CooperationComponent
  },
  // fileDNA
  {
    path: 'filedna',
    component: FileDnaComponent
  },
  // 解决方案-地产
  {
    path: 'land',
    component: LandComponent
  },
  // 解决方案-智慧政府
  {
    path: 'government',
    component: GovernmentComponent
  },
  // 解决方案-高端制造
  {
    path: 'manufacture',
    component: ManufactureComponent
  },
  // 解决方案-医疗业
  {
    path: 'medical',
    component: MedicalComponent
  },
  // 解决方案-零售业
  {
    path: 'trade',
    component: TradeComponent
  },
  // 解决方案-金融业
  {
    path: 'finance',
    component: FinanceComponent
  },
  // 解决方案-能源业
  {
    path: 'energy',
    component: EnergyComponent
  },
  // 解决方案-教育业
  {
    path: 'education',
    component: EducationComponent
  },
  // 解决方案-文化传媒
  {
    path: 'media',
    component: MediaComponent
  },
  // 解决方案-文化传媒
  {
    path: 'design',
    component: DesignComponent
  },
  // 合作申请
  {
    path: 'cooperation',
    component: ChannelCooperateComponent
  },
  {
    path: '', 
    redirectTo: 'home', 
    pathMatch: 'full'
  },
  {
    path: '**',
    component: HomeComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(
    routes, { 
      useHash: true, 
      initialNavigation: 'enabled'
    }
  )],
  exports: [RouterModule]
})
export class AppRoutingModule { }
