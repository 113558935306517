<div>
  <div>
    <app-header
      [title]="cooperationTitle | translate"
      [imgSrc]="cooperationImgSrc"
    ></app-header>
  </div>

  <div class="product-cooperation-wrapper">
    <div class="product-cooperation-item1">
      <div *ngFor="let item of partner">
        <a target="_blank" [href]="item.link">
          <img [src]="item.imgUrl">
        </a>
      </div>
    </div>

    <div class="product-cooperation-item2">
      <div class="product-cooperation-item2-title">
        {{ 'product.footer.foot5' | translate }}
      </div>
      <div class="product-cooperation-item2-content">
        <a target="_blank" href="https://www.filedna.tech">FILEDNA</a>
        <a target="_blank" href="https://www.oatos.com">{{ 'product.footer.foot5-title0'| translate }}</a>
        <a target="_blank" href="https://app.oatos.com">{{ 'product.footer.foot5-title1' | translate }}</a>
        <a target="_blank" href="https://www.oatos.com">{{ 'product.footer.foot5-title2' | translate }}</a>
        <a target="_blank" href="https://www.dnspod.cn/">{{ 'product.footer.foot5-title3' | translate }}</a>
        <a target="_blank" href="http://sendcloud.sohu.com/">{{ 'product.footer.foot5-title4' | translate }}</a>
        <a target="_blank" href="http://www.sunde.cn/">{{ 'product.footer.foot5-title5' | translate }}</a>
        <a target="_blank" href="http://www.singhead.com/">{{ 'product.footer.foot5-title6' | translate }}</a>
        <a target="_blank" routerLink="/functions/mimic">{{ 'product.footer.foot5-title7' | translate }}</a>
        <a target="_blank" href="https://www.merchdna.com/">{{ 'product.footer.foot5-title8' | translate }}</a>
      </div>
    </div>
  </div>

  <app-suspension></app-suspension>

  <app-footer></app-footer>

</div>