import { Component, OnInit } from '@angular/core';
import {JumpPageService} from 'src/app/_share/service/jump-page.service';
import { Meta, Title } from '@angular/platform-browser';

class TraditionalSearch {
  title: string;
  desc: string;
  src: string;
}

class FunctionIntroduction {
  title: string;
  desc: string;
  src: string;
}

class UsageScenario {
  title: string;
  desc: string;
  src: string;
}


@Component({
  selector: 'app-smart-find',
  templateUrl: './smart-find.component.html',
  styleUrls: ['./smart-find.component.less']
})
export class SmartFindComponent implements OnInit {

  constructor(
    private _jumpPage: JumpPageService,
    private meta: Meta,
    private titleService: Title
  ) {
    this.titleService.setTitle('智能搜索-燕麦云智能企业云盘');
    this.meta.addTag({ name: 'keywords', content: '智能搜索,SmartFind,燕麦云,企业云盘,企业网盘,OATOS,私有云存储' });
    this.meta.addTag({ name: 'description', content: '燕麦云SmartFind智能搜索引擎以先进人工智能技术赋能云端数据搜索，极大提高文件检索和管理效率。燕麦云SmartFind智能搜索引擎基于领先的深度学习技术，对文件、图片、视频进行智能识别，“读懂”你的文件，并根据搜索关键词展示相关结果。燕麦云智能企业云盘服务热线：4000861108。' });
  }

  public SfindTitle = '智能搜索引擎'; // 标题
  public SfindDesc = '更智能的文件数据搜索引擎，支持通过文件内容关键词从海量数据中快速找出目标文件，支持图片及音视频内容搜索'; // 描述
  public SfindImgSrc = '/assets/img/Sfind/smart_find.png'; // 背景图

  // 传统搜索
  public traditionalSearch: Array<TraditionalSearch> = [
    {
      title: "product.smart-find.title3-subtitle1",
      desc: "product.smart-find.title3-desc1",
      src: "/assets/img/Sfind/smart_find_01.png"
    },
    {
      title: "product.smart-find.title3-subtitle2",
      desc: "product.smart-find.title3-desc2",
      src: "/assets/img/Sfind/smart_find_02.png"
    }
  ];

  // 功能介绍
  public functionIntroduction: Array<FunctionIntroduction> = [
    {
      title: "product.smart-find.title4-subtitle1",
      desc: "product.smart-find.title4-desc1",
      src: "/assets/img/Sfind/smart_find_03.png"
    },
    {
      title: "product.smart-find.title4-subtitle2",
      desc: "product.smart-find.title4-desc2",
      src: "/assets/img/Sfind/smart_find_04.png"
    },
    {
      title: "product.smart-find.title4-subtitle3",
      desc: "product.smart-find.title4-desc3",
      src: "/assets/img/Sfind/smart_find_05.png"
    }
  ];

  // 使用场景
  public usageScenario: Array<UsageScenario> = [
    {
      title: "product.smart-find.title5-subtitle1",
      desc: "product.smart-find.title5-desc1",
      src: "/assets/img/Sfind/smart_find_06.png"
    },
    {
      title: "product.smart-find.title5-subtitle2",
      desc: "product.smart-find.title5-desc2",
      src: "/assets/img/Sfind/smart_find_07.png"
    },
    {
      title: "product.smart-find.title5-subtitle3",
      desc: "product.smart-find.title5-desc3",
      src: "/assets/img/Sfind/smart_find_08.png"
    },
    {
      title: "product.smart-find.title5-subtitle4",
      desc: "product.smart-find.title5-desc4",
      src: "/assets/img/Sfind/smart_find_09.png"
    },
  ];

  ngOnInit(): void {
    this._jumpPage.goTop();
  }

}
