import { Component, ViewChild, ElementRef, AfterViewInit } from '@angular/core';

@Component({
  selector: 'app-auto-dialog',
  templateUrl: './auto-dialog.component.html',
  styleUrls: ['./auto-dialog.component.less']
})
export class AutoDialogComponent implements AfterViewInit {
  @ViewChild('dialog') dialog: ElementRef;
  pdfSrc = 'assets/remark.pdf'; // PDF 文件路径
  keyName = "declearReaded";
  public closeText: string = 'product.declare.close';

  ngAfterViewInit(): void {
    this.dialog.nativeElement.style.display = 'none';
    if (localStorage.getItem(this.keyName) == null) {
      this.dialog.nativeElement.style.display = 'block';
      // this.startCountdown();
    }
  }

  closeDialog(): void {
    this.dialog.nativeElement.style.display = 'none';
    localStorage.setItem("declearReaded", "true");
  }

  countdown: number = 10; // 倒计时秒数
  timer: any; // 定时器对象

  startCountdown(): void {
    this.timer = setInterval(() => {
      if (this.countdown > 0) {
        this.countdown--;
      } else {
        clearInterval(this.timer);
        this.dialog.nativeElement.style.display = 'none';
      }
    }, 1000);
  }
}