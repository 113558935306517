import { Component, OnInit } from '@angular/core';
import { OperationInterfaceService } from '../_share/service/operation-interface.service';
import { TranslateService } from '@ngx-translate/core';
import { UserAgentService } from "../_share/service/user-agent.service";
import { JumpPageService } from "../_share/service/jump-page.service";
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-channel-cooperate',
  templateUrl: './channel-cooperate.component.html',
  styleUrls: ['./channel-cooperate.component.less']
})
export class ChannelCooperateComponent implements OnInit {

  constructor(
    private _http: OperationInterfaceService, // 接口服务
    private _translate: TranslateService, // 翻译
    public _userAgent: UserAgentService,
    private _jumpPage: JumpPageService,
    private meta: Meta,
    private titleService: Title
  ) {
    this.titleService.setTitle('渠道合作-燕麦云智能企业云盘');
    this.meta.addTag({ name: 'keywords', content: '渠道合作,燕麦云,企业云盘,企业网盘,OATOS,私有云存储' });
    this.meta.addTag({ name: 'description', content: '燕麦云坚持合作经营，互补互利，规模覆盖的原则，与商业伙伴共同打造核心竞争力，实现长期合作，共赢未来目标。欢迎提交燕麦云合作申请！燕麦云智能企业云盘服务热线：4000861108。' });
  }

  public title = 'product.channel-cooperation.title'; // 标题
  public intro = 'product.channel-cooperation.intro'; // 简介
  public imgSrc = '../../assets/img/channel/channel-cooperation-bg.png'; // 图片
  public submitBtn = 'product.channel-cooperation.submitbtn'; // 提交按钮
  public codeSrc = ''; // 验证码
  public uuid = '';

  // 渠道申请内容标题
  public channelCooperationContent = {
    name: 'product.channel-cooperation.name',
    site: 'product.channel-cooperation.site',
    contacts: 'product.channel-cooperation.contacts',
    number: 'product.channel-cooperation.number',
    address: 'product.channel-cooperation.address',
    job: 'product.channel-cooperation.job',
    scale: 'product.channel-cooperation.scale',
    desc: 'product.channel-cooperation.desc',
    code: 'product.channel-cooperation.code',
  };

  // 渠道申请数据
  public ruleForm = {
    name: '',
    site: '',
    contacts: '',
    number: '',
    address: '',
    job: '',
    scale: '',
    desc: '',
    code: '',
    reset: function () {
      this.name = '';
      this.site = '';
      this.contacts = '';
      this.number = '';
      this.address = '';
      this.job = '';
      this.scale = '';
      this.desc = '';
      this.code = '';
    }
  };

  ngOnInit() {
    this.getUUid();
    this._userAgent.checkSystem();
    this._jumpPage.goTop();
  }

  // 提交表单
  public submitForm() {
    if (this.ruleForm.name === '') {
      alert(this._translate.instant('product.channel-cooperation.name-valid-empty'));
    } else if (this.ruleForm.contacts === '') {
      alert(this._translate.instant('product.channel-cooperation.contacts-valid-empty'));
    } else if (this.ruleForm.number === '') {
      alert(this._translate.instant('product.channel-cooperation.number-valid-empty'));
    } else if (this.ruleForm.address === '') {
      alert(this._translate.instant('product.channel-cooperation.address-valid-empty'));
    } else if (this.ruleForm.code === '') {
      alert(this._translate.instant('product.channel-cooperation.code-valid-empty'));
    } else {
      this.channelApplyForForm();
    }
    return false;
  }

  // 提交渠道合作表单
  private channelApplyForForm() {

    // 提交内容的发送
    let params = {
      nameOrganization:    this.ruleForm.name,
      institutionAddress:    this.ruleForm.site,
      contacts:    this.ruleForm.contacts,
      contactNumber:      this.ruleForm.number,
      contactAddress:    this.ruleForm.address,
      industryInvolved:   this.ruleForm.job,
      organizationScale:       this.ruleForm.scale,
      intentionDescribe:  this.ruleForm.desc,
      verificationCode: this.ruleForm.code,
      uuid : this.uuid
    };

    this._http.channelApplyFor(params).subscribe( {
      next: (response) => {
        if (response.statusCode === 405) {
          alert(this._translate.instant('product.cloud-from.apply-error-alert'));
        }
        if (response.statusCode === 200) {
          alert(this._translate.instant('product.cloud-from.apply-cooperation-success'));
          // this._clearForm();
          // this.getUUid();
          window.location.reload();
        } else {
          // 图形验证码错误信息
          if (response.msg && response.statusCode !==428) {
            alert(this._translate.instant(response.msg));
          }
          // 图形验证码失效
          if (response.statusCode === 428) {
            alert(this._translate.instant(response.msg));
            this.getPicCaptchaUrl(this.uuid);
          }
        }
      },
      error: (err) => {
         console.log(err);
      }
    });
  }

  // 获取uuid
  public getUUid() {
    this._http.getUuid().subscribe({
      next: (response)=>{
        this.uuid = response.uuid;

        // 获取图形验证码
        this.getPicCaptchaUrl(this.uuid)
      },
      error: (err)=> {
        console.log(err);
      }
    })
  }

  // 刷新获取图形验证码
  public getPicCaptchaUrl(uuid) {
    let params = {
        uuid: uuid
    };
    this.codeSrc = this._http.getPicCaptchaUrl(params);
  }

  // 清空表单数据
  private _clearForm() {
    this.ruleForm.reset();
  }

}

