import { Component, OnInit } from '@angular/core';
import { JumpPageService } from 'src/app/_share/service/jump-page.service';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-solution',
  templateUrl: './solution.component.html',
  styleUrls: ['./solution.component.less']
})
export class SolutionComponent implements OnInit {

  constructor(
    private _jumpPage: JumpPageService,
    private meta: Meta,
    private titleService: Title
  ) {
    this.titleService.setTitle('行业解决方案-燕麦云智能企业云盘');
    this.meta.addTag({ name: 'keywords', content: '燕麦云,企业云盘,企业网盘,OATOS,私有云存储解决方案' });
    this.meta.addTag({ name: 'description', content: '燕麦云为企业数字化转型及数据资产管理提供解决方案，在金融、高端制造、地产、能源、医疗、教育、商贸、传媒等行业均拥有成熟的行业解决方案。燕麦云智能企业云盘服务热线：4000861108。' });
  }

  public solutionTitle: string = '行业解决方案';
  public solutionDesc: string = '燕麦云为企业数字化转型及内容安全协作提供优质解决方案，覆盖金融、高端制造、教育、能源、地产、商贸、医疗、文化传媒、智慧政府、设计等十大行业，为新时代企业的竞争优势加上智胜砝码。';
  public solutionImgSrc: string = '/assets/img/solution/solution-background.png';

  
  public solutionArr: Array<any> = [
    {
      route: '/finance',
      name: 'finance',
      title: '金融',
      subtitle: '为金融数据建立安全保护体系',
      desc: '燕麦云DMZ（Demilitarized Zone）数据共享方案为金融企业打造一个安全的缓冲区，在保障内网数据绝不外出的前提下，让总部与分支机构进行跨部门、跨区域（网点）的文件安全共享与流转，显著提高文件流转效率。',
      dataDesc: '文件安全流转效率提升69%',
      src: '/assets/img/solution/solution-img1.png',
      logo1x: '-360px',
      logo1y: '-192px',
      logo2x: '-1080px',
      logo2y: '-64px',
      logo3x: '-120px',
      logo3y: '-320px',
    },
    {
      route: '/manufacture',
      name: 'manufacture',
      title: '高端制造',
      subtitle: '为制造业数字化和智能化转型助力',
      desc: '技术研发是高端制造企业的核心驱动力之一，燕麦云制造业数据管理与共享方案在保障研发数据安全的前提下，通过对核心生产流程的全方位对接，实现自动化的文档及数据流转与监管，显著提升生产效率和研发数据安全性。',
      dataDesc: '生产文档安全流转效率提升73%',
      src: '/assets/img/solution/solution-img2.png',
      logo1x: '-720px',
      logo1y: '-320px',
      logo2x: '-1080px',
      logo2y: '-192px',
      logo3x: '0px',
      logo3y: '-256px',
    },
    {
      route: '/education',
      name: 'education',
      title: '教育',
      subtitle: '让知识的传递更加简单高效',
      desc: '燕麦云替代学校中传统的FTP服务器，实现科研教学资料的一站式集中存储、安全访问、实时共享与快速分发，通过提供教学资料分享中心，让教师与学生通过安全的内部链接分享方式流转课件、作业、以及学习研究心得。',
      dataDesc: '课件分发效率提升65%',
      src: '/assets/img/solution/solution-img3.png',
      logo1x: '-960px',
      logo1y: '-192px',
      logo2x: '0px',
      logo2y: '-128px',
      logo3x: '0px',
      logo3y: '-64px',
    },
    {
      route: '/energy',
      name: 'energy',
      title: '能源',
      subtitle: '为能源企业实现“数字化内容”的价值最大化',
      desc: '与传统牢笼式的安全方案不同，燕麦云通过集中化的安全存储与智能化的态势感知技术，帮助能源企业在全面禁用电脑USB接口及U盘后，仍能便捷安全地进行文件协同，打通企业内部与上下游机构之间的文件安全流转通道。',
      dataDesc: '业务文件安全流转效率提升68%',
      src: '/assets/img/solution/solution-img4.png',
      logo1x: '-1080px',
      logo1y: '-128px',
      logo2x: '-960px',
      logo2y: '0px',
      logo3x: '-960px',
      logo3y: '-320px',
    },
    {
      route: '/land',
      name: 'land',
      title: '地产',
      subtitle: '让数据在流转中产生价值',
      desc: '燕麦云为地产业打造安全的项目文档存储与协同中心，增强房企对不同区域项目的掌控。同时，燕麦云独有的生产级100%云端CAD图纸预览方案，帮助地产施工方在施工现场可以快速对照图纸解决难题，提升工程质量。',
      dataDesc: '项目文件安全流转效率提升71%',
      src: '/assets/img/solution/solution-img5.png',
      logo1x: '0px',
      logo1y: '0px',
      logo2x: '-480px',
      logo2y: '-192px',
      logo3x: '-240px',
      logo3y: '0px',
    },
    {
      route: '/trade',
      name: 'trade',
      title: '零售',
      subtitle: '管理海量商业数据变得更容易',
      desc: '商贸平台通常拥有众多商品供应商，每天都产生着海量的敏感商业文件，燕麦云从根本上改善了商贸平台依靠邮件沟通商业文件的低效情况，实现了跨地区、甚至跨国的海量文件数据统一管理，为企业打造商业文件传输的安全高速公路。',
      dataDesc: '文件跨地域安全流转效率提升78%',
      src: '/assets/img/solution/solution-img6.png',
      logo1x: '-360px',
      logo1y: '-128px',
      logo2x: '-840px',
      logo2y: '-128px',
      logo3x: '-240px',
      logo3y: '-128px',
    },
    {
      route: '/medical',
      name: 'medical',
      title: '医疗',
      subtitle: '轻松高效地访问及共享医学资料',
      desc: '燕麦云从安全、智能、易用三个方面解决了传统医疗非结构化数据存储和备份设备所存在的硬件损坏、安全管理机制缺失、以及高可用灾备等问题。同时，通过与医疗机构现有IT系统进行无缝对接，显著提升医疗非结构化数据的流动性与安全性。',
      dataDesc: '医疗文件安全流转效率提升68%',
      src: '/assets/img/solution/solution-img7.png',
      logo1x: '-720px',
      logo1y: '-192px',
      logo2x: '-600px',
      logo2y: '-256px',
      logo3x: '-840px',
      logo3y: '-64px',
    },
    {
      route: '/media',
      name: 'media',
      title: '文化传媒',
      subtitle: '保护内容版权，革新内容协作方式',
      desc: '版权数字内容是现代传媒业的重要资产，燕麦云基于人工智能技术帮助传媒业搭建新一代的内容管理与版权保护平台。对内，可有效预防版权数字内容的泄露风险；对外，让版权数字内容可以安全地发布、流通、并充分体现商业价值。',
      dataDesc: '内容版权安全性10倍提升',
      src: '/assets/img/solution/solution-img8.png',
      logo1x: '-1080px',
      logo1y: '-256px',
      logo2x: '-480px',
      logo2y: '0px',
      logo3x: '0px',
      logo3y: '-192px',
    },
    {
      route: '/government',
      name: 'government',
      title: '智慧政府',
      subtitle: '实现政法体系信息安全高效互通',
      desc: '版权数字内容是现代传媒业的重要资产，燕麦云基于人工智能技术帮助传媒业搭建新一代的内容管理与版权保护平台。对内，可有效预防版权数字内容的泄露风险；对外，让版权数字内容可以安全地发布、流通、并充分体现商业价值。',
      dataDesc: '行政文件安全流转效率提升82%',
      src: '/assets/img/solution/solution-img9.png',
      logo1x: '-1080px',
      logo1y: '-256px',
      logo2x: '-480px',
      logo2y: '0px',
      logo3x: '0px',
      logo3y: '-192px',
    },
    {
      route: '/design',
      name: 'design',
      title: '设计',
      subtitle: '重塑文件管理模式',
      desc: '版权数字内容是现代传媒业的重要资产，燕麦云基于人工智能技术帮助传媒业搭建新一代的内容管理与版权保护平台。对内，可有效预防版权数字内容的泄露风险；对外，让版权数字内容可以安全地发布、流通、并充分体现商业价值。',
      dataDesc: '设计文件管理效率提升76%',
      src: '/assets/img/solution/solution-img10.png',
      logo1x: '-1080px',
      logo1y: '-256px',
      logo2x: '-480px',
      logo2y: '0px',
      logo3x: '0px',
      logo3y: '-192px',
    }
  ];

  ngOnInit(): void {
    this._jumpPage.goTop();
  }

}
