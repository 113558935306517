import { Component, OnInit } from '@angular/core';
import { JumpPageService } from "src/app/_share/service/jump-page.service";
import { Meta, Title } from '@angular/platform-browser';
import { OperationInterfaceService } from 'src/app/_share/service/operation-interface.service';

@Component({
  selector: 'app-privacy',
  templateUrl: './privacy.component.html',
  styleUrls: ['./privacy.component.less']
})
export class PrivacyComponent implements OnInit {

  constructor(
    private _jumpPage: JumpPageService,
    private meta: Meta,
    private titleService: Title,
    private _http: OperationInterfaceService,
  ) {
    this.titleService.setTitle('隐私政策-燕麦云智能企业云盘');
    this.meta.addTag({ name: 'keywords', content: '隐私政策,燕麦云,企业云盘,企业网盘,OATOS,私有云存储' });
    this.meta.addTag({ name: 'description', content: '燕麦云隐私政策述了我们收集用户信息的内容，我们收集信息的方式和目的，如何共享信息，以及如何保护信息。燕麦云智能企业云盘服务热线：4000861108。' });
  }

  public title: string = '';
  public content: string = '';

  ngOnInit(): void {
    this._jumpPage.goTop();

    this._http.getPactDetails({
      type: 'privacy',
    }).subscribe({
      next: (result: any) => {
        this.title = result.data.title;
        this.content = result.data.content;
      },
      error: (errorCode: string) => {
        console.error(errorCode);
      }
    })
  }
}
