<div class="oat-video-popup" *ngIf="isShowVideo">
  <div class="oat-popup"></div>
  <div class="oat-video-wrapper">
    <ng-container *ngIf="key == 'oatos'">
      <video autoplay [src]="videoSrc" controls="controls" (click)="showVideoPopup($event, popupVideo)"  ref-popupVideo></video>
    </ng-container>
    <!-- <ng-container *ngIf="key == 'edu'">
      <video autoplay [src]="eduVideoSrc" controls="controls" (click)="showVideoPopup($event, popupVideo)"  ref-popupVideo></video>
    </ng-container> -->
    <div class="oat-image">
      <img src="../../assets/img/home/cancel.svg" (click)="hideVideoPopup()" alt="">
    </div>
  </div>
</div>
