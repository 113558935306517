<div class="product-footer">
  <div class="product-footer-header" lazyLoad="/assets/img/footer-background.png">
    <div class="product-footer-title">
      {{'product.footer.titlte' | translate}}
    </div>
    <div class="product-footer-button">
      <a
        href="https://app.oatos.com/os/#/welcome/sign-up" 
        target="_blank"
      >
      {{'product.footer.btn' | translate}}
      </a>
    </div>
  </div>
  
  <div class="product-footer-list">
    <div class="product-footer-list-wrapper">
      <div class="product-footer product-info">
        <div class="product-footer-info">
          <div class="product-footer-info-left">
            <ul>
              <li>
                <a>{{'product.footer.foot1' | translate}}</a>
              </li>
              <li>
                <a routerLink="/aboutUs">{{'product.footer.aboutUs' | translate}}</a>
              </li>
              <li>
                <a target="_blank" href="https://www.oatos.com/courses/henrycolumn">{{'product.footer.foot1-title1' | translate}}</a>
              </li>
              <li>
                <a target="_blank" href="https://www.oatos.com/news">{{'product.footer.foot1-title2' | translate}}</a>
              </li>
              <li>
                <a target="_blank" href="https://www.qycloud.com/#/careers">{{'product.footer.foot1-title3' | translate}}</a>
              </li>
              <li>
                <a routerLink="/terms">{{'product.footer.foot1-title4' | translate}}</a>
              </li>
              <li>
                <a routerLink="/privacy">{{'product.footer.foot1-title5' | translate}}</a>
              </li>
            </ul>
            <ul>
              <li>
                <a>{{'product.footer.foot2' | translate}}</a>
              </li>
              <li>
                <a href="https://www.oatos.com/" target="_blank">{{'product.footer.foot2-title1' | translate}}</a>
              </li>
              <li>
                <a href="https://www.merchdna.com/" target="_blank">{{'product.footer.foot2-title2' | translate}}</a>
              </li>
              <li>
                <a href="https://www.stexture.com/" target="_blank">智纹</a>
              </li>
              <li>
                <a target="_blank" href="http://www.qycloud.com/#/laboratory">{{'product.footer.foot2-title3' | translate}}</a>
              </li>
            </ul>
            <ul>
              <li>
                <a>{{'product.footer.foot3' | translate}}</a>
              </li>
              <li>
                <a target="_blank" href="https://www.oatos.com/help">{{'product.footer.foot3-title1' | translate}}</a>
              </li>
              <li>
                <a href="https://www.oatos.com/courses" target="_blank">{{'product.footer.foot3-title2' | translate}}</a>
              </li>
              <li>
                <a routerLink="/cooperation">{{'product.footer.foot3-title3' | translate}}</a>
              </li>
              <li>
                <a routerLink="/contact">联系我们</a>
              </li>
              <li>
                <a routerLink='/partnership'>{{'product.footer.foot3-title4' | translate}}</a>
              </li>
              <li>
                <a routerLink="/sitemap">{{'product.footer.foot3-title5' | translate}}</a>
              </li>
            </ul>
          </div>
        
          <div class="product-footer-info-right">
            <div>
              <span class="i">
                {{'product.footer.foot4' | translate}}
              </span>
              <span>
                400-086-1108
              </span>
              <div>
                {{'product.footer.foot4-time1' | translate}}
              </div>
              <span>
                +86-755-23358866
              </span>
              <div  >
                {{'product.footer.foot4-time' | translate}}
              </div>
            </div>
          </div>
        </div>
      </div>
    
      <div class="product-footer product-copyright">
        <div class="product-footer-copyright">
          <div class="product-footer-copyright-top">
            <!-- <div class="product-footer-links">
              <span>友情链接：</span>
              <a target="_blank" routerLink="/functions/filedna">FILEDNA</a>
              <a target="_blank" href="https://www.oatos.com">燕麦云</a>
              <a target="_blank" href="https://www.oatos.com">企业网盘</a>
              <a target="_blank" href="https://www.oatos.com">私有云</a>
              <a target="_blank" href="https://www.dnspod.cn/">DNSpod</a>
              <a target="_blank" href="http://sendcloud.sohu.com/">SendCloud</a>
              <a target="_blank" href="http://www.sunde.cn/">桌面云</a>
              <a target="_blank" href="http://www.singhead.com/">呼叫中心</a>
              <a target="_blank" routerLink="/functions/mimic">拟态保险箱</a>
              <a target="_blank" href="https://www.merchdna.com/">百货基因</a>
            </div> -->
          </div>
          <div class="product-footer-copyright-bottom">
            <div class="product-footer-copyright-plice">
              <div>
                <span>Copyright © 2020</span>
                <span>{{'product.footer.foot2-title1' | translate}}</span>
                <a target="_blank" href="http://beian.miit.gov.cn/"> {{'product.footer.tip-desc1' | translate}} </a>
                <img [src]="defaultImage" alt="" lazyLoad="/assets/img/record-icon.png">
                <span>{{'product.footer.tip-desc3' | translate}}</span>
                <a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=44030502001316"> {{'product.footer.tip-desc2' | translate}} </a>
              </div>
              <div class="product-footer-change-lang">
                <div class="product-change-lang-item">
                  <img [src]="defaultImage" lazyLoad="./assets/img/lang.svg"><span>{{ currentLang === 'zh-CHT' ? '繁體中文' : '简体中文' }}</span>
                  <ul>
                    <li *ngFor="let Lang of lang" >
                      <span (click)="useLanguage(Lang)">{{ Lang.name }}</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        
        <a class="product-footer-img" href="./assets/img/footer-cert.png" target="_blank">
          <img [src]="defaultImage" lazyLoad="./assets/img/footer-1.png">
          <img [src]="defaultImage" lazyLoad="./assets/img/footer-2.png">
          <img [src]="defaultImage" lazyLoad="./assets/img/footer-3.png">
        </a>
      </div>
    </div>
</div>

