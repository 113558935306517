<div>
  <div class="product-header-wrapper" id="product-header-wrapper">
    <app-header-nav></app-header-nav>
  </div>
  <!-- <div class="product-privacy-wrapper">
    <div class="product-privacy">
      <div class="product-privacy-title">燕麦云隐私政策</div>
      <div class="product-privacy-date">燕麦云隐私政策自2020年4月22日发布并生效</div>
      <div class="product-privacy-detail">
        <div class="product-privacy-subtitle">一、总览</div>
        <ul>
          <li>企业云公司（以下统称“我们”）非常重视用户的隐私保护，本隐私政策（以下统称“本政策”）阐述了我们收集用户（以下统称“您”）信息的内容，我们收集信息的方式和目的，如何共享信息，以及如何保护信息。</li>
          <li>
            <strong>1.1 本政策适用范围：</strong>
            本政策适用于您使用或访问我们的网站、产品和服务及其客户端（包括OATOS的网页端和H5官网、Windows客户端、Mac客户端、iOS客户端和安卓客户端，以下统称“燕麦云产品”）时收集，使用或共享的信息，包括您参与燕麦云相关活动时与我们互动的信息。</li>
          <li>
            <strong>1.2 本政策不适用范围：</strong>
            燕麦云网站可能包含有其它网站的链接，燕麦云产品也可能包含有其它网站链接的文件。我们不了解其它网站的隐私策略或内容，不是其他网站的运营方，不为其他网站的隐私政策承担任何责任。</li>
          <li>
            <strong>1.3 本政策的变更：</strong>
            我们可能会对本政策作出更改。如果我们进行内容更改，会在本政策顶部顶置更改日期和更改内容查看方式。在本政策发生重大变更时，我们可能会在更改生效之前告知您。告知的方式和渠道包括发送电子邮件或信息到您在燕麦云已验证的电子邮箱或手机号，或在燕麦云官网和燕麦云产品消息中心发布通知。我们建议您使用常用的电子邮箱地址和手机号注册或绑定燕麦云账号以便及时收悉我们的通知，我们也鼓励您定期查看本页以便了解有关我们隐私政策的最新信息。</li>
        </ul>
      </div>
      <div class="product-privacy-detail">
        <div class="product-privacy-subtitle">二、信息收集方式</div>
        <ul>
          <li>
            <div><strong>2.1 </strong>有时候我们需要您的某些信息才能为您提供请求的服务，我们收集信息的方式包括：</div>
            <ul>
              <li>① 您自愿提供给我们的信息。您在访问我们的网站或使用燕麦云产品时需要您自愿填的信息，或您上传的文件、数据等信息。</li>
              <li>② 我们自动收集的信息。在您访问燕麦云官网或使用燕麦云产品时，系统的日志程序会自动记录您的网站使用信息，设备信息或地理位置信息。</li>
              <li>③ 我们从其它来源收集的信息。在您通过第三方产品访问燕麦云网站或使用燕麦云产品时，在您授权我们调用第三方信息时，我们将会从第三方调取您相关信息。</li>
            </ul>
          </li>
        </ul>
      </div>
      <div class="product-privacy-detail">
        <div class="product-privacy-subtitle">三、信息收集类型</div>
        <ul>
          <div>您在燕麦云访问燕麦云官网、注册燕麦云产品、使用燕麦云产品、参加我们举报的相关活动，或者通过第三方产品与我们交流互动时，我们可能会收集由您提交或授权的信息，或者由程序自动记录您的相关访问和和使用信息。我们收集的与您相关的信息包括：</div>
            <li>
              <strong>3.1 </strong>由您提交的信息:
              <ul>
                <li>① 您所在的机构名称、机构地址、机构联系人姓名、机构联系电话和电子邮箱、机构银行账号信息。</li>
                <li>② 您的姓名、生日、用户名、电子邮箱、手机号、所属部门、工号、职位或职务信息。</li>
                <li>③ 您使用燕麦云产品上传、下载、分享或共享的文件信息，或通过燕麦云文件或信息时的联系人手机号和电子邮箱。</li>
                <li>④ 您购买或续费燕麦云产品或服务时的交易信息，包括所用支付账号、账单地址、发票收件人及其联系信息。</li>
                <li>⑤ 您参加我们的活动时提供的信息。</li>
                <li>⑥ 您选择提供的其它信息。</li>
              </ul>
            </li>
            <li>
              <strong>3.2 </strong>我们自动收集的信息:
              <ul>
                <li>① 日志信息。您在访问我们的网站和使用燕麦云产品时，我们的服务器日志程序会自动收集您的访问信息，包括：访问的IP地址、访问时间、操作系统和浏览器类型、网络服务商、浏览的页面网址。</li>
                <li>② 设备信息。您在访问我们的网站和使用燕麦云产品时，我们的服务器日志程序会自动收集您的设备信息，包括：你的设备类型（计算机、手机或平板电脑）、设备型号、设备识别码、操作系统和版本、屏幕分辨率、网络运营商、设备网卡信息。您在通过移动设备访问燕麦云网站或使用燕麦云产品时，在您授权同意时，我们可能会从您的移动设备收集您的位置信息，已启用某些功能完成服务。</li>
                <li>③ 使用信息。您在使用燕麦云上传、下载、分享或共享文件时，燕麦云程序将自动记录您操作的文件相关的信息，包括文件名称、操作的时间、操作人和操作类型。您对燕麦云账户进行管理员操作时，燕麦云程序也将自动记录您操作账号的相关信息，包括账号姓名、操作时间、操作内容。</li>
              </ul>
            </li>
            <li>
              <strong>3.3 </strong>我们从其它来源收集的信息:
              <ul>
                <li>① 您在通过燕麦云产品调用第三方服务，或者通过第三方产品使用燕麦云产品服务时，我们也可能会从第三方产品获取信息，例如账号信息、联系信息。</li>
              </ul>
            </li>
        </ul>
      </div>
      <div class="product-privacy-detail">
        <div class="product-privacy-subtitle">四、信息如何使用</div>
        <ul>
          <li>
            <strong>4.1 </strong>为了遵循国家法律法规及监管需求，以及向您提供燕麦云产品服务、提升服务质量，或保障您的账户安全，我们会在以下情形中使用您的信息：
            <ul>
              <li>① 注册燕麦云产品或开通燕麦云产品或服务；</li>
              <li>② 管理您的燕麦云账户，为您的燕麦云账户添加成员账号，管理成员信息或个人账户信息；</li>
              <li>③ 使用燕麦云产品或服务，包括上传、下载、分享、共享文件，通过电子邮件、短信或第三方产品发送文件；</li>
              <li>④ 您通过第三方产品与我们链接或通过我们与第三方产品链时完成您的账户验证，完成您请求的服务；</li>
              <li>⑤ 监视和分析燕麦云产品和服务，提供、操作、维护或改进燕麦云产品或服务；</li>
              <li>⑥ 提供、处理和完成您在燕麦云产品或与在线客服商洽购买的燕麦云服务，并向您提供、发送或邮寄发票；</li>
              <li>⑦ 为您提供客户服务或使用支持，回应您的反馈、建议、问题或要求；</li>
              <li>⑧ 协助您处理产品使用过程中遇到的问题，或为您和其他成员提供产品培训、问题解决；</li>
              <li>⑨ 向您发送产品更新、服务更新、促销或活动信息，发送安全告警和系统消息；</li>
              <li>⑩ 在您参加我们的市场或运营活动时，记录您的信息以便为您交付活动约定的服务或内容。</li>
            </ul>
          </li>
        </ul>
      </div>
      <div class="product-privacy-detail">
        <div class="product-privacy-subtitle">五、信息如何共享</div>
        <ul>
          <li>
            <strong>5.1 </strong>我们不会与任何第三方共享与您有关的任何个人信息和文件信息，但下列情况除外：：
            <ul>
              <li>① 您通过燕麦云产品发送、分享信息；</li>
              <li>② 您通过第三方产品使用燕麦云产品或服务；</li>
              <li>③ 符合燕麦云产品相关服务条款或软件许可使用协议的约定；</li>
              <li>④ 为完成合并、分立、收购或资产转让而转移；</li>
              <li>⑤ 为保障用户信息安全或符合法律法规要求。</li>
            </ul>
          </li>
        </ul>
      </div>
      <div class="product-privacy-detail">
        <div class="product-privacy-subtitle">六、个人信息保护</div>
        <ul>
          <li>
            我们非常重视您的隐私保护。我们已采用适当的安全技术和组织措施，保障您的信息不会被丢失，被盗，被篡改或破坏，被滥用或未经授权的访问。
          </li>
          <li>
            <strong>6.1 </strong>信息存储地点：您的所有信息都存储在中华人民共和国境内的燕麦云服务器。
          </li>
          <li>
            <strong>6.2 </strong>信息保存时限：我们将在为您提供服务的服务条款所约定的期限内保留您的个人信息。我们还将在为遵守适用的法律义务、解决争议以及执行协议而必要的期限内保留您的个人信息。
          </li>
          <li>
            <strong>6.3 </strong>信息保护采用的方法：
            <ul>
              <li>① 您的文件上传到燕麦云数据中心时，我们会对其进行加密；</li>
              <li>② 您访问燕麦云网站或产品的网络传输，我们会对敏感信息进行机密保护；</li>
              <li>③ 存储您个人信息的服务器存放在安全合规的数据中心；</li>
              <li>④ 我们已取得国家信息安全等级保护3级认证，并采用适当的组织行为和安全流程对您的信息进行保护。</li>
              <li>⑤ 为保障用户信息安全或符合法律法规要求。</li>
            </ul>
          </li>
          <li>
            <strong>6.4 </strong>虽然我们会采取适当的措施来保护您的个人信息，但请您知悉没有任何互联网产品和服务可以确保百分之百完全安全，在如下情况时您应自行承担风险：
            <ul>
              <li>① 因您的保管疏忽导致燕麦云账号、密码遭他人非法使用；</li>
              <li>② 您与他人共享燕麦云账号；</li>
              <li>③ 您选择同意第三方产品使用个人信息，由此所产生的纠纷；</li>
              <li>④ 任何由于黑客攻击、电脑病毒侵入或政府管制而造成的暂时性网站或服务关闭，或因不可抗力导致的任何后果；</li>
              <li>⑤ 根据相关法律法规要求或相关政府主管部门的要求；</li>
              <li>⑥ 燕麦云相关服务条款或软件许可使用协议列明的使用方式或免责情形。</li>
            </ul>
          </li>
        </ul>
      </div>
      <div class="product-privacy-detail">
        <div class="product-privacy-subtitle">七、儿童隐私保护</div>
        <ul>
          <li>燕麦云面向机构用户提供产品和服务，并不对14岁以下的儿童提供服务，我们也不会主动收集未满14岁的任何个人的信息。如您未满14岁，在您的监护人或父母沒有阅读并了解本政策并同意您提供信息，您不应在燕麦云网站或燕麦云产品上提供或公布任何信息。
          </li>
          <li>如果未满 14岁的儿童向我们提交个人信息，我们一旦发现此类信息是由未满 14 岁的儿童提供且没有其监护人或父母的同意，我们就会立即删除此类信息且不会将此类信息用于任何目的。如果您了解到有未满14岁的儿童向我们提交了信息，请您通过privacy@qycloud.com通知我们，我们将立即删除此类信息。
          </li>
        </ul>
      </div>
      <div class="product-privacy-detail">
        <div class="product-privacy-subtitle">八、联系我们</div>
        <ul>
          <li>如果您对本隐私政策有疑问，或者对您的个人信息的处理存在担心和投诉，举报可能存在违反隐私保护的行为，您可以发送电子邮件到privacy@qycloud.com与我们联系；您也可以发送邮件给我们，收件地址：深圳市南山区粤海街道滨海社区白石路3939号怡化金融科技大厦21层，深圳企业云科技股份有限公司（收），邮编：518057。
          </li>
        </ul>
      </div>
    </div>
  </div> -->
  <div class="product-privacy-wrapper">
    <div class="product-privacy">
      <div class="product-privacy-title" [innerHtml]="title | safeHtml"></div>
      <div class="product-privacy-detail" [innerHtml]="content | safeHtml"></div>
    </div>
  </div>
  <app-suspension></app-suspension>

  <app-footer></app-footer>

</div>