import { Component, OnInit } from '@angular/core';
import { JumpPageService } from 'src/app/_share/service/jump-page.service';

@Component({
  selector: 'app-land',
  templateUrl: './land.component.html',
  styleUrls: ['./land.component.less']
})
export class LandComponent implements OnInit {

  constructor(
    private _jumpPage: JumpPageService,
  ) { }

  public landTitle = 'product.land.landTitle'; // 标题
  public landDesc = 'product.land.landDesc'; //描述
  public landBtn = 'product.land.landBtn'; 
  public landImgSrc = './assets/img/land/land-background.png'; //背景图
  public landLink = 'https://app.oatos.com/os/#/welcome/sign-up'; //链接地址

  public caseContent: Array<any> = [
    {
      imgUrl: './assets/img/land/land-case-img1.png'
    },
    {
      imgUrl: './assets/img/land/land-case-img2.png'
    },
    {
      imgUrl: './assets/img/land/land-case-img3.png'
    }
  ]

  public featureTitle = 'product.land.featureTitle';
  public featureDesc = 'product.land.featureDesc';

  public customizeTitle = 'product.land.customizeTitle';
  public customizeContent: Array<any> = [
    {
      imgUrl: './assets/img/land/land-customize-img1.png',
      title: 'product.land.customizeContent-title1',
      desc: 'product.land.customizeContent-desc1'
    },
    {
      imgUrl: './assets/img/land/land-customize-img2.png',
      title: 'product.land.customizeContent-title2',
      desc: 'product.land.customizeContent-desc2'
    },
    {
      imgUrl: './assets/img/land/land-customize-img3.png',
      title: 'product.land.customizeContent-title3',
      desc: 'product.land.customizeContent-desc3'
    },
    {
      imgUrl: './assets/img/land/land-customize-img4.png',
      title: 'product.land.customizeContent-title4',
      desc: 'product.land.customizeContent-desc4'
    },
    {
      imgUrl: './assets/img/land/land-customize-img5.png',
      title: 'product.land.customizeContent-title5',
      desc: 'product.land.customizeContent-desc5'
    }
  ]

  ngOnInit(): void {
    this._jumpPage.goTop();
  }

}
