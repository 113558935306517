import { Component, OnInit } from '@angular/core';
import {JumpPageService} from 'src/app/_share/service/jump-page.service';

// declare 引入外部变量时声明使用
declare var BMap: any;
declare var BMAP_ANIMATION_BOUNCE: any;
declare var BMAP_NORMAL_MAP: any;
declare var BMAP_HYBRID_MAP: any;


class ContactUsList {
  imgUrl: string;
  title: string;
  telephone: string;
}

class ContactUsDetail {
  InternationalLine: string;
  serviceHotline: string;
  contactEmail: string;
  contactAddress: string;
  zipCode: string;
}

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.less']
})
export class ContactUsComponent implements OnInit {

  constructor(
    private _jumpPage: JumpPageService,
  ) { }

  public contactUsTitle = 'product.contact-us.contactUsTitle'; // 标题
  public contactUsDesc = 'product.contact-us.contactUsDesc'; // 描述
  public contactUsImgSrc = '/assets/img/contact/contact-bg.png'; //背景图

  public isShowDefaultMap;

  // 联系我们
  public contactUsList: Array<ContactUsList> = [
    {
      imgUrl: '/assets/img/contact/contact-icon1.svg',
      title: 'product.contact-us.title1',
      telephone: 'product.contact-us.telephone1'
    },
    {
      imgUrl: '/assets/img/contact/contact-icon2.svg',
      title: 'product.contact-us.title2',
      telephone: 'product.contact-us.telephone2'
    },
    {
      imgUrl: '/assets/img/contact/contact-icon3.svg',
      title: 'product.contact-us.title3',
      telephone: 'product.contact-us.telephone3'
    },
    {
      imgUrl: '/assets/img/contact/contact-icon4.svg',
      title: 'product.contact-us.title4',
      telephone: 'product.contact-us.telephone4'
    }
  ];

  public contactUsDetail: Array<ContactUsDetail> = [
    {
      InternationalLine: 'product.contact-us.InternationalLine',
      serviceHotline: 'product.contact-us.serviceHotline',
      contactEmail: 'product.contact-us.contactEmail',
      contactAddress: 'product.contact-us.contactAddress',
      zipCode: 'product.contact-us.zipCode'
    }
  ];


  ngOnInit(): void {
    this.createMap();
    this._jumpPage.goTop();
  }

  createMap() {
    // 百度地图API功能
    var map = new BMap.Map("product-contact-us-bottom");    // 创建Map实例
    var point = new BMap.Point(113.948819,22.554205);
    if (point) {
      this.isShowDefaultMap = false;
    }
    map.centerAndZoom(point, 19);  // 初始化地图,设置中心点坐标和地图级别
    var marker = new BMap.Marker(point);  // 创建标注
    map.addOverlay(marker);               // 将标注添加到地图中
    marker.setAnimation(BMAP_ANIMATION_BOUNCE); //跳动的动画

    //添加地图类型控件
    map.addControl(new BMap.MapTypeControl(
      {
        mapTypes:[
          BMAP_NORMAL_MAP,
          BMAP_HYBRID_MAP
        ]
      })
    );
    map.enableScrollWheelZoom(true);     //开启鼠标滚轮缩放
  };

}
