<div class="product-header-nav" id="product-header-nav">
  <app-cloud-form [isShowForm]="isShowForm"></app-cloud-form>

  <div class="product-header-nav-wrapper">
    <div class="product-header-nav-img">
      <img *ngIf="!darkHeader" src="./assets/img/header-nav.svg" />
      <img *ngIf="darkHeader" src="./assets/img/header-nav-dark.svg" />
    </div>

    <div class="product-header-nav-desc">
      <ul>
        <li *ngFor="let item of navArr">
          <a [routerLink]="item.route">
            {{ item.title | translate }}
          </a>
          <ul *ngIf="item.children">
            <li *ngFor="let child of item.children">
              <a *ngIf="child.route" [routerLink]="child.route">
                {{ child.subTitle | translate }}
              </a>

              <a *ngIf="child.linkUrl" [href]="child.linkUrl" target="_blank">
                {{ child.subTitle | translate }}
              </a>
            </li>
          </ul>
        </li>
      </ul>

      <img *ngIf="!darkHeader" src="./assets/img/400-086-1108.png" />
      <img *ngIf="darkHeader" src="./assets/img/400-086-1108-dark.png" />
    </div>

    <div class="product-header-nav-button">
      <a class="product-header-nav-login" (click)="showForm()">
        {{ "product.header-nav.button1" | translate }}
      </a>
      <div class="product-header-nav-deploy">
        <a target="_blank" href="https://app.oatos.com/os/#/welcome/sign-in">
          {{ "product.header-nav.sign" | translate }}
        </a>
        /
        <a target="_blank" href="https://app.oatos.com/os/#/welcome/sign-up">
          {{ "product.header-nav.register" | translate }}
        </a>
      </div>
    </div>
  </div>
</div>
