<div class="product-custom-wrapper">
  <div class="product-custom-header">
    <app-header
      [title]="customTitle | translate"
      [btn]="customBtn | translate"
      [imgSrc]="customImgSrc"
      [link]="customLink"
    ></app-header>
  </div>

  <div class="product-custom-content-all">
    <div class="product-custom-content">
      <div class="product-custom-content-wrapper" *ngFor="let content of customContent" >
        <div class="product-custom-content-title">
          {{content.title | translate}}
        </div>
        <div class="product-custom-content-main">
          <div class="product-custom-content-item" *ngFor="let children of content.children">
            <img [src]="children.imgUrl">
            <div class="product-custom-content-item-title">
              {{children.title | translate}}
            </div>
            <div class="product-custom-content-item-desc">
              {{children.desc | translate}}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <app-suspension></app-suspension>

  <app-footer></app-footer>

</div>