<div class="product-content-wrapper">
  <div class="product-content-header">
    <app-header
      [title]="contentTitle | translate"
      [btn]="contentBtn | translate"
      [imgSrc]="contentImgSrc"
      [link]="contentLink"
    ></app-header>
  </div>

  <div class="product-control-content-all">
    <div class="product-control-content">
      <div class="product-control-content-wrapper">
        <div class="product-control-content-title">
          {{'product.content-control.main-title1' | translate}}
        </div>
        <div class="product-control-content1-main">
          <div class="product-control-content1-item" *ngFor="let content1 of controlContent1">
            <img [src]="content1.imgUrl">
            <div class="product-control-content1-item-title">
              {{content1.title | translate}}
            </div>
            <div class="product-control-content1-item-desc">
              {{content1.desc | translate}}
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="product-control-content product-control-content2">
      <div class="product-control-content-wrapper">
        <div class="product-control-content-title">
          {{'product.content-control.main-title2' | translate}} 
        </div>
        <div class="product-control-content2-main">
          <div class="product-control-content2-main-content" *ngFor="let content2 of controlContent2" >
            <div class="product-control-content2-title">
              {{content2.title | translate}}
            </div>
            <div class="product-control-content2-desc">
              <div class="product-control-content2-item" *ngFor="let item of content2.children" >
                <img [src]="item.imgUrl">
                <div class="product-control-content2-item-title">
                  {{item.title | translate}}
                </div>
                <div class="product-control-content2-item-desc">
                  {{item.desc | translate}}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  
  <app-suspension></app-suspension>

  <app-footer></app-footer>
</div>