import { Component, OnInit } from '@angular/core';
import { JumpPageService } from 'src/app/_share/service/jump-page.service';

@Component({
  selector: 'app-education',
  templateUrl: './education.component.html',
  styleUrls: ['./education.component.less']
})
export class EducationComponent implements OnInit {

  constructor(
    private _jumpPage: JumpPageService,
  ) { }

  public educationTitle = 'product.education.educationTitle'; // 标题
  public educationDesc = 'product.education.educationDesc'; //描述
  public educationBtn = 'product.education.educationBtn'; 
  public educationImgSrc = './assets/img/education/education-background.png'; //背景图
  public educationLink = 'https://app.oatos.com/os/#/welcome/sign-up'; //链接地址

  public caseContent: Array<{
    imgUrl: string;
  }> = [
    {
      imgUrl: './assets/img/education/education-case-img1.png'
    },
    {
      imgUrl: './assets/img/education/education-case-img2.png'
    },
    {
      imgUrl: './assets/img/education/education-case-img3.png'
    },
  ]

  public featureTitle = 'product.education.featureTitle';
  public featureDesc = 'product.education.featureDesc';

  public customizeTitle = 'product.education.customizeTitle';
  public customizeContent: Array<any> = [
    {
      imgUrl: './assets/img/education/education-customize-img1.svg',
      title: 'product.education.customizeContent-title1',
      desc: 'product.education.customizeContent-desc1'
    },
    {
      imgUrl: './assets/img/education/education-customize-img2.svg',
      title: 'product.education.customizeContent-title2',
      desc: 'product.education.customizeContent-desc2'
    },
    {
      imgUrl: './assets/img/education/education-customize-img3.svg',
      title: 'product.education.customizeContent-title3',
      desc: 'product.education.customizeContent-desc3'
    },
    {
      imgUrl: './assets/img/education/education-customize-img4.svg',
      title: 'product.education.customizeContent-title4',
      desc: 'product.education.customizeContent-desc4'
    }
  ]

  ngOnInit(): void {
    this._jumpPage.goTop();
  }

}
