<div>
  <div>
    <app-header
      [title]="mapTitle"
      [imgSrc]="mapUrl"
    ></app-header>
  </div>

  <div class="product-sitemap-box">
    <h2>网站地图</h2>
    <div class="product-sitemap-item">
      <div class="product-item-box" *ngFor="let item of mapsite" >
        <h3>
          <a [routerLink]="item.route">
            {{item.title}}
          </a>
        </h3>
        <ul *ngIf="item.children" >
          <li *ngFor="let children of item.children">
            <a *ngIf="children.route" [routerLink]="children.route">
              {{children.title}}
            </a>
            <a *ngIf="children.subTitle && children.route" [routerLink]="children.route">
              {{children.subTitle}}
            </a>
            <a *ngIf="children.subTitle && children.linkUrl" [href]="children.linkUrl" target="_blank">
              {{children.subTitle}}
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>

  <app-suspension></app-suspension>

  <app-footer></app-footer>

</div>