<div class="product-main-widget">
  <ul>
    <li>
      <a class="product-float-service"></a>
      <div class="product-expand">
        <div class="product-expand-content product-expand-contact">
          <div>
            <span class="product-expand-title">
              {{ 'product.suspension-parts.title1' | translate}}
            </span>
            <!-- <a class="product-expand-qq" target="_blank" href="https://wpa1.qq.com/cKcTYUpd?_type=wpa&qidian=true"></a> -->
            <a class="product-expand-qq"></a>
            <span class="product-expand-date">
              {{ 'product.suspension-parts.desc1' | translate}}
            </span>
            <span class="product-expand-title">
              {{ 'product.suspension-parts.title11' | translate}}
            </span>
          </div>
          <div>
            <span class="product-expand-title">
              {{ 'product.suspension-parts.title2' | translate }}
            </span>
            <span class="product-expand-phone">
              400-086-1108
            </span>
            <span class="product-expand-date">
              {{ 'product.suspension-parts.desc2' | translate }}
            </span>
            <span class="product-expand-phone">
              +86-755-23358866
            </span>
            <span class="product-expand-date">
              {{ 'product.suspension-parts.desc1' | translate }}
            </span>
          </div>
        </div>
      </div>
    </li>
    <li>
      <a class="product-float-wechat"></a>
      <div class="product-expand">
        <div class="product-expand-content product-expand-wechat">
          <div class="product-wechat-title">
            <span>
              {{ 'product.suspension-parts.title3' | translate }}
            </span>
            <br>
            <span>
              {{ 'product.suspension-parts.subtitle3' | translate }}
            </span>
          </div>
          <div class="product-wechat-content">
            <div>
              <img src="./assets/img/home/zip.png" lazyLoad="./assets/img/qrcode_public.jpg" >
              <span>
                {{ 'product.suspension-parts.desc3' | translate }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </li>
    <li>
      <a class="product-float-weibo" target="_blank" href="http://weibo.com/oatos"></a>
    </li>
    <li (click)="_jumpPage.goTop()">
      <a class="product-float-to-top"></a>
    </li>
  </ul>
</div>
